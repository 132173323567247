import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid,GridCellEditStopReasons  } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useForm } from "react-hook-form";
import DialogTitle from "@mui/material/DialogTitle";

import DataService from "../../../services/data.service";
import Swal from "sweetalert2";
import OncologysService from "../../../services/oncologys.service";
import { BiSave } from "react-icons/bi";


const Oncology = () => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState({});
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
     editable: true
    },
    {
      field: "code",
      headerName: "Code",
      flex: 2,
      editable: true,
     
    },

    {
      field: "actionEdit",
      headerName: "Save",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          OncologysService.Putoncology(params.row)  .then(function (response) {
            setOpen(false);
            Swal.fire({
              icon: "success",
              title: "success...",
              text: "   تم تعديل المرض بالنجاح",
            }).then(() => window.location.reload());
          })
          .catch(function (error) {
              
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: "",
                text: error.response?.data?.error + "!",
              });
              return 0;
            }
            if (!error.status) {
              if (error) {
                Swal.fire({
                  icon: "error",
                  title: "NetWork",
                  text: "مشكلة في شبكه!",
                });
                return 0;
              }
            }
          });
        };
  
        return <Button onClick={onClick}><BiSave  /></Button>;
      },
    },
  ];
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) =>
    OncologysService.Postoncology(data)
      .then(function (response) {
        setOpen(false);
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "   تم أضافه المرض بالنجاح",
        }).then(() => window.location.reload());
      })
      .catch(function (error) {
        if (error.response) {
          Swal.fire({
            icon: "error",
            title: "",
            text: error.response?.data?.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          if (error) {
            Swal.fire({
              icon: "error",
              title: "NetWork",
              text: "مشكلة في شبكه!",
            });
            return 0;
          }
        }
      });

  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
  //Service Get Data
  try {
    useEffect(() => {
      DataService.Getoncologys()
        .then((data) => data)
        .then((data) => setRows(data))
        .catch(function (err) {
          if (!err?.response) {
            Msg("No Server Response");
          } else if (err.response?.status === 400) {
            Msg("error");
          } else if (
            err.response?.status === 401 ||
            err.response?.status === 422
          ) {
            DataService.GetRefresh().catch(function (err) {
              if (!err?.response) {
                Msg("No Server Response");
              } else if (err.response?.status === 400) {
                Msg("error");
              } else if (
                err.response?.status === 401 ||
                err.response?.status === 422
              ) {
                window.location.reload();
              }
            });
          }
          window.location.reload();
        });
    }, []);
  } catch (err) {}
  return (
    <div className="w-100">
      <div className="center">
        <Button variant="outlined" onClick={() => setOpen(true)}>
          أضافة مرض جديد
        </Button>
        <Dialog open={open} fullWidth={true}
        maxWidth={'md'} >
          <DialogTitle>ADD</DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <TextField
                id="standard-basic"
                label="Name"
                variant="standard"
                {...register("name", { required: true })}
                
                sx={{
                  width:'100%',
                }}/>
              <br />
              {errors.name && <span className="R2">يرجي ادخال البيانات</span>}
              <br />
              <TextField
                id="standard-basic"
                label="Code"
                variant="standard"
                {...register("code", { required: true })}
                sx={{
                  width:'100%',
                 }} />
              <br />
              {errors.code && <span className="R2">يرجي ادخال البيانات</span>}
              <br />
            </DialogContent>
            <DialogActions className='l'>
          
           
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button type="submit">Save</Button>

            </DialogActions>
          </form>
        </Dialog>
    
      </div>
      <br />
      <Box sx={{ height: "84vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowId={(rows1) => rows1?.uuid}
          experimentalFeatures={{ newEditingApi: true }}
    
        />
      </Box>
    </div>
  );
};

export default Oncology;
