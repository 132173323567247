import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const Getanalysis = async () => {
  const response=  await axios
  .get("/analysis", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Postanalysis = async (data) => {
  
  const response=  await axios
  .post("/analysis",
  {
    name:data.name,

} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Putanalysis = async (data) => {
  
  const response=  await axios
  .put("/analysis?uuid="+data.uuid,
  {
    name:data.name,
  
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const analysisService = {
  Getanalysis,
  Postanalysis,
  Putanalysis,
}

export default analysisService;
