import React, { useEffect, useState } from "react";
import Select from "react-select";
import DrawerAppBar from "../AppBar";
import ReportService from "../../services/report.service";
import Swal from "sweetalert2";

import DataService from "../../services/data.service";
import randomColor from "randomcolor";
import $, { data } from "jquery";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { color } from "@mui/system";
import { object } from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const Report2 = () => {
  const [rows, setrows] = useState({});

  function onClick() {
    let a = [];
    console.log(state);
    state.oncology.forEach((element) => {
      console.log(element.value);
      if (element.value === "OPK") {
        a = "OPK";
      } else {
        a.push({
          uuid: element.value,
        });
      }
    });
    console.log(a);
    if (
      state.address === "" ||
      state.oncology === "" ||
      state.oncology == null ||
      state.oncology === null
    ) {
      Msg("Please fill in the required fields");
      return 0;
    }
  
    ReportService.inicity(a, state.address)
    .then((data) => {
      if (data[0].y == 0) {
        Msg("No data");
      } else {
        setrows(data);
      }
    })
    .catch(function (err) {
      if (!err?.response) {
        Msg("No Server Response");
      } else if (err.response?.status === 400) {
        Msg("error");
      }
    });

  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }

  const [state, setState] = useState({});
  let data1;
  let labels = [];
  let label1 = [];
  let color = [];
  let dataset = [];
  const [rows1, setrows1] = useState({});
  if (rows.length > 0) {
    labels = rows.map((rows, index) => labels.push(rows.oncology));

    rows.map((rows, index) => {
      color.push(randomColor());
      dataset.push({
        label: rows.oncology,
        data: rows.data.map((data) => data.y),
        backgroundColor: color[index],
      });
      rows?.data.map((data1) => {
        if (!label1.includes(data1.x)) {
          label1.push(data1.x);
        }
      });
    });

    data1 = {
      labels: label1,
      datasets: dataset,
    };
  }
  console.log(rows);
  useEffect(() => {
    ReportService.init()
      .then((data) => setrows1(data))

      .catch(function (err) {
        if (!err?.response) {
          Msg("No Server Response");
        } else if (
          err.response?.status === 400 ||
          err.response?.status === 404
        ) {
          Msg("error");
        } else if (
          err.response?.status === 401 ||
          err.response?.status === 422
        ) {
          DataService.GetRefresh().catch(function (err) {
            if (!err?.response) {
              Msg("No Server Response");
            } else if (err.response?.status === 400) {
              Msg("error");
            } else if (
              err.response?.status === 401 ||
              err.response?.status === 422
            ) {
              Msg("error");
            }
          });
        }
      });

    // setloading(true)
  }, []);
  

  let optionscitys = [
    {
      label: "كل المدن",
      value: "OPK",
    },
  ];
  let optionsoncology = [
    {
      label: "كل  الأمراض",
      value: "OPK",
    },
  ];
  rows1?.citys?.map((citys) =>
    optionscitys.push({
      label: citys.name,
      value: citys.uuid,
    })
  );

  rows1?.oncologys?.map((oncology) =>
    optionsoncology.push({
      label: oncology.name,
      value: oncology.uuid,
    })
  );
  return (
    <div className="">
      <DrawerAppBar />
      <br /> <br />
      <br /> <br />
      <div className="x400 ">
        <div className="flex">
          <div className="mx-1">
            <div className="Flex">
              <label className="label"> نوع الورم </label>
            </div>

            <br></br>
            <div style={{ width: "520px" }}>
              <Select
                onChange={(event) =>
                  setState({
                    ...state,
                    oncology: event,
                  })
                }
                isMulti
                name="oncology"
                options={optionsoncology}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>

          <div className="mx-1">
            <div className="Flex">
              <label className="label"> المدن</label>
            </div>

            <br></br>

            <div style={{ width: "520px" }}>
              <Select
                onChange={(event) =>
                  setState({
                    ...state,
                    address: event.value,
                  })
                }
              
                name="address"
                options={optionscitys}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="flex">
          <div className="mt-5">
            <button
              variant="contained"
              color="primary"
              style={{
                marginLeft: "1.1%",
                padding: "",
                width: "100px",
                backgroundColor: "#1976D2",
              }}
              onClick={() => onClick()}
            >
              Search
            </button>
          </div>
         
        </div>
        <div className="flex  w-40">
          {rows.length > 0 ? <Bar data={data1} options={options} /> : ""}
        </div>

        <br></br>
      </div>
      <table id="customers">
        {rows[0] != undefined &&
          rows.map((result, index1) => (
            <tbody className="text-center">
              <tr key={index1}>
          
              {/* {rows[0] != undefined &&
                Object.values(rows).map((result, index) => ( */}
                  <th className="text-center"> {result.oncology}  عدد المصابين  بالمرض </th>
                {/* ))} */}
                    
                <th className="text-center">الجنس</th>

               
                <td className="text-center" style={{ flex: 1 }}>
                      {index1+1}
                    </td>
              </tr>

              {rows[0] != undefined &&
                Object.values(rows[index1].data).map((result, index) => (
                  <tr key={index}>
               
                    <td className="text-center" style={{ flex: 1 }}>
                      {parseInt(result.y) ? parseInt(result.y) : 0}
                    </td>
                    <td className="text-center" style={{ flex: 1 }}>
                      {index % 2 ==  0 ? 'male':'female' }
                    </td>
                  
                   
                  </tr>
                ))}

              {/* <td  className="text-center">{rows[index]?.oncology}</td>  */}
            </tbody>
          ))}
      </table>
    </div>
  );
};
