import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import Typography from "@mui/material/Typography";
import Cookies from "universal-cookie";
import { Box, Button, Radio } from "@mui/material";
import $, { map } from "jquery";
import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from "../../AppBar";
import api from "../../../api/axios";
import MedicinesService from "../../../services/medicines.service";

const Medicinesview = () => {
  const navigate = useNavigate();
  let { id } = useParams();

console.log(id)
let tp = id.split("&")[1];
id = id.split("&")[0];



  const [isloading2, setisloading2] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [name, setname] = useState("أضافه");
  const [Uuid, setUuid] = useState(null);
  const [medicine, setmedicine] = useState([]);
  const [Check, setCheck] = useState([]);

  const [tableData2, setTableData2] = useState([]);



  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
 
  //Service Get Data
  try {
    useEffect(() => {
      DataService.Getmedicines()
        .then((data) => data)
        .then((data) => setmedicine(data))
        .catch(function (err) {
          if (!err?.response) {
            Msg("No Server Response");
          } else if (err.response?.status === 400) {
            Msg("error");
          } else if (
            err.response?.status === 401 ||
            err.response?.status === 422
          ) {
            DataService.GetRefresh().catch(function (err) {
              if (!err?.response) {
                Msg("No Server Response");
              } else if (err.response?.status === 400) {
                Msg("error");
              } else if (
                err.response?.status === 401 ||
                err.response?.status === 422
              ) {
                Msg("error");
                localStorage.clear();
              }
            });
          }
        });
        MedicinesService.GetTPMedicines(id,tp)
        .then((data) => data)
        .then((data) => {
          setTableData2(data);
        });
    }, []);
  } catch (err) {}
  const navItems = [];


  return (
    <>
      <DrawerAppBar navItems={navItems} />
      {!1 ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="App">
          <div className="Home">
            <br></br>
            <br></br>

            <br />

            <Typography className="R">بيانات العلاج</Typography>

            <Typography className="R">  
            الفترة    علاجيه : {tp}</Typography>
         

            <Box
              sx={{
                height: tableData2.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table2 tableData2={tableData2}/>

              <br></br>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default Medicinesview;

export const Table2 = ({ tableData2 }) => {

  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
         الرقم
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
            الاسم
              </h5>{" "}
            </th>

           
            <th
            
            >
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
  
                  float: "Center",
                }}>
      الوحده/الجرعة    

              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
 طريقة اعطاء العلاج
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
             التكرار
             {/* Frequency    */}
              </h5>
            </th>
           
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
الكمية
              </h5>
            </th>
            {/* <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
uint
              </h5>
            </th> */}
     
          </tr> 
        </thead>
        <tbody>
          {tableData2.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.medicine_name
}</td>
                <td>{data.dose+'/'+data.unit}</td>
                <td>{data.roa}</td>
                <td>{data.frequency}</td>
            
                <td>{data.quantity}</td>
                
      
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

