  
import React, { PureComponent, useEffect, useState } from "react";

import ReportService from "../../services/report.service";
import { useLocation, useNavigate } from "react-router-dom";
import { InputLabel, NativeSelect } from "@mui/material";
import FacilitysService from "../../services/facility.service";
  
const Medicinepatient = () => {
  const [rows, setrows] = useState({});
  const location = useLocation()
  const [uuid, setuuid] = useState(location.pathname.substring(location.pathname.indexOf(':') + 1  ) != null? location.pathname.substring(location.pathname.indexOf(':') + 1  ) : 0)
console.log(uuid)
if(uuid=='/Report/one/medicinepatient'){
setuuid(
  ''
)
}
  const [Facilitys, setFacilitys] = React.useState(null);
  useEffect(() => {
    FacilitysService.GetFacility()
    .then((data) => data)
    .then((data) => setFacilitys(data))
    ReportService.medicinepatient(uuid)
      .then((data) => data)
      .then((data) => setrows(data))

      .catch(function (err) {
        if (!err?.response) {
        } else if (err.response?.status === 400) {
        } else if (
          err.response?.status === 401 ||
          err.response?.status === 422
        ) {
               localStorage.clear();
          navigate("/");
          window.location.reload();
        }
      });
    // setloading(true)
  }, [uuid]);

  const navigate = useNavigate();

  const container = React.useRef(null);
console.log(rows)
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <br></br>

      <div className="border rounded p-2">
        <div ref={container} className="W100">
          <div className={localStorage.getItem('facility') ==''?'center':null}>
<>

{
  localStorage.getItem('facility') ==''?
  <NativeSelect
 defaultValue={location.pathname.substring(location.pathname.indexOf(':') + 1 )}
onChange={(e)=>setuuid(e.target.value)}
  inputProps={{
    name: 'fhcility',
  }}
  sx={{
    width: '70vw',
  }}
>
  <option  value={0}>جميع المراكز</option>

  {Facilitys != null && Facilitys.map((Facilitys) => (
    uuid==Facilitys.uuid ? 
    
    <option
    value={Facilitys.uuid}
selected
  >
    {Facilitys.name}
  </option>
    :
    <option
      value={Facilitys.uuid}

    >
      {Facilitys.name}
    </option>
  ))}
</NativeSelect>:null
}


</>

          <h1 className="text-center"> اسماء المرضي</h1>
          </div>
      
          <span className='rg1'>
              <h6 id="name">
{localStorage.getItem("user")+'-:اسم المستخدم '}
</h6>


            </span>

          <table id="customers">
            {rows[0] != undefined &&
              Object.values(rows).map((result, index) => (
                <>
                  <tr key={result.medicine}>
                    <th
                      style={{ color: "#ffff", flex: 1 }}
                      className="text-center"
                   
                    >
                      {" "}
         
                  <div className="flexjs">
               
        
             <div className="flex">
                      <h3   style={{ color: "#ffff", flex: 1 }}
                      className="text-center">
         {result.quantity}
                      </h3>
                      <h3   style={{ color: "#ffff", flex: 1 }}
                      className="text-center">
                 :      الكمية  
                      </h3>
                      <h3   style={{ color: "#ffff", flex: 4}}
                      className="text-center">
      
      {result.medicine}
                      </h3>

                      <h3   style={{ color: "#ffff", flex: 1 }}
                      className="text-center">
                 
               :  الاسم
                   
                      </h3>
                      </div>
             
              
                  </div>
                 
                  
                    </th>
                  </tr>
                  <tr key={index}>
               
                      
                    <th
                      style={{ color: "#ffff", flex: 1 }}
                      className="text-center"
                      key={index}
                    >
                      {" "}
                      <div className="flex">
                      <h3   style={{ color: "#ffff", flex: 1 }}
                      className="text-center">
        الكمية 
                      </h3>
                      <h3   style={{ color: "#ffff", flex: 1 }}
                      className="text-center">
                    الرقم الوطني  
                      </h3>
                      <h3   style={{ color: "#ffff", flex: 1 }}
                      className="text-center">
                      الاسم
                      </h3>
                      <h3   style={{ color: "#ffff", flex: 1 }}
                      className="text-center">
                        الترقيم
                      </h3>
                      </div>
                   
                    </th>
                      
                  
                    
                        </tr>
                  {rows[0] != undefined &&
                    Object.values(rows[index].patients).map((result, index) => (
                      <>
                        <tr key={result.nid}>
                          <td key={index}
                            className="center"
                            style={{ flex: 1 }}
                          >
                           <td className="text-center" style={{ flex: 1 ,
                          
                           }}>
                              {result.quantity != "none" ? result.quantity : "0"}
                            </td>
                            <td className="text-center" style={{ flex: 1,
                           }}>
                              {result.nid != "none" ? result.nid : "لا يوجد"}
                            </td>
                            <td className="text-center" style={{ flex: 1 ,
                          }}>
                              {result.name}
                            </td>
                            <td className="text-center" style={{ flex: 1 ,
                          }}>
                              {index+1}
                            </td>
                          </td>
                        </tr>
                      </>
                    ))}
                       <td  className="text-center" style={{flex:1}}>{ result.total }</td> 
    
                </>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Medicinepatient;
