import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBTable,
  MDBTableHead,
} from "mdb-react-ui-kit";
import DataService from "../../../services/data.service";

export default function App() {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  //get uuid from url
  const url = window.location.href;
  const uuid = url.substring(url.lastIndexOf("/") + 1);
  const timestamp = Date.now();
  const date = new Date(timestamp);
  
  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
  React.useEffect(() => {
    document.title = "report";
    DataService.Getreportmedicines(uuid).then((response) => {
      console.log(response);
      setData(response);
      setIsLoading(true);
    });
  }, []);
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time?.getTime());
    a = new Date(a);
    //reverse date?
    return  a.toISOString().split("T")[0].split("-").reverse().join("-");



     
  }
  return (
    <MDBContainer
      className="py-5"
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      {
         !isLoading  ? <div className="spinner-container">
         <div className="loading-spinner">
         
         </div> </div> : 
 
      <MDBCard
        className="p-4"
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        
        <MDBCardBody
          style={{
            backgroundColor: "#ffffff",
          }}
        >
          <MDBContainer
            className="mb-2 mt-3"
            style={{
              backgroundColor: "#ffffff",
            }}
          >
            <MDBRow
              className="d-flex align-items-baseline"
              style={{
                backgroundColor: "#ffffff",
              }}
            ></MDBRow>
          </MDBContainer>
          <MDBContainer>
            <MDBCol md="12" className="text-center" style={{
              display: "flex",
              justifyContent: "center",
            }}>
            
              <p
                style={{
                  color: "#000000",
                }}
                className="pt-0"
              >
                {/* تقارير تفصيلي للمريض  to english*/}
                Medical report for case no : {data?.code}
    
              </p>
              <div class="floating-print-button">
  <button 
  onClick={() => window.print()}
  >Print</button>
</div>
            </MDBCol>
          </MDBContainer>
          <br />
          <MDBRow>
          <MDBCol xl="8">
              <MDBTypography listUnStyled>
                <div className="flex000"  style={{
                      color: "#000000",
                      width: "1000px",
                    }}>
                  <li
                    style={{
                      color: "#000000",
                      width: "1800px",
                    }}
                    className="text-muted"
                  >
                    Report requested by
                    : <span style={{ color: "#5d9fc5" }}>{
                      // reomvo frist later and last later from local storage username

                     
                      localStorage.getItem("user")
                    }</span>
                  </li>
                  <li
                    style={{
                      color: "#000000",
                      width: "1500px",
                    }}
                  >
                    {" "}
                    Print date:{" "}
                    <span style={{ color: "#5d9fc5" }}>
                      {
                   formattedDate

                      }
                    </span>
                  </li>
                </div>

                
              </MDBTypography>
            </MDBCol>
            <MDBCol xl="8">
              <MDBTypography listUnStyled>
                <div className="flex000"  style={{
                      color: "#000000",
                      width: "1000px",
                    }}>
                  <li
                    style={{
                      color: "#000000",
                      width: "1800px",
                    }}
                    className="text-muted"
                  >
                    Name: <span style={{ color: "#5d9fc5" }}>{data?.name}</span>
                  </li>
                  <li
                    style={{
                      color: "#000000",
                      width: "1500px",
                    }}
                  >
                    {" "}
                    birthdate:{" "}
                    <span style={{ color: "#5d9fc5" }}>
                      {time(data?.birthdate)}
                    </span>
                  </li>
                </div>

                <div className="flex000"style={{
                      color: "#000000",
                      width: "1000px",
                    }}>
                  <li
                    style={{
                      color: "#000000",
                      width: "1200px",
                    }}
                    className="text-muted"
                  >
                    Code NO:{" "}
                    <span style={{ color: "#5d9fc5" }}>{data?.code}</span>
                  </li>
                  <li
                    style={{
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    {" "}
                    facility :{" "}
                    <span style={{ color: "#5d9fc5" }}>{data?.facility}</span>
                  </li>
                </div>
              </MDBTypography>
            </MDBCol>
            <br>
            </br>
            <hr/>
            <MDBCol xl="3">
              <h1>
                <span style={{ color: "#5d9fc5", fontSize: 20 }}>
                  Diagnosis
                </span>
              </h1>
            </MDBCol>
          </MDBRow>
       {
          data?.diagnosis?.length!=0?
      <MDBRow className="my-2 mx-1 justify-content-center">
            <MDBTable className="table" id="customers">
              <MDBTableHead
                className="text-white"
                style={{
                  backgroundColor: "#84B0CA",
                }}
              >
                <tr>
                  <th
                    style={{
                      width: "10%",
                    }}
                    scope="col"
                  >
                    #
                  </th>
                  <th
                    style={{
                      width: "30%",
                    }}
                  >
                    name
                  </th>
                  <th
                    style={{
                      width: "20%",
                    }}
                    scope="col"
                  >
             diagnosed by
                  </th>
                  <th
                    style={{
                      width: "20%",
                    }}
                    scope="col"
                  >
                  grade
                  </th>
                  <th
                    style={{
                      width: "20%",
                      border: '1px solid #000000',
                    }}
                    scope="col"
                  >
                    diagnosis date
                  </th>
                </tr>
              </MDBTableHead>
              <tbody>
                {data?.diagnosis?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          width: "12px",
                          textaglin: "center",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          float: "Center",
                          textaglin: "center",
                        }}
                      >
                        {item?.name}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          float: "Center",
                          textaglin: "center",
                        }}
                      >
                        {item?.diagnosedby
}  
                      </td>
                      <td
                        style={{
                          width: "400px",
                          float: "Center",
                          textaglin: "Center",

                          //textaglin in center??
                        }}
                      >
                        {item?.grade}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          float: "Center",
                          textaglin: "center",
                        }}
                      >
                        {time(item?.diagnosdate)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </MDBTable>
          </MDBRow> :   <span style={{ color: "#5d9fc5", fontSize: 16 }}>Null</span>}
          <br />
          <hr></hr>
          <MDBCol xl="3">
            <h1>
              <span style={{ color: "#5d9fc5", fontSize: 20 }}>Surgical</span>
            </h1>
           
              <span style={{ color: "#5d9fc5", fontSize: 16 }}>Null</span>
     
          </MDBCol>
          <br />
          <hr></hr>
          <MDBCol xl="3">
            <h1>
              <span style={{ color: "#5d9fc5", fontSize: 20 }}>Medicines</span>
            </h1>
          </MDBCol>

          <MDBRow className="my-2 mx-1 justify-content-center">
           {
          data?.medicines?.length!=0?
          <TableM tableData2={data?.medicines} /> :
          <span style={{ color: "#5d9fc5", fontSize: 16 }}>Null</span>

           } 
          </MDBRow>
          <br />
          <hr></hr>
          <MDBCol xl="3">
            <h1>
              <span style={{ color: "#5d9fc5", fontSize: 20 }}>Radiations</span>
            </h1>
          </MDBCol>
          {
          data?.radiations?.length!=0?
          <MDBRow className="my-2 mx-1 justify-content-center">
            <MDBTable className="table" id="customers">
              <MDBTableHead
                className="text-white"
                style={{
                  backgroundColor: "#84B0CA",
                }}
              >
                <tr>
                  <th
                    style={{
                      width: "10%",
                    }}
                    scope="col"
                  >
                    #
                  </th>
                  <th
                    style={{
                      width: "30%",
                    }}
                  >
                 radiation type
                  </th>
                  <th
                    style={{
                      width: "20%",
                    }}
                    scope="col"
                  >
                  start date
                  </th>
                  <th
                    style={{
                      width: "20%",
                    }}
                    scope="col"
                  >
              tecnology
                  </th>
                  <th
                    style={{
                      width: "100px",
                      border: '1px solid #000000',
                    }}
                    scope="col"
                  >
                 status
                  </th>
                  <th
                    style={{
                      width: "20%",
                      border: '1px solid #000000',
                    }}
                    scope="col"
                  >
           end date
                  </th>
                </tr>
              </MDBTableHead>
              <tbody>
                {data?.radiations?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          width: "12px",
                          textaglin: "center",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          float: "Center",
                          textaglin: "center",
                        }}
                      >
                       {item?.radiationtype}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          float: "Center",
                          textaglin: "center",
                        }}
                      >
                        {time(item.startdate)}
                      </td>
                      <td
                        style={{
                          width: "400px",
                          float: "Center",
                          textaglin: "Center",

                          //textaglin in center??
                        }}
                      >
                        {item?.tecnology}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          float: "Center",
                          textaglin: "center",
                        }}
                      >
                        {item?.status}  
                      </td>
                      <td
                        style={{
                          width: "200px",
                          float: "Center",
                          textaglin: "center",
                        }}
                      >
                        {item?.enddate ==null ?
                       'none': item?.enddate}  
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </MDBTable>
          </MDBRow> : 
          <span style={{ color: "#5d9fc5", fontSize: 16 }}>Null</span>
}
<br>
</br>
<hr>
</hr>
          <MDBCol xl="3">
            <h1>
              <span style={{ color: "#5d9fc5", fontSize: 20 }}>Current management</span>
            </h1>
            <div className="box300">

            </div>
            <br>
</br>
<hr>
</hr>
<div className="footer">
<h1>
              <span style={{ color: "#5d9fc5", fontSize: 20 }}>Dr. Name  :</span>
              <br />
              <span style={{ color: "#5d9fc5", fontSize: 20 }}> Signature:  . . . . . . . . . </span>  
            </h1>
</div>

          </MDBCol>
        </MDBCardBody>
      </MDBCard>
}
    </MDBContainer>
  );
}
export const TableM = ({ tableData2 }) => {
  let tpchange = 0;
  let maxtp = 0;
  tableData2?.map((data2) => {
    if (data2.tp > maxtp) {
      maxtp = data2.tp;
    }
  });
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time?.getTime());
    a = new Date(a);
    //reverse date?
    return  a.toISOString().split("T")[0].split("-").reverse().join("-");



     
  }
  return (
    <div>
      <MDBTable
        className="table"
        id="customers"
        style={{
          width: "100%",
        }}
      >
        <tr style={{}}>
          <th
            style={{
              color: "black",
              background: "white",
              float: "center",
              border: "none",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "#1976D2",
                float: "center",
           
              }}
            >
              Therapeutic course
            </h5>
          </th>
          <th
            style={{
              direction: "rtl",
              color: "black",
              background: "white",
              float: "center",
              border: "none",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "#1976D2",
                float: "center",
              }}
            >
              {0}
            </h5>
          </th>
          <th
            style={{
              direction: "rtl",
              color: "black",
              background: "white",
              float: "center",
              border: "none",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "#1976D2",
                float: "center",
              }}
            >
            
              

              
            </h5>
          </th>
        </tr>
        <tr>
       
          <th
            style={{
              width: "50%",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "black",
                float: "center",
              }}
            >
              name
            </h5>{" "}
          </th>

          <th
            style={{
              width: "50px",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "black",
                float: "center",
              }}
            >
           dose
            </h5>
          </th>
          <th
            style={{
              width: "500px",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "black",
                float: "center",
              }}
            >
routes of administration
            </h5>
          </th>
          <th
            style={{
              width: "200px",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "black",
                float: "center",
              }}
            >
              frequency
            </h5>
          </th>
          <th
            style={{
              width: "200px",
            }}
          >
            <h5
              style={{
                direction: "rtl",
                color: "black",
                float: "center",
              }}
            >
              date
            </h5>
          </th>
        </tr>
        {tableData2
          ?.sort((a, b) => (a.tp > b.tp ? 1 : -1))
          ?.map((data, index) => {
            // get max tp

            if (tpchange != data.tp) {
              tpchange = data.tp;
              return (
                <>
               
                  <tr style={{}}>
                    <th
                      style={{
                        color: "black",
                        background: "white",
                        float: "center",
                        border: "none",
                      }}
                    >
                      <h5
                        style={{
                          color: "#1976D2",
                          float: "center",
                        }}
                      >
                        Therapeutic course
                      </h5>
                    </th>
                    <th
                      style={{
                        direction: "rtl",
                        color: "black",
                        background: "white",
                        float: "center",
                        border: "none",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "#1976D2",
                          float: "center",
                          
                        }}
                      >
                        {data.tp}
                      </h5>
                    </th>
                    <th
                      style={{
                        direction: "rtl",
                        color: "black",
                        background: "white",
                        float: "center",
                        border: "none",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "#1976D2",
                          float: "center",
                          
                        }}
                      >
                       
                      </h5>
                    </th>
                    <th
                      style={{
                        direction: "rtl",
                        color: "black",
                        background: "white",
                        float: "center",
                        border: "none",
                      }}
                    ></th>
                  </tr>
                  <tr>
                    {/* <th
                      style={{
                        width: "12px",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "black",
                          float: "center",
                          
                        }}
                      >
                        #
                      </h5>
                    </th> */}
                    <th
                      style={{
                        width: "50%",
                        border :"1px solid #000",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "black",
                          float: "center",
                        
                        }}
                      >
                        name
                      </h5>{" "}
                    </th>
                    <th
                      style={{
                        width: "50px",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "black",
                          float: "center",
                        }}
                      >
                    dose
                      </h5>
                    </th>
                    <th
                      style={{
                        width: "500px",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "black",
                          float: "center",
                        }}
                      >
             routes of administration
                      </h5>
                    </th>
                    <th
                      style={{
                        width: "100px",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "black",
                          float: "center",
                        }}
                      >
                        frequency
                      </h5>
                    </th>
                    <th
                      style={{
                        width: "200px",
                      }}
                    >
                      <h5
                        style={{
                          direction: "rtl",
                          color: "black",
                          float: "center",
                        }}
                      >
                   date
                      </h5>
                    </th>
                  
                  </tr>
                  <tr key={index} style={{  width: "12px",}}>
                    {/* <td
                      style={{
                        width: "12px",
                        fontsize: "12px",
                      }}
                    >
                      {index + 1}
                    </td> */}
                    <td
                      style={{
                        fontsize: "12px",
                      }}
                    >
                      {data.name}
                    </td>
                    <td
                      style={{
                        fontsize: "12px",
                      }}
                    >
                      {data.roa}
                    </td>
                    <td
                      style={{
                        fontsize: "12px",
                      }}
                    >
                      {data.dose}
                    </td>
                    <td
                      style={{
                        fontsize: "12px",
                      }}
                    >
                      {data.frequency}
                    </td>
                    <td
                      style={{
                        fontsize: "12px",
                      }}
                    >
                      {time(data?.date)}
                    </td>
                  </tr>
                </>
              );
            }

            return (
              <tr key={index}>
                {/* <td
                  style={{
                    width: "12px",
                    fontsize: "12px",
                  }}
                >
                  {index + 1}
                </td> */}
                <td
                  style={{
                    fontsize: "12px",
                  }}
                >
                  {data.name}
                </td>
                <td
                  style={{
                    fontsize: "12px",
                  }}
                >
                  {data.dose}
                </td>
                <td
                  style={{
                    fontsize: "12px",
                  }}
                >
                  {data.roa}
                </td>
                <td
                  style={{
                    fontsize: "12px",
                  }}
                >
                  {data.frequency}
                </td>
                <td
                  style={{
                    fontsize: "12px",
                  }}
                >
                  {time(data.date)}
                </td>
              </tr>
            );
          })}
      </MDBTable>
    </div>
  );
};
