import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth } from '../hooks/useAuth';


const drawerWidth = 240;


function DrawerAppBar( props ) {
  const navigate = useNavigate();
  var facility =   localStorage.getItem('facility')
  var role =   localStorage.getItem('role')

  let navItems =[]
  if(facility==''){
 
    navItems = ['New','View','Report','Admin','Search'];
  }else{
if(role == 'pharmacist'){
  navItems = [];
}
if(role == 'analist'){
  navItems = ['Report'];
}
if(role == 'reception'){
  navItems = ['New','View'];
}
 
  if(role == 'admin'){
    navItems = ['New','View','Report'];
  }
  if(role == 'doctor'){
    navItems = ['New','View','Report'];
  }

    
  }
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
       Oncology
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const auth = useAuth();
// function logout() {




// }
const closeTab = () => {
  auth.logout();
  localStorage.clear();
  navigate('/', { replace: true }).then(() => window.location.reload());

};
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
   
         { role =='pharmacist'?

<Link to='../Pharm'  sx={{ color: '#fff' }} style={{margin:'10px'}}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
          Oncology
          </Typography>
              </Link>
        :
        <Link to='../'  sx={{ color: '#fff' }} style={{margin:'10px'}}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                  >
                  Oncology
                  </Typography>
                      </Link>
                


         } 
          <Box sx={{ display: { xs: 'none', sm: 'flex' } ,justifyContent:'center' }}>
            {navItems.map((item) => (
              <Link to={'../'+item} key={item} sx={{ color: '#fff' }} style={{margin:'10px'}}>
           
                {item == 'New'? 'الإضافة':null}
                {item == 'View'? 'العرض':null}
              
                {item == 'Report'? 'التقارير':null}
                {item == 'Admin'? 'إدارة':null}
                {item == 'Search'? 'البحث':null}
              </Link>
            ))}
          </Box>
          <div className="xxx">
     
     
<Button  onClick={closeTab} variant="contained">تسجيل الخروج</Button>

       
          </div>
     
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
  
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
