import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const GetNationalitys = async () => {
  const response=  await axios
  .get("/nationalitys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const PostNationalitys = async (data) => {
  
  const response=  await axios
  .post("/nationality",
  {
    name:data.name,
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const PutNationalitys = async (data) => {
  
  const response=  await axios
  .put("/nationality?uuid="+data.uuid,
  {
    name:data.name,
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const NationalitysService = {
  GetNationalitys,
  PostNationalitys,
  PutNationalitys
}

export default NationalitysService;
