  
import React, { PureComponent, useEffect, useState } from 'react';
import xlsx from "json-as-xlsx"


import ReportService from "../../services/report.service";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dialog, DialogTitle } from '@mui/material';
import DataService from '../../services/data.service';

  
 
const  Group = () => {
  const [rows, setrows] = useState({});
  const [item, setitem] = useState('');
  const [uuid, setuuid] = useState('');
  const [facilitys, setfacilitys] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(  ()  => {

 
    DataService.Getfacilitys()
    .then((data) => data)
    .then((data) => setfacilitys(data))
    ReportService.GetGroups()
    .then((data) => data)
    .then((data) => setrows(data))
   
    .catch(function (err) {
      if (!err?.response) {
        
    } else if (err.response?.status === 400) {
    
    } else if (err.response?.status === 401 ||err.response?.status === 422) {
           localStorage.clear();
      navigate('/');
      window.location.reload();
    }
    });
    // setloading(true)

  },[])
 
  
  const navigate = useNavigate();

function go(result) {
  setitem(result)

  const facility = facilitys.filter(
    (facilitys) => facilitys.name === result
  );
  setuuid(facility[0].uuid)

  setOpen(true)

}


  return (
    <div style={{backgroundColor:'#ffffff'}}>
    
<br />

        <Dialog open={open}fullWidth={true}
        maxWidth={'md'} >
        <DialogTitle ><h5    inputProps={{min: 0, style: { textAlign: 'right' }}}>
     {item} </h5></DialogTitle>
      
         <Button onClick={()=> navigate('../Report/one/medicinepatient/:'+uuid)}>

         أحتياج الجرعات التفصيلي
         </Button>
         <Button onClick={()=> navigate('../Report/one/Facilitypatient/:'+uuid)}>
         تعداد المراكز
</Button>
<Button onClick={()=> navigate('../Report/one/Facilitypatientmedicine/:'+uuid)}>

تفصيلي العلاج المرضي
</Button>
         <div className='l'>


<Button onClick={()=> setOpen(false)}>الغاء</Button>
</div>



        </Dialog>
 <br></br>
        <div className="border rounded p-2" >

          <div  className="W100" >
       
          <h1 className="text-center" >عدد الجرعات المستخدم  بالنسبة  للشهر الواحد</h1>
          <span className='rg1'>
              <h6 id="name">
{localStorage.getItem("user")+'-:اسم المستخدم '}
</h6>


            </span>
          
    <table id="customers">
    <tr>
  
    
    <th style={{color:'#ffff',flex:1}}  className="text-center">المجموع</th>
    {  rows[0] != undefined && Object.keys(rows[0].facilitys).map((result,index) => 
    <th style={{color:'#ffff',flex:1}}  className="text-center" key={index} onClick={()=>go(result)}
    >  {result}</th>

)}
  <th style={{color:'#ffff',flex:1}}  className="text-center"></th>
  <th style={{color:'#ffff',flex:1}}  className="text-center">الاسم</th>

  <th style={{color:'#ffff',flex:1}}  className="text-center">الرمز</th>
  <th style={{color:'#ffff',flex:1}}  className="text-center">الترقيم</th>

  </tr>
  
  { rows[0] != undefined &&  (rows).map((result,index)  =>
     
  <tr className="text-center" >
    <td>

<h6  className="text-center1" style={{flex:1}}>   {parseInt(result.total.patient) ? parseInt(result.total.patient) : 0}</h6> 
              <h6  className="text-center1" style={{flex:1}}> {parseInt(result.total.quantity) ? parseInt(result.total.quantity) : 0}</h6> 
    
  </td>
  { rows[0] != undefined && Object.values(rows[index].facilitys).map((result,index)  => 
      
  <td>

<h6  className="text-center1" style={{flex:1}}>   {parseInt(result.patient) ? parseInt(result.patient) : 0}</h6> 
              <h6  className="text-center1" style={{flex:1}}> {parseInt(result.quantity) ? parseInt(result.quantity) : 0}</h6> 
    
  </td>
             
           
       )}
        
        <td>

<h6  className="text-center1" style={{flex:1}}>  المرضي </h6> 
              <h6  className="text-center1" style={{flex:1}} > الجرعات     </h6> 
    
  </td>
        <td  className="text-center"> <h6  className="text-center1" style={{flex:1}} > {rows[index]?.medicine}     </h6>  </td>
        
        <td  className="text-center"> <h6  className="text-center1" style={{flex:1}} > {rows[index]?.code}     </h6>  </td>
        <td  className="text-center"> <h6  className="text-center1" style={{flex:1}} > {index+1}     </h6>  </td>
        
        </tr> 

) }
</table>
  
      
          </div>
    
      </div>
    </div>
  )
}

export default  Group