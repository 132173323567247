import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams, Navigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import Typography from "@mui/material/Typography";
import Cookies from "universal-cookie";
import { Box, Button, Radio } from "@mui/material";
import $ from "jquery";
import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from "../../AppBar";
import api from "../../../api/axios";

const cookies = new Cookies();

const Edit = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const [nationality, setnationality] = useState([]);
  const [facility, setfacility] = useState([]);
  const [bloadtype, setbloadtype] = useState([]);
  const [medicine, setmedicine] = useState([]);
  const [oncology, setoncology] = useState([]);
  const [disease, setdisease] = useState([]);
  const [Check, setCheck] = useState([]);
  const [isMale, setisMale] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [address, setAddress] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  //valadion
  const handleChange = (event) => {
    const value = event.target.value;
    let a = [];
    let uuidvalue = null;
    if (event.target.name === "bloadtype") {
      bloadtype.map((bloadtype, index) => a.push(bloadtype.name));

      if (!a.includes(value.toUpperCase())) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#bloadtypelist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        bloadtypename: value,
      });
    }
    if (event.target.name === "nationality") {
      nationality.map((nationality, index) => a.push(nationality.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#nationalitylist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        libya: value,
        nationalityname: value,
      });
    }

    if (event.target.name === "status") {
      if (value === "") {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
        isout: true,
      });
    }
    if (event.target.name === "name") {
      if (value.length <= 5) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "mothername") {
      setCheck({ ...Check, [event.target.name]: true });

      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "phone_number") {
      if (value.length != 10) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "facility") {
      facility.map((facility, index) => a.push(facility.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#facilitylist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        facilityname: value,
      });
    }

    if (event.target.name === "NID") {
      if (value.length != 12 && state.libya === "ليبيا") {
        setCheck({ ...Check, [event.target.name]: true });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "medicine") {
      medicine.map((medicine, index) => a.push(medicine.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
    if (event.target.name === "address") {
      address.map((address, index) => a.push(address.name));

      if (!a.includes(value.toUpperCase())) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#addresslist [value="' + value + '"]').data("value");
      }

      setState({
        ...state,
        [event.target.name]: uuidvalue,
        AddresssName: value,
      });
    }
    if (event.target.name === "oncology") {
      oncology.map((oncology, index) => a.push(oncology.name));
      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
    if (event.target.name === "birthday") {
      setCheck({ ...Check, [event.target.name]: true });
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
    if (
      event.target.name != "facility" &&
      event.target.name != "bloadtype" &&
      event.target.name != "nationality" &&
      event.target.name != "address"
    ) {
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
  };

  function onSubmit() {
    var tokens = localStorage.getItem("accessToken");

    var a = false;

    if (state.status != "") {
      a = true;
    }
    api
      .put(
        `/patient?uuid=` + id,
        {
          status: state.status,
          name: state.name,
          mothername: state.mothername,
          passport: state.passport,
          NID: state.NID,
          birthday: state.birthday,
          indate: state.indate,
          exitdate: state.outdate,
          phone_number: state.phone_number,
          ismale: isMale,
          isout: a,
          address: state.address,
          facility: state.facility,
          bloadtype: state.bloadtype,
          nationality: state.nationality,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
        }
      )
      .catch(function (error) {
        if (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
      });
    Swal.fire({
      icon: "success",
      title: "success...",
      text: "تم تعديل المريض",
    }).then(() => window.location.reload());
  }

  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
  //Service Get Data
  try {
    useEffect(() => {
      DataService.Getfacilitys()
        .then((data) => data)
        .then((data) => setfacility(data))
        .catch(function (err) {
          if (!err?.response) {
            Msg("No Server Response");
          } else if (err.response?.status === 400) {
            Msg("error");
          } else if (
            err.response?.status === 401 ||
            err.response?.status === 422
          ) {
            DataService.GetRefresh().catch(function (err) {
              if (!err?.response) {
                Msg("No Server Response");
              } else if (err.response?.status === 400) {
                Msg("error");
              } else if (
                err.response?.status === 401 ||
                err.response?.status === 422
              ) {
                Msg("error");
              }
            });
          }
        });
      DataService.Getnationalitys()
        .then((data) => data)
        .then((data) => setnationality(data))
        .catch(function (err) {
          if (!err?.response) {
            Msg("No Server Response");
          } else if (err.response?.status === 400) {
            Msg("error");
          } else if (
            err.response?.status === 401 ||
            err.response?.status === 422
          ) {
            DataService.GetRefresh().catch(function (err) {
              if (!err?.response) {
                Msg("No Server Response");
              } else if (err.response?.status === 400) {
                Msg("error");
              } else if (
                err.response?.status === 401 ||
                err.response?.status === 422
              ) {
                window.location.reload();
              }
            });
          }
        });
      DataService.Getbloadtypes()
        .then((data) => data)
        .then((data) => setbloadtype(data));
      DataService.GetAddresss()
        .then((data) => data)
        .then((data) => setAddress(data));
        localStorage.getItem('role') != 'reception'&&     DataService.Getdiseases()
        .then((data) => data)
        .then((data) => setdisease(data));
        localStorage.getItem('role') != 'reception' &&    DataService.Getmedicines()
        .then((data) => data)
        .then((data) => setmedicine(data));
        localStorage.getItem('role') != 'reception' &&     DataService.Getoncologys()
        .then((data) => data)
        .then((data) => setoncology(data));
      DataService.GetonePation(id)
        .then((data) => data)
        .then((data) => {
          data.indate = new Date(data.indate);
          var a = Math.floor(data.indate.getTime());
          a = new Date(a);
          data.indate = a.toISOString().split("T")[0];
          data.birthday = new Date(data.birthday);
          var a = Math.floor(data.birthday.getTime());
          a = new Date(a);
          data.birthday = a.toISOString().split("T")[0];

          setTableData3(data.diseases);
          setTableData(data.oncologys);
          setTableData2(data.medicines);
          setTableData4(data.radiations);
          setisMale(data.ismale);
   
          setState({
            ...state,
            facilityname: data.facilityname,
            facility: data.facility,
            bloadtype: data.bloadtype,
            status: data.status,
            bloadtypename: data.bloadtypename,
            NID: data.NID,
            address: data.address,
            AddresssName: data.addressname,
            birthday: data.birthday,
            outdate: data.exitdate,
            circles: data.circles,
            indate: data.indate,
            mothername: data.mothername,
            name: data.name,
            nationality: data.nationality,
            nationalityname: data.nationalityname,
            passport: data.passport,
            phone_number: data.phone_number,
          });
          setIsLoading(true);
        });
    }, []);
  } catch (err) {
    Msg("error");
  }
  const navItems = [];

  return (
    <>
      <DrawerAppBar />
      {!isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>{" "}
        </div>
      ) : (
        <div className="App">
          <div className="Home">
            <br></br>
            <br></br>
            <>
          {
            localStorage.getItem('role') != 'reception' ?   
           <div className="floating-print-button1">
  <button 
  onClick={() => {
    navigate(
  "/View/r/"+id
)
  }}
  >
request medical report

  </button>
</div> 
: null  }
{
            localStorage.getItem('role') != 'doctor' ?

<>
<h1 className="C">{1}</h1>
              <br />
              <Typography className="R">بيانات الشخصيه</Typography>
              <div className="flex">
                <div className="0">
                  <label className="label">الاسم الرباعى</label>
                  <br></br>
                  <input
                    className="input"
                    value={state.name ? state.name : ""}
                    required
                    onChange={handleChange}
                    name="name"
                  ></input>
                  <h6 className="R">
                    {Check?.name === false ? "يرجي التأكد من عدد حروف " : ""}
                  </h6>
                </div>
                <div className="0">
                  <label className="label">اسم الأم</label>
                  <br></br>
                  <input
                    className="input"
                    value={state.mothername ? state.mothername : ""}
                    required
                    onChange={handleChange}
                    name="mothername"
                  ></input>
                  <h6 className="R">
                    {Check?.mothername === false
                      ? "يرجي التأكد من عدد حروف "
                      : ""}
                  </h6>
                </div>
              </div>
              <div className="flex">
                <div className="0">
                  <label className="label">الجنسية</label>
                  <br></br>
                  <div className="Box">
                    <input
                      className="input"
                      required
                      list="nationalitylist"
                      id="nationality"
                      value={state.nationalityname ? state.nationalityname : ""}
                      name="nationality"
                      style={{
                        margin: "1%",

                        textAlign: "start",
                        paddingRight: "2%",
                        height: "35px",
                      }}
                      onChange={handleChange}
                    />
                    <h6 className="R">
                      {Check?.nationality === false
                        ? "يرجي التأكد من الجنسية  "
                        : ""}
                    </h6>
                    <datalist id="nationalitylist">
                      {nationality.map((nationality, index) => (
                        <option
                          key={nationality.uuid}
                          value={nationality.name}
                          data-value={nationality.uuid}
                        >
                          {" "}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>
                <div className="0">
                  <label className="label">رقم الجواز</label>
                  <br></br>
                  <input
                    className="input"
                    required
                    value={state.passport ? state.passport : ""}
                    onChange={handleChange}
                    name="passport"
                  ></input>
                  <h6 className="R">
                    {Check?.passport === false
                      ? "يرجي التأكد من عدد حروف "
                      : ""}
                  </h6>
                </div>
              </div>
              <div className="flex">
                <div className="0">
                  <label className="label">الرقم الوطني </label>
                  <br></br>
                  <input
                    className="input"
                    value={state.NID ? state.NID : ""}
                    required
                    onChange={handleChange}
                    name="NID"
                  ></input>
                  <h6 className="R">
                    {Check?.NID === false ? "يرجي التأكد من عدد حروف " : ""}
                  </h6>
                </div>

                <div className="0">
                  <label className="label">تاريخ الميلاد </label>
                  <br></br>
                  <input
                    className="input1"
                    type="date"
                    required
                    value={state.birthday ? state.birthday : ""}
                    name="birthday"
                    onChange={handleChange}
                    style={{
                      margin: "1%",

                      textAlign: "end",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                  ></input>
                </div>
              </div>

              <div className="flex">
                <div className="b1">
                  <label className="label">فصيلة الدم</label>
                  <br></br>
                  <div className="Box">
                    <input
                      className="input"
                      required
                      list="bloadtypelist"
                      id="bloadtype"
                      value={state.bloadtypename ? state.bloadtypename : ""}
                      name="bloadtype"
                      style={{
                        margin: "1%",

                        textAlign: "start",
                        paddingRight: "2%",
                        height: "35px",
                      }}
                      onChange={handleChange}
                    />
                    <h6 className="R">
                      {Check?.bloadtype === false
                        ? "يرجي التأكد من فصيلة الدم "
                        : ""}
                    </h6>
                    <datalist id="bloadtypelist">
                      {bloadtype.map((bloadtype, index) => (
                        <option
                          key={bloadtype.uuid}
                          value={bloadtype.name}
                          data-value={bloadtype.uuid}
                        >
                          {" "}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>
                <div className="0">
                  <label className="label"> مكان العلاج</label>
                  <br></br>
                  <input
                    list="facilitylist"
                    id="facility"
                    name="facility"
                    className="input"
                    value={state.facilityname ? state.facilityname : ""}
                    required
                    style={{
                      margin: "1%",

                      textAlign: "Start",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                    onChange={handleChange}
                  />
                  <h6 className="R">
                    {Check?.facility === false
                      ? "  يرجي  التأكد من  حروف المدينة"
                      : ""}
                  </h6>
                  <datalist id="facilitylist">
                    {facility.map((facility) => (
                      <option
                        key={facility.uuid}
                        value={facility.name}
                        data-value={facility.uuid}
                      >
                        {" "}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="flex">
                <div className="">
                  <label className="label"> تاريخ الدخول </label>
                  <br></br>
                  <input
                    className="input1"
                    type="date"
                    required
                    name="indate"
                    value={state.indate ? state.indate : ""}
                    onChange={handleChange}
                    style={{
                      margin: "1%",

                      textAlign: "end",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                  ></input>
                </div>
                <div className="">
                  <label className="label"> تاريخ الخروج </label>
                  <br></br>
                  <input
                    className="input1"
                    type="date"
                    required
                    name="outdate"
                    style={{
                      margin: "1%",

                      textAlign: "end",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                    value={state.outdate ? state.outdate : ""}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
              <div className="flex">
                <div className="">
                  <label className="label"> نوع الخروج </label>

                  <br></br>
                  <select
                    className="input"
                    value={state.status ? state.status : ""}
                    required
                    onChange={handleChange}
                    name="status"
                  >
                    <option value=""></option>

                    <option value="خروج طبيعي">خروج طبيعي</option>
                    <option value="خروج علي المسؤلية">خروج علي المسؤلية</option>
                    <option value="هروب">هروب</option>
                    <option value="وفاة">وفاة</option>
                    <option value="رافض الدخول ">رافض الدخول</option>
                  </select>
                </div>
                <div className="c1">
                  <label className="label">الجنس</label>
                  <br />

                  <div className="flex">
                    <input
                      type="radio"
                      id="html"
                      className="kk"
                      name="fav_language"
                      value="ذكر"
                      required
                      checked={isMale}
                      onClick={() => setisMale(true)}
                    />

                    <label>ذكر</label>
                    <input
                      type="radio"
                      id="css"
                      className="kk"
                      name="fav_language"
                      value="أنثى "
                      required
                      checked={!isMale}
                      onClick={() => setisMale(false)}
                    />
                    <label>أنثى </label>
                  </div>
                </div>
              </div>
           
            <br></br>
            <div className="flex">
              <div className="0">
                <div className="Flex1">
                  <label className="label"> محل الأقامة</label>
                </div>

                <input
                  list="addresslist"
                  id="address"
                  name="address"
                  value={state.AddresssName ? state.AddresssName : ""}
                  className="input"
                  style={{
                    margin: "1%",

                    textAlign: "start",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.address === false ? "  يرجي  من  حروف المدينة" : ""}
                </h6>
                <datalist id="addresslist">
                  {address.map((address) => (
                    <option
                      key={address.uuid}
                      value={address.name}
                      data-value={address.uuid}
                    >
                      {" "}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className="0">
                <div className="Flex1">
                  <label className="label"> رقم الهاتف </label>
                </div>

                <input
                  list="phone_numberlist"
                  id="phone_number"
                  type="number"
                  value={state.phone_number ? state.phone_number : ""}
                  name="phone_number"
                  className="input"
                  style={{
                    margin: "1%",

                    textAlign: "start",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.phone_number === false ? "  يرجي  من  حروف رقم" : ""}
                </h6>
              </div>
            </div>
            <Button onClick={() => onSubmit()}>تعديل</Button>
</>
                : null}
                {
            localStorage.getItem('role') == 'doctor' ?

<>
<h1 className="C">{1}</h1>
              <br />
              <Typography className="R">بيانات الشخصيه</Typography>
              <div className="flex">
                <div className="0">
                  <label className="label">الاسم الرباعى</label>
                  <br></br>
                  <input
                    className="input"
                    value={state.name ? state.name : ""}
                    required
                    onChange={handleChange}
                    disabled = {true}
                    name="name"
                  ></input>
                 
                </div>
                <div className="0">
                  <label className="label">تاريخ الميلاد </label>
                  <br></br>
                  <input
                    className="input1"
                    type="date"
                    required
                    value={state.birthday ? state.birthday : ""}
                    name="birthday"
                    disabled = {true}
                    onChange={handleChange}
                    style={{
                      margin: "1%",

                      textAlign: "end",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                  ></input>
                </div>
              </div>
          
              <div className="flex">
                <div className="b1">
                  <label className="label">فصيلة الدم</label>
                  <br></br>
                  <div className="Box">
                    <input
                      className="input"
                      required
                      list="bloadtypelist"
                      id="bloadtype"
                      value={state.bloadtypename ? state.bloadtypename : ""}
                      name="bloadtype"
                      disabled = {true}
                      style={{
                        margin: "1%",

                        textAlign: "start",
                        paddingRight: "2%",
                        height: "35px",
                      }}
                      onChange={handleChange}
                    />
                
                  </div>
                </div>
                <div className="">
                  <label className="label"> تاريخ الدخول </label>
                  <br></br>
                  <input
                    className="input1"
                    type="date"
                    disabled = {true}
                    name="indate"
                    value={state.indate ? state.indate : ""}
                
                    style={{
                      margin: "1%",

                      textAlign: "end",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                  ></input>
                </div>
              </div>
             
              <div className="flex">
               
                <div className="c1">
                  <label className="label">الجنس</label>
                  <br />

                  <div className="flex">
                    <input
                      type="radio"
                      id="html"
                      className="kk"
                      name="fav_language"
                      value="ذكر"
                      required
                      disabled = {true}
                      checked={isMale}
                      onClick={() => setisMale(true)}
                    />

                    <label>ذكر</label>
                    <input
                      type="radio"
                      id="css"
                      className="kk"
                      name="fav_language"
                      value="أنثى "
                 disabled = {true}
                      checked={!isMale}
                      onClick={() => setisMale(false)}
                    />
                    <label>أنثى </label>
                  </div>
                </div>
              </div>
           
            <br></br>
        
        
</>
                : null}
            
            <br />
            <br /> <br />
            <br />
            {
              localStorage.getItem("role") != "reception" ?
        
            <>
        
            <>
              <h1 className="C">{2}</h1>
              <br />

              <Typography className="R">بيانات التشخيص</Typography>

              <Box
                sx={{
                  height: tableData.length <= 5 ? 500 : 1000,
                  backgroundColor: "primary",
                }}
              >
                <Table tableData={tableData} />
              </Box>
            </>
            <>
              <h1 className="C">{3}</h1>
              <br />
              <Typography className="R">بيانات العلاج</Typography>

              <br />

              <Box
                sx={{
                  height: tableData2.length <= 5 ? 1000 : 2000,
                  backgroundColor: "primary",
                }}
              >
                <Table2 tableData2={tableData2} />
              </Box>
            </>
            <h1 className="C">{4}</h1>
            <br />
            <Typography className="R"> الأمراض المصاحبة</Typography>
            <br />
            <Box
              sx={{
                height: tableData3.length <= 5 ? 500 : 2000,
                backgroundColor: "primary",
              }}
            >
              <Table3 tableData3={tableData3} />
            </Box>
            <>
              <h1 className="C"> 5</h1>
              <br />
              <Typography className="R"> العلاج الاشعاعي </Typography>

              <Box
                sx={{
                  height: tableData4.length <= 4 ? 400 : 1000,
                  backgroundColor: "primary",
                }}
              >
                <Table4 tableData4={tableData4} />
              </Box>
            </>
            </>
            : null  }
          </>
          
          </div>   </div>
      )}
    </>
  );
};

export default Edit;

export const Table = ({ tableData }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  function time(time) {
    // time = new Date(time);
    // var a = Math.floor(time.getTime());
    // a = new Date(a);
    return time;
  }
  return (
    <div>
<table className="table" id="customers">
  <thead>
    <tr>
      <th>
        <h3 style={{ direction: "rtl", color: "white", float: "center" }}>
          الرقم
        </h3>
      </th>
      <th>
        <h3 style={{ direction: "rtl", color: "white", float: "center" }}>
          رمز الورم
        </h3>
      </th>
      <th>
        <h3 style={{ direction: "rtl", color: "white", float: "center" }}>
          اسم الورم
        </h3>
      </th>
      <th>
        <h3 style={{ direction: "rtl", color: "white", float: "center" }}>
          المستوى
        </h3>
      </th>
      <th>
        <h3 style={{ direction: "rtl", color: "white", float: "center" }}>
          تاريخ التشخيص
        </h3>
      </th>
      <th>
        <h3 style={{ direction: "rtl", color: "white", float: "center" }}>
          تعديل
        </h3>
      </th>
    </tr>
  </thead>
  <tbody>
    {tableData.map((data, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{data.code}</td>
          <td>{data.name}</td>
          <td>{data.level}</td>
          <td>{time(data.diagnosisdate)}</td>
          <td>
            <button
              style={{
                background: "#1976D2",
                color: "white",
                border: "none",
                width: "100px",
              }}
              onClick={() => navigate("/View/oncologys/" + id)}
              className=""
            >
              تعديل
            </button>
          </td>
        </tr>
      );
    })}
    {tableData.length === 0 ? (
      <tr>
        <td colSpan="6">1</td>
        <td colSpan="5">لايوجد مرض</td>
        <td colSpan="5">لايوجد مرض</td>
        <td colSpan="5">لايوجد مرض</td>
        <td colSpan="5">لايوجد مرض</td>
        <td colSpan="5">
          <button
            style={{
              background: "#1976D2",
              color: "white",
              border: "none",
              width: "100px",
            }}
            onClick={() => navigate("/View/oncologys/" + id)}
            className=""
          >
            تعديل
          </button>
        </td>
      </tr>
    ) : null}
  </tbody>
</table>


    </div>
  );
};
export const Table2 = ({ tableData2 }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  let tpchange = 0;
  let maxtp = 0;
  tableData2.map((data2) => {
    if (data2.tp > maxtp) {
      maxtp = data2.tp;
    }
  });
  return (
    <div>
      <table className="table" id="customers">
        <thead>
       
        </thead>
        <tbody>
        <tr>
                  <th
                      
                          style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            border: "none",
                            float: "center",
                          }}
                    >
                      </th>
                      <th    style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <h3
                          style={{
                            direction: "rtl",
                            color: "#1976D2",
                            float: "center",
                          }}
                        >
                          الدورة العلاجية
                        </h3>
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <h3
                          style={{
                            direction: "rtl",
                            color: "#1976D2",
                            float: "center",
                          }}
                        >
                          {
                            0
                          }
                        </h3>
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                    
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                       
                   
                      </th>  <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <button
                          style={{
                            background   :   "#1976D2",
                            color  : "white",
                            border: "none",
                            width: "100px",
                          }}
                          onClick={() =>
                            tpchange == 0 ?
                            navigate("/View/Medicines/" + id + "&" + 0)
                            : navigate("/View/Medicinesview/" + id + "&" + 0)
                          }
                          className=""
                        >
                     {
                      tpchange != 0 ? "تعديل" : "عرض"
                     }
                        </button>
                      </th>
                      </tr >
                      <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم العلاج
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الدورة العلاجية
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الجرعة
              </h3>
            </th>
          

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الكمية
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
          التكرار
              </h3>
            </th>

          </tr>
          {tableData2
            .sort((a, b) => (a.tp > b.tp ? 1 : -1))
            .map((data, index) => {
              // get max tp
            

              if (tpchange != data.tp) {
                tpchange = data.tp;
                return (
                  <>
                  
                     <tr style={{
                      background: "#ffffff",
                     }} >
                    
                    <br></br>
                       </tr>
                       <tr>
                  <th
                      
                          style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            border: "none",
                            float: "center",
                          }}
                    >
                      </th>
                      <th    style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <h3
                          style={{
                            direction: "rtl",
                            color: "#1976D2",
                            float: "center",
                          }}
                        >
                          الدورة العلاجية
                        </h3>
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <h3
                          style={{
                            direction: "rtl",
                            color: "#1976D2",
                            float: "center",
                          }}
                        >
                          {
                          data.tp
                          }
                        </h3>
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                     <h3
                          style={{
                            direction: "rtl",
                            color: "#1976D2",
                            float: "center",
                          }}
                        >
                          {
                          //data.created_at

                          }
                        </h3>
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                       
                   
                      </th>  <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <button
                          style={{
                            background   :   "#1976D2",
                            color  : "white",
                            border: "none",
                            width: "100px",
                          }}
                          onClick={() =>
                            
                            data.tp ==   maxtp ?
                            navigate("/View/Medicines/" + id + "&" + data.tp)
                            :
                            navigate("/View/Medicinesview/" + id + "&" + data.tp)
                          }
                          className=""
                        >
                     {
                      tpchange ==   maxtp ? "تعديل" : "عرض"
                     }
                        </button>
                      </th>
                      </tr >
                      <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم العلاج
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الدورة العلاجية
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الجرعة
              </h3>
            </th>
          

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الكمية
              </h3>
            </th>
          
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
          التكرار
              </h3>
            </th>

          </tr>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.name}</td>

                      <td>{data.tp}</td>
                      <td>{data.dose}</td>
                      <td>{data.frequency}</td>

                      <td>{data.quantity}</td>
                 
                    </tr>
                 </>
                 
                );
              }

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.name}</td>

                  <td>{data.tp}</td>
                  <td>{data.dose}</td>
                  <td>{data.frequency}</td>

                  <td>{data.quantity}</td>
             
                </tr>
                
              );
            })}
            
          {tableData2.length == 0 ? <td>1</td> : null}
          {tableData2.length == 0 ? <td>لايوجد علاج</td> : null}
          {tableData2.length == 0 ? <td>0</td> : null} {tableData2.length == 0 ? <td>0</td> : null} {tableData2.length == 0 ? <td>0</td> : null} {tableData2.length == 0 ? <td>0</td> : null}
       
          
        </tbody>
        <tr>
                  <th
                      
                          style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            border: "none",
                            float: "center",
                          }}
                    >
                      </th>
                      <th    style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <h3
                          style={{
                            direction: "rtl",
                            color: "#1976D2",
                            float: "center",
                          }}
                        >
                          الدورة العلاجية
                        </h3>
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <h3
                          style={{
                            direction: "rtl",
                            color: "#1976D2",
                            float: "center",
                          }}
                        >
                          {
                           maxtp+1
                          }
                        </h3>
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                    
                      </th>
                      <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                       
                   
                      </th>  <th style={{
                            direction: "rtl",
                            color: "white",
                            background : "white",
                            float: "center",
                            border: "none",
                          }}>
                        <button
                          style={{
                            background   :   "#1976D2",
                            color  : "white",
                            border: "none",
                            width: "100px",
                          }}
                          onClick={() =>{
                            var newtp =maxtp +1
                        
                            navigate("/View/Medicines/" + id + "&" +  newtp)
                           
                          }
                        
                           
                          }
                          className=""
                        >
                     {
                      "اضافة"
                     }
                        </button>
                      </th>
                      </tr >
      </table>
    </div>
  );
};
export const Table3 = ({ tableData3 }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم المرض
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                تعديل
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData3.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>

                <td>
                  <button
                    style={{
                      background: "#1976D2",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => navigate("/View/Disease/" + id)}
                    className=""
                  >
                    تعديل
                  </button>
                </td>
              </tr>
            );
          })}
          {tableData3.length == 0 ? <td>1</td> : null}
          {tableData3.length == 0 ? <td>لايوجد امراض</td> : null}
          {tableData3.length == 0 ? (
            <td>
              <button
                style={{
                  background: "#1976D2",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                onClick={() => navigate("/View/Disease/" + id)}
                className=""
              >
                تعديل
              </button>
            </td>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};
export const Table4 = ({ tableData4 }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time.getTime());
    a = new Date(a);
    return a.toISOString().split("T")[0];
  }
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                هدف العلاج{" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                نوع الإشعاع{" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                الكمية الإجمالية للاشعاع
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                نوع التقنية
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                تاريخ البدء
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                تعديل{" "}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData4.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.status}</td>
                <td>{data.radiationtype}</td>
                <td>{data.name}</td>
                <td>{data.tecnology}</td>
                <td>{time(data.startdate)}</td>
                <td>
                  <button
                    style={{
                      background: "#1976D2",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => navigate("/View/Radiations/" + id)}
                    className=""
                  >
                    تعديل
                  </button>
                </td>
              </tr>
            );
          })}
          {tableData4.length == 0 ? <td>1</td> : null}
          {tableData4.length == 0 ? <td>0</td> : null}
          {tableData4.length == 0 ? <td>0</td> : null}
          {tableData4.length == 0 ? <td>0</td> : null}
          {tableData4.length == 0 ? <td>0</td> : null}
          {tableData4.length == 0 ? <td>0</td> : null}
          {tableData4.length == 0 ? (
            <td>
              <button
                style={{
                  background: "#1976D2",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                onClick={() => navigate("/View/Radiations/" + id)}
                className=""
              >
                تعديل
              </button>
            </td>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};
