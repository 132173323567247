import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, useGridApiContext } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useForm } from "react-hook-form";
import DialogTitle from "@mui/material/DialogTitle";


import DataService from "../../../services/data.service";
import Swal from "sweetalert2";
import UserService from "../../../services/users.service";
import { InputLabel, NativeSelect, Select, Switch } from "@mui/material";
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';



import { BiSave } from "react-icons/bi";
import FacilitysService from "../../../services/facility.service";
import { exportToCSV } from "../../export";

const User = () => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState({});
  const [Facilitys, setFacilitys] = React.useState(null);
  const tableRef = useRef(null);
console.log(rows)

  

  function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <Select
      value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
          <option
                        value={'AJK'}
                      >
                        AJK
                      </option>
         {Facilitys != null && Facilitys.map((Facilitys) => (
                      <option
                        value={Facilitys.name}
                      >
                        {Facilitys.name}
                      </option>
                    ))}
      </Select>
    );
  }

  
  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };
  

  function SelectEditInputCell1(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option value={'admin'}>Admin</option>
                    <option value={'doctor'}>Doctor</option>
                    <option value={'reception'}>Reception</option>
                    <option value={'pharmacist'}>pharmacist</option>
                    <option value={'analist'}>analist</option>
      </Select>
    );
  }

  
  const renderSelectEditInputCell1 = (params) => {
    return <SelectEditInputCell1 {...params} />;
  };
  
  const columns = [
    {
      field: "username",
      headerName: "username",
      flex: 1,
     

    },
    {
      field: "birthday",
      headerName: "birthday",
      flex: 1,
      editable: true,
      hide: true
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: true,
      hide: true
    },
    {
      field: "facility",
      headerName: "Facility",
      flex: 2,
      editable: true,
      renderEditCell: renderSelectEditInputCell,
      renderCell: (params) => {
        
      
       


        if (params.row.facility == '') {
          return 'AJK';
        } else {
          return params.row.facility;
        }

      },
 
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderEditCell: renderSelectEditInputCell1,
      editable: true
    },
    {
      field: "phone_number",
      headerName: "phone number",
      flex: 1,
      editable: true,
      hide: true
    },

    {
      field: "status",
      headerName: "status",
      flex: 1,
      renderCell: (params) => {
        
        const onClick = (e) => {
          if (params.row.status == 'Active') {
            UserService.ActiveUser(params.row, e).then(function (response) {
            setOpen(false);
            Swal.fire({
              icon: "success",
              title: "success...",
              text: "   تم تعطيل المستخدم  بالنجاح",
            }).then(() => window.location.reload());
          })
            .catch(function (error) {
                
              if (error.response) {
                Swal.fire({
                  icon: "error",
                  title: "",
                  text: error.response?.data?.error + "!",
                });
                return 0;
              }
              if (!error.status) {
                if (error) {
                  Swal.fire({
                    icon: "error",
                    title: "NetWork",
                    text: "مشكلة في شبكه!",
                  });
                  return 0;
                }
              }
            });
          } else {
            UserService.DisActiveUser(params.row, e).then(function (response) {
              setOpen(false);
              Swal.fire({
                icon: "success",
                title: "success...",
                text: "   تم تفعيل المستخدم  بالنجاح",
              }).then(() => window.location.reload());
            })
              .catch(function (error) {
                  
                if (error.response) {
                  Swal.fire({
                    icon: "error",
                    title: "",
                    text: error.response?.data?.error + "!",
                  });
                  return 0;
                }
                if (!error.status) {
                  if (error) {
                    Swal.fire({
                      icon: "error",
                      title: "NetWork",
                      text: "مشكلة في شبكه!",
                    });
                    return 0;
                  }
                }
              });
          }
         
        };



        if (params.row.status == 'Active') {
          return <Switch defaultChecked onChange={() => onClick('Disactive')} />;
        } else {
          return <Switch onChange={() => onClick('Active')} />;
        }

      },
    },
    {
      field: "actionEdit",
      headerName: "Save",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          let a = []
          let uuidFacilitys 
          Facilitys.map((Facilitys, index) => a.push(Facilitys.name));
          for (let index = 0; index < Facilitys.length; index++) {
            if (Facilitys[index].name == params.row.facility) {
              (uuidFacilitys = Facilitys[index].uuid)

            }

          }
      UserService.PutUser(params.row,uuidFacilitys).then(function (response) {
            setOpen(false);
            Swal.fire({
              icon: "success",
              title: "success...",
              text: "   تم تعديل المستخدم بالنجاح",
            }).then(() => window.location.reload());
          })
            .catch(function (error) {

              if (error.response) {
                Swal.fire({
                  icon: "error",
                  title: "",
                  text: error.response?.data?.error + "!",
                });
                return 0;
              }
              if (!error.status) {
                if (error) {
                  Swal.fire({
                    icon: "error",
                    title: "NetWork",
                    text: "مشكلة في شبكه!",
                  });
                  return 0;
                }
              }
            });
        };

        return <Button onClick={onClick}><BiSave /></Button>;
      },
    },
  ];




  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) =>
    UserService.PostUser(data)
      .then(function (response) {
        setOpen(false);
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "   تم أضافه المستخدم بالنجاح",
        }).then(() => window.location.reload());
      })
      .catch(function (error) {
        setOpen(false);
        if (error.response) {
          Swal.fire({
            icon: "error",
            title: "",
            text: error.response?.data?.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          if (error) {
            Swal.fire({
              icon: "error",
              title: "NetWork",
              text: "مشكلة في شبكه!",
            });
            return 0;
          }
        }
      });

  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
  //Service Get Data
  try {
    useEffect(() => {
      UserService.GetUsers()
        .then((data) => data)
        .then((data) => setRows(data))
      FacilitysService.GetFacility()
        .then((data) => data)
        .then((data) => setFacilitys(data))
        .catch(function (err) {
          if (!err?.response) {
            Msg("No Server Response");
          } else if (err.response?.status === 400) {
            Msg("error");
          } else if (
            err.response?.status === 401 ||
            err.response?.status === 422
          ) {
            DataService.GetRefresh().then(window.location.reload()).catch(function (err) {
              if (!err?.response) {
                Msg("No Server Response");
              } else if (err.response?.status === 400) {
                Msg("error");
              } else if (
                err.response?.status === 401 ||
                err.response?.status === 422
              ) {
                window.location.reload();
              }
            });
          }
        });
    }, []);
  } catch (err) { }
  return (
    <div className="w-100">
      <div className="center">
        <Button variant="outlined" onClick={() => setOpen(true)}>
          Add new user
        </Button>
        <Dialog open={open} fullWidth={true}
          maxWidth={'md'} >
          <DialogTitle ><h5 inputProps={{ min: 0, style: { textAlign: 'right' } }}>
            Add  </h5></DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <div className="flex">
                <div>
                  <TextField
                    id="standard-basic"
                    label="username"
                    variant="standard"
                    {...register("username", { required: true })}

                    sx={{
                      width: '100%',
                    }} />
                  <br />
                  {errors.username && <span className="R2">يرجي ادخال البيانات</span>}
                  <br />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="name"
                    variant="standard"
                    {...register("name", { required: true })}

                    sx={{
                      width: '100%',
                    }} />
                  <br />
                  {errors.name && <span className="R2">يرجي ادخال البيانات</span>}
                  <br />
                </div>

              </div>
              <div className="flex">
                <div>
                  <TextField
                    id="standard-basic"
                    label="phone_number"
                    variant="standard"
                    {...register("phone_number", { required: true })}

                    sx={{
                      width: '100%',
                    }} />
                  <br />
                  {errors.phone_number && <span className="R2">يرجي ادخال البيانات</span>}
                  <br />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="password"
                    variant="standard"
                    {...register("password", { required: true })}

                    sx={{
                      width: '100%',
                    }} />
                  <br />
                  {errors.password && <span className="R2">يرجي ادخال البيانات</span>}
                  <br />
                </div>
              </div>

              <div className="flex">
                <div className="ml150">
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Role
                  </InputLabel>
                  <NativeSelect
                    {...register("role", { required: true })}

                    inputProps={{
                      name: 'role',

                    }}
                    sx={{

                      width: '250px',
                    }}
                  >
                    <option ></option>
                    <option value={'admin'}>Admin</option>
                    <option value={'doctor'}>Doctor</option>
                    <option value={'reception'}>Reception</option>
                    <option value={'pharmacist'}>pharmacist</option>
                    <option value={'analist'}>analist</option>
                  </NativeSelect>


                  <br />
                  {errors.role && <span className="R2">يرجي ادخال البيانات</span>}
                  <br />
                </div>
                <div>


                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Facility
                  </InputLabel>
                  <NativeSelect
                    {...register("facility", { required: true })}

                    inputProps={{
                      name: 'facility',

                    }}
                    sx={{
                      width: '250px',
                    }}
                  >
                  <option
                        value={'AJK'}
                      >
                        AJK
                      </option>

                    {Facilitys != null && Facilitys.map((Facilitys) => (
                      <option
                        value={Facilitys.uuid}

                      >
                        {Facilitys.name}
                      </option>
                    ))}
                  </NativeSelect>


                  <br />
                  {errors.facility && <span className="R2">يرجي ادخال البيانات</span>}
                  <br />

                </div> </div>
              <div style={{ margin: '0 85px' }}>
                <TextField
                  variant="standard"
                  id="date"
                  label="Birthday"
                  type="date"
                  {...register("birthday", { required: true })}
                  defaultValue="2017-05-24"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                {errors.facility && <span className="R2">يرجي ادخال البيانات</span>}
                <br />
              </div>

            </DialogContent>
            <DialogActions className='l'>


              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button type="submit">Save</Button>

            </DialogActions>
          </form>
        </Dialog>

      </div>
      <br />
      <Box sx={{ height: "84vh", width: "100%" }}>
      {/* <Button onClick={(e) => exportToCSV(rows,'a')}>Export</Button> */}
        <DataGrid
          loading={rows.length === 0}
          rows={rows}
          // components={{ Toolbar: GridToolbar }}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowId={(rows1) => rows1?.uuid}
          experimentalFeatures={{ newEditingApi: true }}

        />
      </Box>
    </div>
  );
};

export default User;
