import Cookies from "universal-cookie";
import axios from "../api/axios";


const cookies = new Cookies();

var tokens =localStorage.getItem("accessToken");

// s

const Getbloadtypes = async () => {
  const response=  await axios
  .get("/report/oncology/bloodtype", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const GetGroups = async () => {
  const response=  await axios
  .get("/report/medicine/group", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const Getmedicinecity = async () => {
  const response=  await axios
  .get("/report/oncology/facility", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getmedicine = async () => {
  const response=  await axios
  .get("/report/medicine", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetSex = async () => {
  const response=  await axios
  .get("/report/oncology/sex", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getbirthdate = async () => {
  const response=  await axios
  .get("/report/oncology/birthdate", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getindate = async () => {
  const response=  await axios
  .get("/report/oncology/indate", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetOutedate = async () => {
  const response=  await axios
  .get("/report/oncology/out", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getbloodtype = async () => {
  const response=  await axios
  .get("/report/patient/bloodtype", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getsex = async () => {
  const response=  await axios
  .get("/report/patient/sex", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const facilitypatient = async (uuid) => {
 
  if(uuid == 0){
    const response=  await axios
    .get("/report/medicine/patient", {
      headers: {
        Authorization: `Bearer ${tokens}`,
      },
});
return response.data;
  }else{
    const response=  await axios
    .get("/report/medicine/patient?uuid="+uuid, {
      headers: {
        Authorization: `Bearer ${tokens}`,
      },
});
return response.data;

  }

};

const medicinefacility = async (uuid) => {
if(uuid==''){
uuid=''
}else{
uuid='?facility='+uuid
}
    const response=  await axios
    .get("/report/facility/medicine"+uuid, {
      headers: {
        Authorization: `Bearer ${tokens}`,
      },
});
console.log( response.data)
return response.data;

}
const Facilitypatientmedicine = async (uuid) => {
 

    const response=  await axios
    .get("/report/facility/patient/medicine?facility="+uuid, {
      headers: {
        Authorization: `Bearer ${tokens}`,
      },
});
return response.data;



};
const medicinepatient = async (uuid) => {
 
  if(uuid == 0){
    const response=  await axios
    .get("/report/medicine/patient", {
      headers: {
        Authorization: `Bearer ${tokens}`,
      },
});
return response.data;
  }else{
    const response=  await axios
    .get("/report/medicine/patient?uuid="+uuid, {
      headers: {
        Authorization: `Bearer ${tokens}`,
      },
});
return response.data;

  }

};
const MAP = async () => {
  const response=  await axios
  .get("/report/map/patient", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const init = async () => {
  const response=  await axios
  .get("/report/statistics/city-oncology/init", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const initsex = async (address,oncology) => {
  console.log(address,oncology)
  let a = 'list'

if (address=='OPK') {
  
  a='OPK'
}

  const response=  await axios
  .post("report/statistics/city-oncology/sex?city="+a+"&oncology="+oncology+"", 
  {
    "cities":  address
  },{
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });

  return response.data;
};
const inityear = async (address,oncology,year) => {

let a = 'list'
if (address=='OPK') {
  a='OPK'
}
  const response=  await axios
  .post("report/statistics/city-oncology/indate?city="+a+"&oncology="+oncology+"&year="+year+"", 
    {
      "cities":  address
    },
  {
    headers: {
      Authorization: `Bearer ${tokens}`,
    },
  },

  );

  return response.data;
};
const inicity = async (oncology,address) => {
  console.log(address,oncology)
  let a = 'list'

  if (oncology=='OPK') {
  a='OPK'
  }


  
    const response=  await axios
    .post("report/statistics/oncology-city/sex?oncology="+a+"&city="+address+"", 
      {
        "oncologies":  oncology=='OPK'?[oncology]: oncology
      },
    {
      headers: {
        Authorization: `Bearer ${tokens}`,
      },
    },
  
    );
    console.log(response)
    return response.data;
  };
const ReportService = {
  Getmedicine,
  Getmedicinecity,
  GetGroups,
  GetSex,
  Getbirthdate,
  Getbloadtypes,
  medicinepatient,
  Getindate,
  GetOutedate,
  Getbloodtype,
  Getsex,
  MAP,
  init,
  initsex,
  inityear,
  facilitypatient,
  medicinefacility,
  Facilitypatientmedicine,
  inicity
}

export default ReportService;
