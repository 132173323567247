import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import Typography from "@mui/material/Typography";
import Cookies from "universal-cookie";
import { Box, Button, Radio } from "@mui/material";
import $, { map } from "jquery";
import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from '../../AppBar'
import api from "../../../api/axios";
const cookies = new Cookies();

const Disease = () => {
  const [TableData3, setTableData3] = useState([]);
  const [formInputData3, setformInputData3] = useState({
    uuid: null,
    name: null,
  });
  const [isloading, setisloading] = useState(false);
  const [name, setname] = useState('أضافه');
  const [Uuid, setUuid] = useState(null);

  let { id } = useParams();
  console.log( id )
  const [isloading3, setisloading3] = useState(true);

  const [disease, setdisease] = useState([]);
  const [Check, setCheck] = useState([]);
 

  const handleChangeTable3 = (evnt) => {
    setisloading3(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;


    if (evnt.target.name === "name") {
      uuidvalue = $('#diseaselist [value="' + value + '"]').data("value");
      console.log(uuidvalue);

      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });

      if (uuidvalue != undefined) {
        setformInputData3(newInput);
       
        setCheck({ ...Check, [evnt.target.name]: true });
        if (formInputData3.uuid != undefined) {
          setisloading3(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }

    setformInputData3(newInput);
    if (formInputData3.uuid != undefined) {
      console.log(formInputData3);
    } else {
      setisloading3(false);
    }
  };
  const handleChange = (data) => {
    setisloading3(false);
    setUuid(data.uuid)
    const employeesCopy = TableData3.filter(
      (employee) => employee.uuid !== data.uuid

    );
    setisloading(false);
    document.getElementById("name").value =  data.name;
    var  uuidvalue = $('#diseaselist [value="' + data.name + '"]').data("value");

      console.log(uuidvalue)
      setformInputData3({
       name: data.name,
        uuid: uuidvalue,
       
      });
  
    setTableData3(employeesCopy);
setname('تعديل')

};
const handleSubmit3 = (evnt) => {
  
  setisloading(true);
  evnt.preventDefault();
  const checkEmptyInput = !Object.values(formInputData3).every(
    (res) => res === ""
  );
  if (checkEmptyInput) {
    const newData = (data) => [...data, formInputData3];
    setTableData3(newData);
    const emptyInput = {
      uuid: undefined,
      name: undefined,
    };
    var tokens = localStorage.getItem("accessToken");
 
  
    if(name=='تعديل'){
     console.log(formInputData3.uuid )
      api
      .put(
        `patient/disease?uuid=` + Uuid,
        {
          "disease": formInputData3.uuid  
      },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
        }
      )
      .catch(function (error) {
          ;
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
      });
    Swal.fire({
      icon: "success",
      title: "success...",
      text: "تم تعديل المريض",
    })
    }else{
      api
      .post(
        `/patient/disease/add`,
        {
          "patient":id,
          "disease": formInputData3.uuid  
      },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
        }
      
      )
      .catch(function (error) {
          ;
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
      });
    Swal.fire({
      icon: "success",
      title: "success...",
      text: "تم أضافة المرض للمريض",
    })
   
    }

    setformInputData3(emptyInput);
    document.getElementById("name").value = "";
  
    setname('أضافه')

  }
};
  //Delete

function Msg(params) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: params+ "!",
  });
  return 0;
}
  //Service Get Data
  try {
    useEffect(() => {

      DataService.Getdiseases()
      .then((data) => data)
      .then((data) => setdisease(data)).catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
          } else if (err.response?.status === 400) {
            Msg('error');
          } else if (err.response?.status === 401 || err.response?.status === 422) {

            DataService.GetRefresh().catch(function (err) {
              if (!err?.response) {
                Msg('No Server Response');
              } else if (err.response?.status === 400) {
                Msg('error');
              } else if (err.response?.status === 401 || err.response?.status === 422) {
                Msg('error');
              }
            })
          }
        });
        DataService.GetonePation(id)
        .then((data) => data)
        .then((data) => {
         setTableData3(data.diseases)
        })
       
    }, []);
   
  } catch (err) {
   
  }
  const navItems = [];

  return (
    <>
      <DrawerAppBar navItems = {navItems} />
 
    <div className="App">
     
    <div className="Home">
     <br></br>
     <br></br>
  
    <br />

    <>
          
            <br />
            <Typography className="R"> الأمراض المصاحبة</Typography>

            <FormInput3
              handleChangeTable3={handleChangeTable3}
              handleSubmit3={handleSubmit3}
              disease={disease}
              Check={Check}
              isloading3={isloading3}
              name={name}
            />
            <br />

            <Box
              sx={{
                height: TableData3.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table3 tableData3={TableData3} handleChange={handleChange} name={name} />
            </Box>
          </>
</div>
</div>
</>
  );
};

export default Disease;



export const Table3 = ({ tableData3, handleChange,name }) => {
  function Delete(uuid) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
   
      if (result.isConfirmed) {
        var tokens =  localStorage.getItem("accessToken");
 
        api
        .delete(
          `/patient/disease?uuid=`+uuid,
        
     
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        )
        .catch(function (error) {
            
          if (error) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error + "!",
            });
            return 0;
          }
          if (!error.status) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "NetWork...",
              text: "!مشكلة في شبكة ",
            });
            return 0;
          }
        });
      Swal.fire({
        icon: "success",
        title: "success...",
        text: "تم حذف المرض",
      }).then(()=>window.location.reload());
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم المرض
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                تعديل
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                حذف
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData3.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>

                <td>
              {name=='أضافه'?  <button
                    style={{
                      background: "#4caf50ß",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleChange(data)}
                    className=""
                  >
                  تعديل
                  </button>:null}
                
                </td>
                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                   onClick={() => Delete(data.uuid)}
                    className=""
                  >
                  حذف
                  </button>
                </td>
              </tr>
            );
          })}
          {/* {tableData3.length == 0 ? <td>1</td> : null}
          {tableData3.length == 0 ? <td>لايوجد امراض</td> : null}
          {tableData3.length == 0 ? (
            <td>
              <button
                style={{
                  background: "#1976D2",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                // onClick={() => handleDelete(data.uuid)}
                className=""
              >
              تعديل
              </button>
            </td>
          ) : null} */}
        </tbody>
      </table>
    </div>
  );
};


function FormInput3({
  handleChangeTable3,
  disease,
  Check,
  handleSubmit3,
  isloading3,
  name
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> اسم المرض </label>
            <br></br>
            <input
              list="diseaselist"
              id="name"
              name="name"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable3}
            />
            <h6 className="R">
              {Check?.name === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
            <datalist id="diseaselist">
              {disease.map((disease) => (
                <option
                  key={disease.uuid}
                  value={disease.name}
                  data-value={disease.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
        </div>
        <br></br>
        <Button
          variant="contained"
          className="Save-Button"
          disabled={isloading3}
          onClick={handleSubmit3}
          style={{
            fontFamily: "Tajawal",
            textAlign: "center",
            minwidth: "100%",
          }}
        >
         {name}
        </Button>
      </div>
    </Box>
  );
}
