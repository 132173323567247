import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const GetMedicines = async () => {
  const response=  await axios
  .get("/medicines", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetPMedicines = async (id) => {
  const response=  await axios
  .get("/patient/medicine?uuid="+id, {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetTPMedicines = async (id,tp) => {
  console.log(tp +'tp');
    console.log(id +'id');
  const response=  await axios
  .get("/patient/medicine/tp?uuid="+id+"&tp="+tp, {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  console.log(response.data);
  return response.data;
};
const PostMedicines = async (data) => {
  
  const response=  await axios
  .post("/medicine",
  {
    name:data.name,
    code:data.code,
    measruingunit:data.measruingunit
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const PutMedicines = async (data) => {
  
  const response=  await axios
  .put("/medicine?uuid="+data.uuid,
  {
    name:data.name,
    code:data.code,
    measruingunit:data.measruingunit
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const MedicinesService = {
  GetMedicines,
  PostMedicines,
  PutMedicines,
  GetPMedicines,
  GetTPMedicines
}

export default MedicinesService;
