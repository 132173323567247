import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const GetDiseases = async () => {
  const response=  await axios
  .get("/diseases", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Postdiseases = async (data) => {
  
  const response=  await axios
  .post("/disease",
  {
    name:data.name,
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Putdiseases = async (data) => {
  
  const response=  await axios
  .put("/disease?uuid="+ data.uuid,
  {
    name:data.name,
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const DiseasesService = {
  GetDiseases,
  Postdiseases,
  Putdiseases
}

export default DiseasesService;
