  
import React, { PureComponent, useEffect, useState } from "react";

import ReportService from "../../services/report.service";
import { useLocation, useNavigate } from "react-router-dom";
import { InputLabel, NativeSelect } from "@mui/material";
import FacilitysService from "../../services/facility.service";
  
const Facilitypatientmedicine = () => {
  const [rows, setrows] = useState({});
  const location = useLocation()  
  const [uuid, setuuid] = useState(location.pathname.substring(location.pathname.indexOf(':') + 1  ) != null? location.pathname.substring(location.pathname.indexOf(':') + 1  ) : 0)
  if(uuid=='/Report/one/Facilitypatient'){
    setuuid(
      ''
    )
    }
    console.log(uuid)
console.log(location.pathname.substring(location.pathname.indexOf(':') + 1  ))

  const [Facilitys, setFacilitys] = React.useState(null);
  useEffect(() => {
    FacilitysService.GetFacility()
    .then((data) => data)
    .then((data) => setFacilitys(data))
    ReportService.Facilitypatientmedicine(uuid)
      .then((data) => data)
      .then((data) => setrows(data))

      .catch(function (err) {
        if (!err?.response) {
        } else if (err.response?.status === 400) {
        } else if (
          err.response?.status === 401 ||
          err.response?.status === 422
        ) {
               localStorage.clear();
          navigate("/");
          window.location.reload();
        }
      });
    // setloading(true)
  }, [uuid]);

  const navigate = useNavigate();

  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <br></br>

      <div className="border rounded p-2">
        <div ref={container} className="W100">
        <div className={localStorage.getItem('facility') ==''?'center':null}>
<>

{
  localStorage.getItem('facility') ==''?
  <NativeSelect
 defaultValue={location.pathname.substring(location.pathname.indexOf(':') + 1 )}
onChange={(e)=>setuuid(e.target.value)}
  inputProps={{
    name: 'fhcility',
  }}
  sx={{
    width: '70vw',
  }}
>
  <option  value={0}>جميع المراكز</option>

  {Facilitys != null && Facilitys.map((Facilitys) => (
    uuid==Facilitys.uuid ? 
    
    <option
    value={Facilitys.uuid}
selected
  >
    {Facilitys.name}
  </option>
    :
    <option
      value={Facilitys.uuid}

    >
      {Facilitys.name}
    </option>
  ))}
</NativeSelect>:null
}
</>

          <h1 className="text-center"> أسماء المرضي</h1>
          </div>
      
          <span className='rg1'>
              <h6 id="name">
{localStorage.getItem("user")+'-:اسم المستخدم '}
</h6>


            </span>
          <table id="customers">
         
  

            {rows[0] != undefined &&
              Object.values(rows).map((result, index) => (
                <>
                         
              <tr>
                 
                    <th
                      style={{ color: "#ffff", flex: 1 }}
                      className="text-center"
                 
                    >
                  { result.nid }  -:  الرقم الوطني
                    </th>
                    <th
                      style={{ color: "#ffff", flex: 1 }}
                      className="text-center"
                 
                    >
                 { result.code }     - :الكود
                    </th>
                    <th
                      style={{ color: "#ffff", flex: 1 }}
                      className="text-center"
                 
                    >
            الاسم    :- 
             { result.name } 
                    </th>
                  </tr>
             
                <tr>
     
         
                    
    
               </tr>
               {rows[0] != undefined &&
              Object.values(result.medicine).map((result, index) => (
                <>
                         
              <tr>
              <td
                      style={{ flex: 1 }}
                      className="text-center"
                 
                    >
                 { result.quantity }   
                    </td>
                    <td
                      style={{  flex: 1 }}
                      className="text-center"
                 
                    >
                  { result.name } 
                    </td>
                  
                    <td
                      style={{ flex: 1 }}
                      className="text-center"
                 
                    >
           اسم العلاج و الكمية
                    </td>
                  </tr>
            
               </>
              ))}
               </>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Facilitypatientmedicine;
