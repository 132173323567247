import { Button } from "@mui/material";
  
import React, { PureComponent } from 'react';
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Birthdate from "./Birthdate";
import BloodType from "./BloodType";
import { BloodTypeChart } from "./BloodTypeChart";
import Facilitymidecine from "./facilitymidecine";
import Facilitypatient from "./facilitypatient";
import Facilitypatientmedicine from "./facilitypatientmedicine";
import Facilitys from "./Facilitys";
import Group from "./Group";
import Indate from "./Indate";
import Medicinepatient from "./medicinepatient";
import Outedate from "./outdate";
import { Report1 } from "./Report1";
import { Report2 } from "./Report2";
import Sex from "./Sex";
import { Sex1 } from "./sex1";


export const One = () => {

  function print_specific_div_content(){

    // // document.title = localStorage.getItem("user")+' -:   اسم المستخدم ' ;
    // document.getElementById("flex01").style.visibility = "hidden";
    document.getElementById("hide").style.visibility = "hidden";
    // document.getElementById('name').innerHTML=localStorage.getItem("user")+'-:اسم المستخدم ';
    window.print();
    // document.getElementById('name').innerHTML="";
    document.getElementById("hide").style.visibility = "visible";
    // document.getElementById("flex01").style.visibility = "visible";
}

    let { type } = useParams();
  
 
  return (
    <div >
 <div className="flex" id="flex01">


 <button className="btn btn-primary " id="hide" onClick={()=>print_specific_div_content()}>طباعة</button>
 </div>
<div id="print">
      {type === 'BloodType' ?  <BloodType /> :null }
      {type === 'Birthdate' ?  <Birthdate /> :null }
      {type === 'Indate' ?  <Indate /> :null }
      {type === 'Sex' ?  <Sex /> :null }
      {type === 'Group' ?  <Group /> :null }
      {type === 'Facilitys' ?  <Facilitys /> :null } 
      {type === 'Outedate' ?  <Outedate /> :null } 
      {type === 'medicinepatient' ?  <Medicinepatient /> :null } 
      {type === 'Facilitypatient' ?  <Facilitypatient /> :null } 
      {type === 'r' ?  <Report1 /> :null } 
      {type === 'sexChart' ?  <Sex1 /> :null } 
      {type === 'BloodTypeChart' ?  <BloodTypeChart /> :null } 
      {type === 'Facilitymidecine' ?  <Facilitymidecine /> :null } 
      {type === 'Facilitypatientmedicine' ?  <Facilitypatientmedicine /> :null } 
      {type === 'c' ?  <Report2 /> :null } 
</div>

        </div>
        

  )
}
