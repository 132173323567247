import { Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Box, Button, Radio } from "@mui/material";

import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from "../../AppBar";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Label } from "recharts";

const Analysis = () => {
  let { id } = useParams();

  const [isloading2, setisloading2] = useState(true);
  var formdata = new FormData();
  const [type, settype] = useState("text");
  const [name, setname] = useState("أضافه");
  const [Analysis, setAnalysis] = useState([]);
  const [Check, setCheck] = useState([]);
  const [Check1, setCheck1] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [formInputData2, setformInputData2] = useState({
    analysis: null,
    sampledate: null,
    resultdate: null,
    result: null,
  });
  const handleChangeTable2 = (evnt) => {
    setisloading2(true);
    const value = evnt.target.value;
    const name = evnt.target.name;

    if (name != "result") {
      setformInputData2({ ...formInputData2, [name]: value });
    }
    if (name == "result" && type == "text") {
      setformInputData2({ ...formInputData2, [name]: value });
      setisloading2(false);
    } else if (name == "result" && type == "file") {
      if (evnt.target.files[0].size / 1024 / 1024 > 1) {
        Msg("File size too large.");
        return;
      }
      setCheck1(evnt.target.files[0]);
      setisloading2(false);
    }

    console.log(formInputData2);
  };

  try {
    useEffect(() => {
      DataService.GetAnalysis()
        .then((data) => data)
        .then((data) => setAnalysis(data))
        .catch(function (err) {
          if (!err?.response) {
            Msg("No Server Response");
          } else if (err.response?.status === 400) {
            Msg("error");
          } else if (
            err.response?.status === 401 ||
            err.response?.status === 422
          ) {
            DataService.GetRefresh().catch(function (err) {
              if (!err?.response) {
                Msg("No Server Response");
              } else if (err.response?.status === 400) {
                Msg("error");
              } else if (
                err.response?.status === 401 ||
                err.response?.status === 422
              ) {
                Msg("error");
              }
            });
          }
        });
      DataService.GetonePation(id)
        .then((data) => data)
        .then((data) => {
          setTableData2(data.analysis);
        });
    }, []);
  } catch (err) {}
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
  const handleSubmit = async (evnt) => {
    formdata.append("patient", id);
    formdata.append("analysis", formInputData2.analysis);
    formdata.append("sampledate", formInputData2.sampledate);
    formdata.append("resultdate", formInputData2.resultdate);

    if (type == "file") {
      formdata.append("result", "file");
      formdata.append("resultfile", Check1);
      console.log(Check1)
    } else {
      formdata.append("result", formInputData2.result);
    }
    var requestOptions = {
      body: formdata,
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
    
      },
    };

    await fetch(
      "http://oncologytest.teccube.ly/api/patient/analysis/add",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => 
      {
      Swal.fire({
        icon: "success",
        title: "تمت الاضافه بنجاح",
        showConfirmButton: false,
        timer: 1500,
      }
      )
      })
      .catch((error) =>{
        Msg(error
        )
      });
  };


  
  const navItems = [];

  return (
    <>
      <DrawerAppBar navItems={navItems} />
      {!1 ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="App">
          <div className="Home">
            <br></br>
            <br></br>

            <br />

            <Typography className="R">البيانات التحليل</Typography>

            <FormInput2
              handleChangeTable2={handleChangeTable2}
              formInputData2={formInputData2}
              handleSubmit={handleSubmit}
              Analysis={Analysis}
              Check={Check}
              isloading2={isloading2}
              name={name}
              type={type}
              settype={settype}
            />
<br></br>
            <Box
    sx={{
      height: tableData2.length <= 5 ? 500 : 1000,
      backgroundColor: "primary",
    }}
  >
 <Table2 tableData2={tableData2}   /> 

    <br>
    </br>
  
  </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default Analysis;
function FormInput2({
  handleChangeTable2,
  Analysis,
  Check,
  handleSubmit,
  isloading2,
  name,
  type,
  settype,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> نوع التحليل </label>
            <br></br>
            <select
              id="analysis"
              name="analysis"
              className="input"
              onChange={handleChangeTable2}
            >
              <option>{""}</option>

              {Analysis.map((Analysis) => (
                <option
                  key={Analysis.uuid}
                  value={Analysis.uuid}
                  id={Analysis.uuid}
                >
                  {Analysis.name}
                </option>
              ))}
            </select>
            <h6 className="R">
              {Check?.name === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
          </div>
          <div className="0">
            <label className="label"> تاريخ اخذ العينة</label>

            <br></br>
            <input
              className="input"
              type="date"
              required
              onChange={handleChangeTable2}
              name="sampledate"
              id="sampledate"
            ></input>
          </div>
        </div>
        <br></br>
        <div className="flex">
          <div className="0">
            <label className="label"> تاريخ نتجية العينة</label>

            <br></br>
            <input
              className="input"
              type="date"
              required
              onChange={handleChangeTable2}
              name="resultdate"
              id="resultdate"
            ></input>
          </div>
          <div
            className="0"
            style={{
              marginLeft: "14%",

              textAlign: "start",
              paddingRight: "20%",
              height: "35px",
            }}
          >
            <label className="label"> نوع نتجية العينة</label>

            <br></br>
            <div className="flexadmin">
              <label className="label">ملف</label>
              <div>.....</div>
              <input
                type="radio"
                onClick={() => settype("file")}
                name="result"
              ></input>
              <div>.....</div>
              <label className="label"> نص </label>
              <div>.....</div>
              <input
                onClick={() => settype("text")}
                type="radio"
                required
                name="result"
                id="result"
              ></input>
            </div>
          </div>
        </div>
        <br></br>
        {type == "file" ? (
          <div className="0">
            <label  className="label"
            >
              نتجية العينة
            </label>

            <br></br>
            <input
              type="file"
              name="result"
              id="result"
              className="input"
              style={{
                margin: "1%",
                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable2}
            />
          </div>
        ) : (
          <div className="0">
               <label  className="label"
            >
              نتجية العينة
            </label>

            <br></br>
            <input
              className="input"
              type="text"
              required
              onChange={handleChangeTable2}
              style={{
                marginLeft: "14%",
  
                textAlign: "start",
                paddingRight: "20%",
                height: "35px",
              }}
              name="result"
              id="result"
            ></input>
          </div>
        )}
        <br></br>

        <Button
          variant="contained"
          className="Save-Button"
          disabled={isloading2}
          onClick={handleSubmit}
          style={{
            fontFamily: "Tajawal",
            textAlign: "center",
            minwidth: "100%",
          }}
        >
          {name}
        </Button>
      </div>
    </Box>
  );
}

export const Table2 = ({ tableData2}) => {
  function time(time) {
    var b = time.replace('00:00:00 GMT','');
 

    return b;
     
  }
  function download(id) {
    DataService.download(id).then(
      (response) => {
        console.log(response.data);
      }
    )
    
    
    
  }
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                الرقم
              </h3>
            </th>
                
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                نوع التحليل
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                تاريخ اخذ العينة
              </h3>
            </th>
            <th>
              <h3 

                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                تاريخ نتجية العينة
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                نتجية العينة
              </h3>
            </th>

                
          </tr>
        </thead>
        <tbody>
          {tableData2.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.analysis}</td>
                <td>{time(data?.sampledate)}</td>
                <td>{time(data?.resultdate)}</td>
                <td>{data.result=='file'?
             <button onClick={()=>download(
              data.uuid,
            )}>
              تحميل
            </button>
                  : data.result }
               </td>

            
              
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};