
import React, {  useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Swal from "sweetalert2";
import DrawerAppBar from "../AppBar";
import ReportService from "../../services/report.service";

import DataService from "../../services/data.service";
import randomColor from "randomcolor";

import Card from "../Card/Card";
import { VictoryPie } from "victory-pie";



ChartJS.register(ArcElement, Tooltip, Legend);



export const Report = () => {

const navItems = []

  return (
    <div className=''>
      <DrawerAppBar  />
      <br /> <br />      <br /> <br />
      <Card/>
      <br /> <br />  
   
   
    
  
    <br>
    </br>
    </div>
  );
};
