import React, { useEffect, useState } from 'react'

import DrawerAppBar from '../AppBar'

import Box from '@mui/material/Box';
import { DataGrid ,GridToolbar,GridToolbarExport,GridToolbarContainer} from '@mui/x-data-grid';
import DataService from '../../services/data.service';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const navItems = [];


export const View = () => {
  const [rows1, setrows] = useState({});
  const [pageSize, setPageSize] = React.useState(10);

  // const navigate = useNavigate();
  // // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // // const fileExtension = '.xlsx';
  // // let a =[]
  
  const columns = [
    {
      field: 'code',
      headerName: 'رقم الملف',
      flex: 1,
      renderCell: (params) => {

        // nsole.log(params.row.genres[0].name).arco

        return params.row.code;
      },
    },
    {
      field: 'name',
      headerName: 'الاسم',
      flex: 1
    },
    {
      field: 'nationality',
      headerName: 'الجنسية',
      flex: 1

    },
  {
      field: 'facility',
      headerName: 'مراكز العلاج',
      flex: 1,
    hide: localStorage.getItem('facility') == ''?false:true,
    },
   
    {
      field: 'address',
      headerName: 'العنوان',
      flex: 1

    },
    {
      field: 'ismale',   
      headerName: 'الجنس',
      flex: 1,
      renderCell: (params) => {

        // nsole.log(params.row.genres[0].name).arco

        return params.row.ismale ? 'ذكر' : 'أنثى';
      },


    },
  
    {
      field: "View",
      headerName: "عرض",

      flex: 1,
      renderCell: (params) => {
    


        // nsole.log(params.row.genres[0].name).arco
//<Button onClick={onClick}>عرض</Button>
        return <Link style={{color:'#1876D1'}} to={params.row.uuid} >عرض</Link>;
      },
    },

 localStorage.getItem('role') != 'reception' &&   {
      field: "analysis",
      headerName: "تحليل",

      flex: 1,
      renderCell: (params) => {
    


        // nsole.log(params.row.genres[0].name).arco
//<Button onClick={onClick}>عرض</Button>
        return <Link style={{color:'#1876D1'}} to={'analysis/'+params.row.uuid} >عرض</Link>;
      },
    },
  ];

function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }

  try {
    useEffect(() => { 

      DataService.GetPatients()
        .then((data) => data)
        .then((data) => { 
         
          setrows(data)
        
       
        }).catch(function (err) {
          if (!err?.response) {
            localStorage.clear()
            Msg('No Server Response');
          } else if (err.response?.status === 400) {
      
            Msg('');
          } else if (err.response?.status === 401 ||err.response?.status === 422) {
   
              DataService.GetRefresh().catch(function (err) {
           
            if (!err?.response) {
              Msg('No Server Response');
          } else if (err.response?.status === 400) {
            localStorage.clear()
             Msg('error');
          } else if (err.response?.status === 401 ||err.response?.status === 422) {
            localStorage.clear()
          }})
       
       } });

    }, []);

  } catch (err) {

  }
  console.log(rows1)
  return (
    <div>
      <DrawerAppBar  />
   
      <Box sx={{ height: '85vh', width: '100%', marginTop: '100px' }}>
        
    
        <DataGrid
      // components={{ Toolbar: CustomToolbar }}
          rows={rows1 }
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10,25,50,100]}
          getRowId={(rowsex) => rowsex?.uuid}
          disableSelectionOnClick

        />
     

      </Box>

    </div>
  )
}
