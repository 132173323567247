import { useRef, useState, useEffect } from 'react';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';

import instance from '../../api/axios';
import { useAuth } from '../../hooks/useAuth';

import '../../index.css'
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();
const LOGIN_URL = '/login';
const Login = () => {
   var auth = useAuth();

   var navigate = useNavigate();
   var location = useLocation();
   var from =  "/";
   var ua = navigator.userAgent.toLowerCase(); 
  
   var userRef = useRef();
   var errRef = useRef();

   var [username, resetUser, userAttribs] = useInput('username', '')
   var [password, setpassword] = useState('');
   var [errMsg, setErrMsg] = useState('');

   var [Isload, setIsload] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

   var handleSubmit = async (e) => {
        e.preventDefault();
        setIsload(true);

        try {
           var response = await instance.post(LOGIN_URL,
                JSON.stringify({ username, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                  
                }
            );
           var accessToken = response?.data?.tokens?.access_token;
           var user =response?.data?.tokens?.username;
           var refresh_token = response?.data?.tokens?.refresh_token;
           var role = response?.data?.role;
         auth.login(user,accessToken,role,refresh_token);
        localStorage.setItem('refresh_token',refresh_token  );
      localStorage.setItem('user',user);
        localStorage.setItem('role',role  );
        localStorage.setItem('accessToken',accessToken  );
        localStorage.setItem('facility',response?.data.facility);
        //add time to await before closing the tab?
        if(role=='pharmacist'){
            navigate('/Search', { replace: true }).then(() => window.location.reload());
           }
               //if browser is  safari
               if (ua.indexOf('safari') != -1) { 
                if (ua.indexOf('chrome') > -1) {
                    window.opener = null;
                    window.open('', '_self');
                    window.close();
                    window.open('', '_self').close();
                    window.open('/','_blank', 'rel=noopener noreferrer')
                } else {
                    navigate(from, { replace: true }).then(() => window.location.reload());
                }
              }
          
     
          
          
  
setIsload(false);
        } catch (err) {
            if (!err?.response) {
                // setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
           setIsload(false);
        }
    }

    return (
        <>
        {
            
            Isload == true?  <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div> :
    
  
<div className='App'>



<br></br>
<div className='C1111'>
    <div className='center'>
   
    </div>

<section>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <h1 className='center'>
                
                تسجيل الدخول </h1>
            {/* <form onSubmit={handleSubmit}> */}
                <label htmlFor="username"> البريد الإلكترونى</label>
                <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    {...userAttribs}
                    required
                    className='input0'
                />

                <label htmlFor="password">كلمة السر</label>
                <input
                className='input0'
                    type="password"
                    id="password"
                    onChange={(e) => setpassword(e.target.value)}
                    value={password}
                    required
                />
                <br/>
                <button
                onClick={(e)=>{
                    handleSubmit(e)
                }}
                
                >
                    
                تسجيل الدخول </button>
               
            {/* </form> */}
          
             
   
  
        </section>
        <a href='https://www.teccube.ly/'>
                <h5> made by teccube  </h5> 
                </a>
</div>


        </div>
        }
        </>
    )
}

export default Login
