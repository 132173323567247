import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Box, Button, Radio, TextField } from "@mui/material";
import $ from "jquery";
import Swal from "sweetalert2";
import DataService from "../../services/data.service";
import DrawerAppBar from "../AppBar";
import api from "../../api/axios";



const Search = () => {

 
  const [code, setcode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const [Check, setCheck] = useState([]);

  const [isMale, setisMale] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
   const [tableData2, setTableData2] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData6, setTableData6] = useState([]);
  const [tableData7, setTableData7] = useState([]);

  //valadion
 
 
 console.log(state)
  
 
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
  //Service Get Data

  const navItems = [];
function name(params) {
 
  DataService.GetonePationbycode(code)
  .then((data) => data)
  .then((data) => {
    // DataService.GetPmedicines('7a4d2e7688264eb999a60eb24619cb6d')
    // .then((data) => data)
    // .then((data) => setTableData5(data));
    data.indate = new Date(data.indate);
    var a = Math.floor(data.indate.getTime());
    a = new Date(a);
    data.indate = a.toISOString().split("T")[0];
    data.birthday = new Date(data.birthday);
    var a = Math.floor(data.birthday.getTime());
    a = new Date(a);
    data.birthday = a.toISOString().split("T")[0];
    setTableData3(data.diseases);
    setTableData(data.oncologys);
    setTableData2(data.medicines);
    setTableData4(data.radiations);
    setTableData5(data.invoices);
    setTableData7(data.analysis);

    setisMale(data.ismale);
    console.log(data)
    setState({
      ...state,
      facility: data.facility,
      bloadtype: data.bloadtype,
      status:data.status,
      NID: data.NID,
      address: data.address,
      uuid:data.uuid,
   
      birthday: data.birthday,
      outdate: data.exitdate,
      circles: data.circles,
      indate: data.indate,
      mothername: data.mothername,
      name: data.name,
      nationality: data.nationality,
      passport: data.passport,
      phone_number: data.phone_number,

    });
   
    setIsLoading(true)
  }).catch(function (err) {
    if (!err?.response) {
      Msg('No Server Response');
    } else if (err.response?.status === 400) {
      Msg('');
    }
    else if (err.response?.status === 404) {
      setState({})
      setIsLoading(false)
      Msg('رقم الملف غير صحيح');
    } else if (err.response?.status === 401 || err.response?.status === 422) {

      DataService.GetRefresh().catch(function (err) {
        if (!err?.response) {
          Msg('No Server Response');
        } else if (err.response?.status === 400) {
          Msg('error');
        } else if (err.response?.status === 401 || err.response?.status === 422) {
          window.location.reload();
        }
      })
      window.location.reload();
    }
  });
}
 
  return (
    <>
      <DrawerAppBar  />
      <div className='center m6'>
        <TextField label="Code" onfocus="this.removeAttribute('readonly');" id="fullWidth" onChange={(e) => setcode(e.target.value)} autoComplete="off" onKeyPress={event => {
                if (event.key === 'Enter') {
                  name()
                }
              }}/>

       

      </div>
      {!isLoading  ? <div className="spinner-container">
          <div className="loading-spinner">
          
          </div> </div> : <div className="App">
        <div className="Home">
          <br></br>
          <br></br>
          <>
            <h1 className="C">{1}</h1>
            <br />
            <Typography className="R">بيانات الشخصيه</Typography>
            <div className="flex">
              <div className="0">
                <label className="label">الاسم الرباعى</label>
                <br></br>
                <input
                  className="input"
                  value={state.name ? state.name : ""}
                  required
               
                  name="name"
                  disabled
                ></input>
                <h6 className="R">
                  {Check?.name === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
              <div className="0">
                <label className="label">اسم الأم</label>
                <br></br>
                <input
                  className="input"
                  value={state.mothername ? state.mothername : ""}
                  required
               
                  name="mothername"
                  disabled
                ></input>
                <h6 className="R">
                  {Check?.mothername === false
                    ? "يرجي التأكد من عدد حروف "
                    : ""}
                </h6>
              </div>
            </div>
            <div className="flex">
              <div className="0">
                <label className="label">الجنسية</label>
                <br></br>
                <div className="Box">
                  <input
                    className="input"
                    disabled
                    list="nationalitylist"
                    id="nationality"
                    value={state.nationality ? state.nationality : ""}
                    name="nationality"
                    style={{
                      margin: "1%",

                      textAlign: "start",
                      paddingcenter: "2%",
                      height: "35px",
                    }}
                   
                  />
                  <h6 className="R">
                    {Check?.nationality === false
                      ? "يرجي التأكد من الجنسية  "
                      : ""}
                  </h6>
                 
                </div>
              </div>
              <div className="0">
                <label className="label">رقم الجواز</label>
                <br></br>
                <input
                 disabled
                  className="input"
                  required
                  value={state.passport ? state.passport : ""}
                  
                  name="passport"
                ></input>
                <h6 className="R">
                  {Check?.passport === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
            </div>
            <div className="flex">
              <div className="0">
                <label className="label">الرقم الوطني </label>
                <br></br>
                <input
                  className="input"
                  disabled
                  value={state.NID ? state.NID : ""}
                  required
                
                  name="NID"
                ></input>
                <h6 className="R">
                  {Check?.NID === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>

              <div className="0">
                <label className="label">تاريخ الميلاد </label>
                <br></br>
                <input
                 disabled
                  className="input1"
                  type="date"
                  required
                  value={state.birthday ? state.birthday : ""}
                  name="birthday"
              
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingcenter: "2%",
                    height: "35px",
                  }}
                ></input>
              </div>
            </div>

            <div className="flex">
              <div className="b1">
                <label className="label">فصيلة الدم</label>
                <br></br>
                <div className="Box">
                  <input
                   disabled
                    className="input"
                    required
                    list="bloadtypelist"
                    id="bloadtype"
                    value={state.bloadtype ? state.bloadtype : ""}
                    name="bloadtype"
                    style={{
                      margin: "1%",

                      textAlign: "start",
                      paddingcenter: "2%",
                      height: "35px",
                    }}
                   
                  />
                  <h6 className="R">
                    {Check?.bloadtype === false
                      ? "يرجي التأكد من فصيلة الدم "
                      : ""}
                  </h6>
                
               
                </div>
              </div>
              <div className="0">
                <label className="label"> مكان العلاج</label>
                <br></br>
                <input
                 disabled
                  list="facilitylist"
                  id="facility"
                  name="facility"
                  className="input"
                  value={state.facility ? state.facility : ""}
                  required
                  style={{
                    margin: "1%",

                    textAlign: "Start",
                    paddingcenter: "2%",
                    height: "35px",
                  }}
               
                />
                <h6 className="R">
                  {Check?.facility === false
                    ? "  يرجي  التأكد من  حروف المدينة"
                    : ""}
                </h6>
               
              </div>
            </div>
            <div className="flex">
              <div className="">
                <label className="label"> تاريخ الدخول </label>
                <br></br>
                <input
                 disabled
                  className="input1"
                  type="date"
                  required
                  name="indate"
                  value={state.indate ? state.indate : ""}
             
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingcenter: "2%",
                    height: "35px",
                  }}
                ></input>
              </div>
              <div className="">
                <label className="label"> تاريخ الخروج </label>
                <br></br>
                <input
                 disabled
                  className="input1"
                  type="date"
                  required
                  name="outdate"
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingcenter: "2%",
                    height: "35px",
                  }}
                  value={state.outdate ? state.outdate : ""}
                
                ></input>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <label className="label"> نوع الخروج </label>

                <br></br>
                <select
                 disabled
                  className="input"
                  value={state.status ? state.status : ""}
                  required
                
                  name="status"
                >
                  <option value=""></option>

                  <option value="خروج طبيعي">خروج طبيعي</option>
                  <option value="خروج علي المسؤلية">خروج علي المسؤلية</option>
                  <option value="هروب">هروب</option>
                  <option value="وفاة">وفاة</option>
                  <option value="رافض الدخول ">رافض الدخول</option>
                </select>
              </div>
              <div className="c1">
                <label className="label">الجنس</label>
                <br />

                <div className="flex">
                  <input
                   disabled
                    type="radio"
                    id="html"
                    className="kk"
                    name="fav_language"
                    value="ذكر"
                    required
                    checked={isMale}
                   
                  />

                  <label>ذكر</label>
                  <input
                   disabled
                    type="radio"
                    id="css"
                    className="kk"
                    name="fav_language"
                    value="انثي"
                    required
                    checked={!isMale}
                   
                  />
                  <label>انثي</label>
                </div>
              </div>
            </div>
          </>
          <br></br>
          <div className="flex">
            <div className="0">
              <div className="Flex1">
                <label className="label"> محل الأقامة</label>
        
              </div>

              <input
               disabled
                list="addresslist"
                id="address"
                name="address"
                value={state.address ? state.address : ""}
                className="input"
                style={{
                  margin: "1%",

                  textAlign: "start",
                  paddingcenter: "2%",
                  height: "35px",
                }}
               
              />
              <h6 className="R">
                {Check?.address === false ? "  يرجي  من  حروف المدينة" : ""}
              </h6>
              
            </div>
            <div className="0">
              <div className="Flex1">
                <label className="label"> رقم الهاتف </label>
              
              </div>

              <input
               disabled
                list="phone_numberlist"
                id="phone_number"
                type="number"
                value={state.phone_number ? state.phone_number : ""}
                name="phone_number"
                className="input"
                style={{
                  margin: "1%",

                  textAlign: "start",
                  paddingcenter: "2%",
                  height: "35px",
                }}
               
              />
              <h6 className="R">
                {Check?.phone_number === false ? "  يرجي  من  حروف رقم" : ""}
              </h6>
            </div>
          </div>
         
          <br />
          <br /> 
          {/* <>
            <h1 className="C">{2}</h1>
            <br />

            <Typography className="R">بيانات التشخيص</Typography>

            <Box
              sx={{
                height: tableData.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table tableData={tableData}  />
            </Box>
          </> */}
          <>
            <h1 className="C">{3}</h1>
            <br />
            <Typography className="R">بيانات العلاج</Typography>

            <br />

            <Box
              sx={{
                height: tableData2.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table2 tableData2={tableData2}  />
            </Box>
          </>
        
          <> 
            <h1 className="C"> 3</h1>
            <br />
            <Typography className="R"> العلاج الصيدلي </Typography>

         

            <Box
              sx={{
                height: tableData4.length <= 4? 400 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table5 tableData5={tableData5} id={state.uuid} code={code}  />
            </Box>
          </>
          <>
            <h1 className="C">{4}</h1>
            <br />
            <Typography className="R"
            >
              التحليل
            </Typography>
            <Box
    sx={{
      height: tableData2.length <= 5 ? 500 : 1000,
      backgroundColor: "primary",
    }}
  >
 <Table7 tableData7={tableData7} id={state.uuid}  /> 

    <br>
    </br>
  
  </Box>
  <br>
  </br>
  </>
        </div>
      </div> }
    </>
  );
};

export default Search;

export const Table = ({ tableData  }) => {
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time.getTime());
    a = new Date(a);
    return a.toISOString().split("T")[0];
     
  }
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                رمز الورم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم الورم
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                المستوى {" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                التاريخ التشخيص
              </h3>
            </th>
        
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.code}</td>
                <td>{data.name}</td>
                <td>{data.level}</td>
                <td>{time(data.diagnosisdate)}</td>
               
                {tableData.length == 0 ? <td>1</td> : null}
                      {tableData.length == 0 ? <td >لايوجد  مرض</td> : null}
                  
                   
              </tr>
                
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export const Table2 = ({ tableData2  }) => {

  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم العلاج
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الكمية
              </h3>
            </th>
      
          </tr>
        </thead>
        <tbody>
          {tableData2.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>
                <td>{data.quantity}</td>

          
          
              </tr>
            );
          })}
                {tableData2.length == 0 ? <td>1</td> : null}
          {tableData2.length == 0 ? <td>لايوجد علاج</td> : null}
          {tableData2.length == 0 ? <td>0</td> : null}
         
        </tbody>
      </table>
    </div>
  );
};
export const Table3 = ({ tableData3  }) => {

  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم المرض
              </h3>{" "}
            </th>

           
          </tr>
        </thead>
        <tbody>
          {tableData3.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>

               
              </tr>
            );
          })}
          {tableData3.length == 0 ? <td>1</td> : null}
          {tableData3.length == 0 ? <td>لايوجد امراض</td> : null}
         
        </tbody>
      </table>
    </div>
  );
};
export const Table4 = ({ tableData4 }) => {
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time.getTime());
    a = new Date(a);
    return a.toISOString().split("T")[0];
     
  }
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
 هدف العلاج  {" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
 نوع  الإشعاع{" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >الكمية الاجماليه للاشعاع 
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
          نوع التقنية  
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                التاريخ البدا
              </h3>
            </th>
           
          </tr>
        </thead>
        <tbody>
       
          {tableData4.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.status}</td>
                <td>{data.radiationtype}</td>
                <td>{data.radiation}</td>
                <td>{data.tecnology}</td>
                <td>{time(data.startdate)}</td>
                
              </tr>
            );
          })}
               {tableData4.length == 0 ? <td>1</td> : null}
          {tableData4.length == 0 ? <td>لايوجد هدف العلاج</td> : null}
          {tableData4.length == 0 ? <td >  لايوجد </td> : null}
          {tableData4.length == 0 ? <td>0</td> : null}
          {tableData4.length == 0 ? <td>لايوجد نوع  التقنية</td> : null}
          {tableData4.length == 0 ? <td>-</td> : null}
        </tbody>
      </table>
    </div>
  );
};
export const Table5 = ({ tableData5,id,code }) => {
 const navigate=useNavigate()
 function time(time) {
  time = new Date(time);
  var a = Math.floor(time.getTime());
  a = new Date(a);
  return a.toISOString().split("T")[0];
   
}
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم الدواء
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >الكمية  
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                التاريخ البدا
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              > العرض
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
       
          {tableData5.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
             
                <td>{data.lines[0].medicine}</td>
                <td>{data.lines[0].quantity}</td>
                <td>{time(data.date)}</td>
              
                <td>
                <button
                  style={{
                    background: "#1976D2",
                    color: "white",
                    border: "none",
                    width: "100px",
                  }}
                  onClick={() => navigate("/Search/invoice/" + id+'-'+code)}
                  className=""
                >
                  تعديل
                </button>
              </td>
              </tr>
            );
          })}
               {tableData5.length == 0 ? <td>1</td> : null}
               {tableData5.length == 0 ? <td>لايوجد </td> : null}
          {tableData5.length == 0 ? <td>لايوجد  </td> : null}
          {tableData5.length == 0 ? <td >  لايوجد </td> : null}
       
             {tableData5.length == 0 ? (
              <td>
                <button
                  style={{
                    background: "#1976D2",
                    color: "white",
                    border: "none",
                    width: "100px",
                  }}
                  onClick={() => navigate("/Search/invoice/" + id+'-'+code)}
                  className=""
                >
                  تعديل
                </button>
              </td>
            ) : null}
        </tbody>
      </table>
    </div>
  );
};
export const Table7 = ({ tableData7,id}) => {
  const navigate=useNavigate()
  function time(time) {
    var b = time.replace('00:00:00 GMT','');
 

    return b;
     
  }
  function download(id) {
    DataService.download(id).then(
      (response) => {
        console.log(response.data);
      }
    )
    
    
    
  }
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                الرقم
              </h3>
            </th>
                
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                نوع التحليل
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                تاريخ اخذ العينة
              </h3>
            </th>
            <th>
              <h3 

                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                تاريخ نتجية العينة
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                نتجية العينة
              </h3>
            </th>

                
          </tr>
        </thead>
        <tbody>
          {tableData7.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.analysis}</td>
                <td>{time(data?.sampledate)}</td>
                <td>{time(data?.resultdate)}</td>
                <td>{data.result=='file'?
                <button onClick={()=>download(
                  data.uuid,
                )}>
                  تحميل
                </button>
                :
                data.result }</td>

            
              
              </tr>
            );
          })}
          {tableData7.length == 0 ? <td>1</td> : null}
          {tableData7.length == 0 ? <td>لايوجد </td> : null}
          {tableData7.length == 0 ? <td>لايوجد  </td> : null}
          {tableData7.length == 0 ? <td >  لايوجد </td> : null}
          {tableData7.length == 0 ? <td > 
            <button
                  style={{
                    background: "#1976D2",
                    color: "white",
                    border: "none",
                    width: "100px",
                  }}
                  onClick={() => navigate("../View/analysis/" + id)}
                  className=""
                >
                  تعديل
                </button>
            </td> : null}

        </tbody>
      </table>
    </div>
  );
};