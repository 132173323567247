import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const GetAddress = async () => {
  const response=  await axios
  .get("/addresss", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Postaddress = async (data) => {
  
  const response=  await axios
  .post("/address",
  {
    name:data.name,
    lang:data.lang, 
    loat:data.loat, 
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Putaddress = async (data) => {
  
  const response=  await axios
  .put("/address?uuid="+data.uuid,
  {
    name:data.name,
    lang:data.lang, 
    loat:data.loat, 
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const AddresssService = {
  GetAddress,
  Postaddress,
  Putaddress
}

export default AddresssService;
