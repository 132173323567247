import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";




  const RequireAuth = ({ children }) => {
    const  auth  = useAuth();
    const location = useLocation();
    if (auth.user)
       {
    
return <Outlet/>
} else {

return <Navigate to="/login"  state={{ from: location }}   />
}


   
}

export default RequireAuth;