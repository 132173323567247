
import React, {  useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Swal from "sweetalert2";
import DrawerAppBar from "../AppBar";
import ReportService from "../../services/report.service";

import DataService from "../../services/data.service";
import randomColor from "randomcolor";

import Card from "../Card/Card";
import { VictoryPie } from "victory-pie";



ChartJS.register(ArcElement, Tooltip, Legend);



export const Sex1 = () => {
   const options = {
   
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params+ "!",
    });
    return 0;
  }


  const [rows1, setrows1] = useState({});
  let labels3=[]
  let labels4=[]

if (rows1.length >1) {
  rows1?.map((rows, index) => labels3.push(rows.x));
  rows1?.map((rows, index) => labels4.push(rows.y));
}
  let data1 = {
    labels: labels3,
    datasets: [
      {
        label: '# of Votes',
        data: labels4,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 109, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  useEffect(  ()  => {

 
    ReportService.Getsex()
    .then((data) => data)
    .then((data) => setrows1(data))   .catch(function (err) {
      if (!err?.response) {
        Msg('No Server Response');
      } else if (err.response?.status === 400) {
        Msg('error');
      } else if (err.response?.status === 401 || err.response?.status === 422) {

        DataService.GetRefresh().catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
          } else if (err.response?.status === 400) {
            Msg('error');
          } else if (err.response?.status === 401 || err.response?.status === 422) {
            Msg('error');
             
          }
        })
      }
    });

  console.log(rows1)
    // setloading(true)

  },[])

const navItems = []

  return (
    <div className=''>


     
    
      <div className=" center">


<div className="" > 

<br></br>
<h5 className="center">

نسبة الجنس 

</h5>
<Pie data={data1} options={options} />

</div>
<br>
    </br>
      </div> 

      <div  className="W100" >
      <span className='rg1'>
              <h6 id="name">
{localStorage.getItem("user")+'-:اسم المستخدم '}
</h6>


            </span>
         
    <table id="customers">
    <tr>
  
    
    <th style={{color:'#ffff',flex:1}}  className="text-center">المجموع</th>


  <th style={{color:'#ffff',flex:1}}  className="text-center">الاسم</th>
  <th style={{color:'#ffff',flex:1}}  className="text-center">الرقم</th>
  </tr>
  { rows1[0] != undefined &&  (rows1).map((result,index)  =>   
  <tr className="text-center" >
     
     <td  className="text-center">{rows1[index]?.y}</td> 
      <td  className="text-center">{rows1[index]?.x}</td> 

        
     

      <td  className="text-center">{index+1}</td> 
        </tr> 

) }
</table>
  
      
          </div>
       <br>
    </br>
    </div>
  );
};
