
import React, {  useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Swal from "sweetalert2";
import DrawerAppBar from "../AppBar";
import ReportService from "../../services/report.service";

import DataService from "../../services/data.service";
import randomColor from "randomcolor";

import Card from "../Card/Card";
import { VictoryPie } from "victory-pie";



ChartJS.register(ArcElement, Tooltip, Legend);



export const BloodTypeChart = () => {
   const options = {
   
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params+ "!",
    });
    return 0;
  }

  const [rows, setrows] = useState({});
  const [rows1, setrows1] = useState({});
  let labels=[]
  let labels1=[]
if (rows.length >1) {
  rows?.map((rows, index) => labels.push(rows.x));
  rows?.map((rows, index) => labels1.push(rows.y));
}
console.log(rows1)
  let data = {
    labels: labels,
    datasets: [
      {
        label: '# of Votes',
        data: labels1,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  let labels3=[]
  let labels4=[]
if (rows1.length >1) {
  rows1?.map((rows, index) => labels3.push(rows.x));
  rows1?.map((rows, index) => labels4.push(rows.y));
}
  let data1 = {
    labels: labels3,
    datasets: [
      {
        label: '# of Votes',
        data: labels4,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 109, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  useEffect(  ()  => {

 
    ReportService.Getbloodtype()
    .then((data) => data)
    .then((data) => setrows(data))   .catch(function (err) {
      if (!err?.response) {
        Msg('No Server Response');
      } else if (err.response?.status === 400) {
        Msg('error');
      } else if (err.response?.status === 401 || err.response?.status === 422) {

        DataService.GetRefresh().catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
          } else if (err.response?.status === 400) {
            Msg('error');
          } else if (err.response?.status === 401 || err.response?.status === 422) {
            Msg('error');
             
          }
        })
      }
    });
    ReportService.Getsex()
    .then((data) => data)
    .then((data) => setrows1(data))
  
  
    // setloading(true)

  },[])

const navItems = []

  return (
    <div>
        <div className=''>
     
      <br />
     
    
      <div className="center">

      <div className=""   > 

<h5 className="center">

نسبة فصائل الدم

</h5>
<Pie data={data} options={options} />
   
</div>
 
</div>

  
</div>
<br></br><br></br>
<span className='rg1'>
              <h6 id="name">
{localStorage.getItem("user")+'-:اسم المستخدم '}
</h6>


            </span>
    <table id="customers">
    <tr>
  
    
    <th style={{color:'#ffff',flex:1}}  className="text-center">المجموع</th>


  <th style={{color:'#ffff',flex:1}}  className="text-center">الاسم</th>
  <th style={{color:'#ffff',flex:1}}  className="text-center">الرقم</th>
  </tr>
  { rows[0] != undefined &&  (rows).map((result,index)  =>   
  <tr className="text-center" >
     
     <td  className="text-center">{rows[index]?.y}</td> 
      <td  className="text-center">{rows[index]?.x}</td> 

        
     

      <td  className="text-center">{index+1}</td> 
        </tr> 

) }
</table>
    </div>

    
  );
};
