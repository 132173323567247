  
import React, { PureComponent, useEffect, useState } from 'react';



import ReportService from "../../services/report.service";
import { useNavigate } from "react-router-dom";
  


  
 
const Facilitys = () => {
  const [rows, setrows] = useState({});
  const [item, setitem] = useState('');
  const [loading, setloading] = useState(false);
  useEffect(  ()  => {

 
     
    ReportService.Getmedicinecity()
    .then((data) => data)
    .then((data) => setrows(data))
   
    .catch(function (err) {
      if (!err?.response) {
        
    } else if (err.response?.status === 400) {
    
    } else if (err.response?.status === 401 ||err.response?.status === 422) {
           localStorage.clear();
      navigate('/');
      window.location.reload();
    }
    });
    // setloading(true)

  },[])
 
  
  const navigate = useNavigate();
  
console.log(rows)

    const container = React.useRef(null);
  return (
    <div style={{backgroundColor:'#ffffff'}}>
 <br></br>
   
        <div className="border rounded p-2" >
       
          <div ref={container}  className="W100" >
            <h1 className="text-center" > عدد مرضي الأورام لكل مركز       </h1>
            <span className='rg1'>
              <h6 id="name">
{localStorage.getItem("user")+'-:اسم المستخدم '}
</h6>


            </span>
          
    <table id="customers">
    <tr>
  
    

    <th style={{color:'#ffff',flex:1}}  className="text-center">   
المجموع

   

 </th>
 {
  localStorage.getItem('facility') == '' ?
  <>
     <th style={{color:'#ffff',flex:1}}  className="text-center">   
    مركز بنغازي الطبي

   

 </th>
    <th style={{color:'#ffff',flex:1}}  className="text-center">   مركز سبها الطبي
 

</th>
<th style={{color:'#ffff',flex:1}}  className="text-center">     مركز الأورام سرت

 

 </th>
<th style={{color:'#ffff',flex:1}}  className="text-center">    مركز البيضاء الطبي
 

 </th>

    <th style={{color:'#ffff',flex:1}}  className="text-center">مستشفي الأطفال بنغازي
</th>
<th style={{color:'#ffff',flex:1}}  className="text-center">مركز الأورام بنغازي  
</th>
  <th style={{color:'#ffff',flex:1}}  className="text-center">مركز طبرق الطبي
</th>
  </>
  :
  <>
    <th style={{color:'#ffff',flex:1}}  className="text-center">
      {
        localStorage.getItem('facility') 
      }
</th>
  </>
 }
 
  
  <th style={{color:'#ffff',flex:1}}  className="text-center" >  المرض </th>

  <th style={{color:'#ffff',flex:1}}  className="text-center" >  
    
   </th>
  </tr>
  

  

        
        { localStorage.getItem('facility') == ''&& rows[0] != undefined && (rows).map((result,index)  =>     
    <tr className="text-center" >
      <td  className="text-center">{rows[index]?.total}</td> 
        <td  className="text-center" style={{flex:1}}>{ result.facility['مركز بنغازي الطبي'] }</td>
          <td  className="text-center" style={{flex:1}}>{ result.facility['مركز سبها الطبي'] }</td>
          <td  className="text-center" style={{flex:1}}>{ result.facility['مركز الأورام سرت'] }</td>
         <td  className="text-center" style={{flex:1}}>{ result.facility['مركز البيضاء الطبي'] }</td> 
       <td  className="text-center" style={{flex:1}}>{ result.facility['مستشفي الأطفال بنغازي'] }</td> 
       <td  className="text-center" style={{flex:1}}>{ result.facility['مركز الأورام بنغازي'] }</td>
       <td  className="text-center" style={{flex:1}}>{ result.facility['مركز طبرق الطبي'] }</td> 
              <td  className="text-center" style={{flex:1}}>{ result.oncology }</td> 
              <td  className="text-center" style={{flex:1}}>{ index+1 }</td> 
           
              </tr> 
       )}
        { localStorage.getItem('facility') != ''&& rows[0] != undefined && (rows).map((result,index)  =>     
    <tr className="text-center" >
      <td  className="text-center">{rows[index]?.total}</td> 
        <td  className="text-center" style={{flex:1}}>{ result.facility[
          localStorage.getItem('facility')
        ] }</td> 
              <td  className="text-center" style={{flex:1}}>{ result.oncology }</td> 
              <td  className="text-center" style={{flex:1}}>{ index+1 }</td> 
           
              </tr> 
       )}
 


</table>
  
      
          </div>
       
      </div>
    </div>
  )
}

export default Facilitys