import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
} from "@mui/material";
import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from "../../AppBar";
import api from "../../../api/axios";

const Cycle = () => {
  // const navigate = useNavigate();
  let { uuid } = useParams();
  console.log()
  let a = 0;
  let day = 0;
  let activeday = [];
  const [open, setOpen] = useState(false);
  const [selectday, setselectday] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const d = [];
  const date = new Date();

  const [ciricle, setciricle] = useState([]);
  const [daycircle, setdaycircle] = useState([]);
  function onSubmit0(e) {

    e.preventDefault();

    setOpen(false);
    api
      .post(
        `/patient/medicine/circle/init`,
        {
          days: d,
          medicine: uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "تم اضافة الدواء بنجاح",
        }).then(() => {
          window.location.reload();
        });;
      })
      .catch(function (error) {
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "NetWork...",
            text: "!مشكلة في شبكة ",
          });
          return 0;
        }
      });
  }
  function onSubmit2(e) {
    e.preventDefault();

    setOpen2(false);
    api
      .post(
        `/patient/medicine/circle/day`,
        {
          days: d,
          circle: uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "تم اضافة الدواء بنجاح",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function (error) {
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "NetWork...",
            text: "!مشكلة في شبكة ",
          });
          return 0;
        }
      });
  }
  function onSubmit3(e) {
    setOpen1(false)
    e.preventDefault();


    api
      .put(
        `/patient/medicine/circle/day`,
        {
          date:date.toISOString().substring(0, 10),
          dose:String(daycircle.dose),
          height:String(daycircle.height),
          weight:String(daycircle.weight),
          status:String(daycircle.status),
          unit:daycircle.unit,
          uuid:daycircle.uuid,
          note:String(daycircle.note)



  

       
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "تم تعديل الدواء بنجاح",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function (error) {
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "NetWork...",
            text: "!مشكلة في شبكة ",
          });
          return 0;
        }
      });
  }
  function onSubmit4(e,uuid) {
    setOpen1(false)
    e.preventDefault();

//add pop up to confirm delete
Swal.fire({
  title: 'هل انت متاكد من حذف اليوم؟',
  text: "لن تستطيع استرجاع اليوم بعد الحذف",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText:'الغاء',
  confirmButtonText: 'نعم',
}).then((result) => {
  if (result.isConfirmed) {

    api
      .delete(
        `/patient/medicine/circle/day?uuid=${uuid}`,
        {
       


  

       
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "تم حذف الدواء بنجاح",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function (error) {
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "NetWork...",
            text: "!مشكلة في شبكة ",
          });
          return 0;
        }
      });
    }
  })

  }
  try {
    useEffect(() => {
      DataService.Getcircle(uuid)
        .then((data) => data)
        .then((data) => setciricle(data));
    }, []);
  } catch (err) {
    Msg("error");
  }
  // get fuctio
  function Get(uuid) {
    DataService.Getcircleday(uuid)
      .then((data) => data)
      .then((data) => setdaycircle(data));
   
  }

  ciricle?.days?.map((i) => {
    activeday.push(i.name);
  });
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
 
  return (
    <>
      <DrawerAppBar />
      <div className="App" dir="ltr">
        <div className="Home">
          <Dialog open={open} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>
              <h5 inputProps={{ min: 0, style: { textAlign: "right" } }}>
                cycle{" "}
              </h5>
            </DialogTitle>
            <form>
              <DialogContent>
                <div>
                  {[1, 2, 3, 4, 5].map((y) => {
                    return (
                      <div
                        className="flex"
                        style={{
                          height: "50px",
                        }}
                      >
                        {[1, 2, 3, 4, 5, 6].map((i) => {
                          a++;
                          return (
                            <div
                              key={a}
                              style={{
                                width: "120px",
                              }}
                            >
                              <label key={a + 100} style={{}}>
                                d {a <= 9 ? " " + a + "  " : a}
                              </label>
                              <input
                                key={a}
                                type="checkbox"
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    //push object to array name: e.target.value?
                                    d.push({
                                      name: e.target.value,
                                    });
                                  } else {
                                    d.pop({
                                      name: e.target.value,
                                    });
                                  }

                                  console.log(d);
                                }}
                                id={"d" + a}
                                name={"d" + a}
                                value={"d" + a}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit" onClick={(e) => onSubmit0(e)}>
                  Save
                </Button>

                {/* </div> */}
              </DialogActions>
            </form>
          </Dialog>
          <Dialog open={open2} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>
              <h5 inputProps={{ min: 0, style: { textAlign: "right" } }}>
                cycle{" "}
              </h5>
            </DialogTitle>
            <form>
              <DialogContent>
                <div>
                  {[1, 2, 3, 4, 5].map((y) => {
                    return (
                      <div
                        className="kiki"
                        style={{
                          height: "50px",
                        }}
                      >
                        {[1, 2, 3, 4, 5, 6].map((i) => {
                          day++;

                          if (!activeday.includes("d" + day)) {
                            return (
                              <div
                                key={day}
                                style={{
                                  width: "120px",
                                }}
                              >
                                <label key={a + 100} style={{}}>
                                  d {day <= 9 ? " " + day + "  " : day}
                                </label>
                                <input
                                  key={day}
                                  type="checkbox"
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      //push object to array name: e.target.value?
                                      d.push({
                                        name: e.target.value,
                                      });
                                    } else {
                                      d.pop({
                                        name: e.target.value,
                                      });
                                    }
                                  }}
                                  id={"d" + day}
                                  name={"d" + day}
                                  value={"d" + day}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={day}
                                style={{
                                  width: "120px",
                                }}
                              >
                                <label key={a + 100} style={{}}>
                                  d {day <= 9 ? " " + day + "  " : day}
                                </label>
                                <input
                                  key={day}
                                  type="checkbox"
                                  disabled
                                  id={"d" + day}
                                  name={"d" + day}
                                  value={"d" + day}
                                  checked
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen2(false)}>Cancel</Button>
                <Button type="submit" onClick={(e) => onSubmit2(e)}>
                  Save
                </Button>

                {/* </div> */}
              </DialogActions>
            </form>
          </Dialog>
          <Dialog open={open1} fullWidth={true} maxWidth={"xs"}>
            <DialogTitle>
              <h5 inputProps={{ min: 0, style: { textAlign: "right" } }}>
                {selectday.name}: اليوم
              </h5>
              <h5 inputProps={{ min: 0, style: { textAlign: "right" } }}>
                {ciricle?.dose}/{ciricle?.unit} الجرعة الموصوفة 
              </h5>
             
            </DialogTitle>
            <DialogContent>
              <div>
              <label
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                >
                  تاريخ اليوم
                </label>
                <input
                  type="text"
                  disabled
                  value={date.toISOString().substring(0, 10)}
                  onChange={(e) => {
                    setdaycircle({
                      ...daycircle,
                      time: e.target.value,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                />
                <label
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                >
                  الطول
                </label>
                <input
                  type="text"
                  value={daycircle.height+'cm'}
                  onChange={(e) => {
                    //removo cm from e.target.value
var a= e.target.value.replace('cm','')
if(isNaN(a)){
  a=0
}
                    setdaycircle({
                      ...daycircle,
                      height:a,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                />
                <label
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                >
                  الوزن
                </label>
                <input
                  type="text"
                  value={daycircle.weight+'kg'}
                  onChange={(e) => {
                    //removo cm from e.target.value
var a= e.target.value.replace('kg','')
//ckeck if a is number
if(isNaN(a)){
  a=0
}
                    setdaycircle({
                      ...daycircle,
                      weight:a,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                />
               <label
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                >
       الجرعة
                </label>
                <input
                  type="number"
                  value={daycircle.dose}
                  onChange={(e) => {
                    setdaycircle({
                      ...daycircle,
                      dose: e.target.value,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                />
                          <label
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                >
                 حالة اليوم
                </label>
                <select

                  value={daycircle.status}
                  onChange={(e) => {
                    setdaycircle({
                      ...daycircle,
                      status: e.target.value,
                    });}}
      
               
               
         
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                >
                    <option 
                  value={'2'}
                  >
              
                  </option> 
                  <option 
                  value={'fit'}
                  >
                يسمح 
                  </option>
                  <option
                  value={'unfit'}
                  >
                  لا يسمح 
                  </option>
                  </select>
                  {//note if status is unfit 
                  daycircle.status=='unfit'?  
                  <div>
                  <label
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",

                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                >
                  السبب
                </label>
                <input

                  type="text"

                  value={daycircle.note}
                  onChange={(e) => {
                    setdaycircle({
                      ...daycircle,
                      note
: e.target.value,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    float: "left",
                    textAlign: "right",
                  }}
                />
            </div>
            :null


                    }
              </div>
            </DialogContent>
            <div className='l'>
            <Button type="submit" onClick={(e) => onSubmit3(e)}>
                حفظ
                </Button>
              <Button onClick={() => setOpen1(false)}>الغاء</Button>
             

              {/* </div> */}
            </div>
          </Dialog>
          <br></br>
          <br></br>
          {ciricle.length == 0 ? (
            <button
              className="btn btn-primary"
              style={{
                width: "300px",
                height: "60px",
                borderRadius: "10px",
                marginRight: "10px",
                fontSize: "20px",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              onClick={() => setOpen(true)}
            >
           دورة علاجية جديدة  أضافة
            </button>
          ) : (
            <button
              className="btn btn-primary"
              style={{
                width: "300px",
                height: "60px",
                borderRadius: "10px",
                marginRight: "10px",
                fontSize: "20px",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              onClick={() => setOpen2(true)}
            >
              أضافة دورة علاجية جديدة
            </button>
          )}
          <br></br>
          <div className="flex">
            <table className="table" id="customers">
              <thead>
                <tr>
                  <th>
                    <h3
                      style={{
                        direction: "rtl",
                        color: "white",
                        float: "Center",
                      }}
                    >
                      الاسم العلاج
                    </h3>
                  </th>
                  <th>
                    <h3
                      style={{
                        direction: "rtl",
                        color: "white",
                        float: "Center",
                      }}
                    >
                 الجرعة   و  الوحده   
                    </h3>
                  </th>

                  <th>
                    <h3
                      style={{
                        direction: "rtl",
                        color: "white",
                        float: "Center",
                      }}
                    >
                   الطبيب المعالج
                    </h3>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h3
                      style={{
                        direction: "rtl",
                        color: "black",
                        float: "Center",
                      }}
                    >
                      {ciricle.name}
                    </h3>
                  </td>
                  <td>
                    <h3
                      style={{
                        direction: "rtl",
                        color: "black",
                        float: "Center",
                      }}
                    >
                      {ciricle.dose}/{ciricle.unit}
                    </h3>
                  </td>

                  <td>
                    <h3
                      style={{
                        direction: "rtl",
                        color: "black",
                        float: "Center",
                      }}
                    >
                      {ciricle.doctor_name}
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <div></div>
          </div>
          <br></br>

          <h3
            style={{
              direction: "rtl",
              color: "black",
              float: "Center",
            }}
          >
            الدورات العلاجية
          </h3>
          <table className="table" id="customers">
            <thead>
              <tr>
                {
                  //order by number in days
                  ciricle?.days?.sort((a, b) => a.number - b.number).map((item, index) => {
                  return (
                    <th
                      style={{
                        direction: "rtl",
                        color: "white",
                        float: "Center",
                      }}
                    >
                      <h3
                        style={{
                          direction: "rtl",
                          color: "white",
                          float: "Center",
                        }}
                      >
                        {item.name}
                      </h3>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
             { ciricle?.days?.sort((a, b) => a.number - b.number).map((item, index) => {
                  return (
                    <td
                      style={{
                        direction: "rtl",
                        color: "black",
                        float: "Center",
                      }}
                    >
                      <h3
                        style={{
                          direction: "rtl",
                          color: "black",
                          float: "Center",
                        }}
                      >
                        {item.status == "Inactive" ? (
                          <div>
                            <button
                              className="btn btn-primary"
                              style={{
                                backgroundColor: "#ffffff",
                                color: "#000000",
                                width: "30px",
                                height: "30px",
                                border: "2px solid #000000",
                                borderRadius: "100px",
                                marginRight: "10px",
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "10px",
                              }}
                              onClick={() => {
                                setOpen1(true);
                                setselectday(item);
                                Get(item.uuid);
                              }}
                            ></button>
                          </div>
                        ) : null}
                        {item.status == "fit" ? (
                          <div>
                            <button
                              className="btn btn-primary"
                              style={{
                               color: "green",
                               backgroundColor: "white",
                                fontSize: "20px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "100px",
                                border: "2px solid #4CAF50",
                                marginRight: "10px",
                                fontWeight: "bold",
                                marginLeft: "10px",
                                textAlign: "center",
                                float: "center",
                                padding: "0px",
                              }}
                              onClick={() => {
                                setOpen1(true);
                                setselectday(item);
                                Get(item.uuid);
                              }}
                            >+</button>
                          </div>
                        ) : null}
                         {item.status == "unfit" ? (
                          <div>
                            <button
                              className="btn btn-primary"
                              style={{
                               color: "red",
                               backgroundColor: "white",
                                fontSize: "20px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "100px",
                                border: "2px solid red",
                                marginRight: "10px",
                                fontWeight: "bold",
                                marginLeft: "10px",
                                textAlign: "center",
                                float: "center",
                                padding: "0px",
                              }}
                              onClick={() => {
                                setOpen1(true);
                                setselectday(item);
                                Get(item.uuid);
                              }}
                            >-</button>
                          </div>
                        ) : null}
                      </h3>
                    </td>
                  );
                })}
              </tr>
              <tr>
             { ciricle?.days?.sort((a, b) => a.number - b.number).map((item, index) => {
                  return (
                    <td
                      style={{
                        direction: "rtl",
                        color: "black",
                        float: "Center",
                      }}
                    >
                      {
                        //DELETE BUTTON?
                      }
                      <button
                        className="btn btn-primary"
                        style={{
                          background: "red",
                          color: "white",
                          border: "none",
                          width: "100px",
                        }}
                        onClick={(e) => {
                          onSubmit4(
                            e,
                            item.uuid
                          )
                        }}
                      >
                 حذف
                      </button>

                     </td>
                  );
                })}


        
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default Cycle;
