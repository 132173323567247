import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import Typography from "@mui/material/Typography";
import Cookies from "universal-cookie";
import { Box, Button, Radio } from "@mui/material";
import $, { map } from "jquery";
import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from '../../AppBar'
import api from "../../../api/axios";
const cookies = new Cookies();

const Oncologys = () => {

  const [isloading, setisloading] = useState(false);
  const [isloading0, setisloading0] = useState(false);
 
  let { id } = useParams();
  console.log( id )
  const [name, setname] = useState('أضافه');
  const [Uuid, setUuid] = useState(null);

  const [oncology, setoncology] = useState([]);
  const [Check, setCheck] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [formInputData, setformInputData] = useState({
    uuid: null,
    level: null,
    name: null,
    diagnosisdate: null,
    code:null,
    kind:null

  });

  //valadion
 
  const handleChangeTable1 = (evnt) => {
    setisloading(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;


    if (evnt.target.name === "level") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined 
      ) {
        setisloading(false);
      }
    }
  
    if (evnt.target.name === "diagnosisdate") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined &&
        formInputData.name != undefined
      ) {
        setisloading(false);
      }
    }
    if (evnt.target.name === "name") {
      uuidvalue = $('#oncologylist [value="' + value + '"]').data("value");
    
let code 
      oncology.map((oncology, index) => (
       
      value==oncology.name?  code = oncology.code :''
         ));

      newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
        code :code
      });

      if (uuidvalue != undefined) {
        setformInputData(newInput);
        console.log(formInputData)
        setCheck({ ...Check, [evnt.target.name]: true });
      
        if (
          formInputData.uuid != undefined &&
          formInputData.level != undefined &&
          formInputData.name != undefined
        ) {
          setisloading(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    
    }

    if (evnt.target.name == "kind") {
      setisloading(false);
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined &&
        formInputData.name != undefined &&
        formInputData.kind != undefined

      ) {
        setisloading(false);
      }
    }
  
  };

  //Submint
  const handleSubmit = (evnt) => {
    setisloading(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData];
      setTableData(newData);
      const emptyInput = {
        uuid: undefined,
        level: undefined,
        diagnosisdate: undefined,
        name: undefined,
        code:undefined,
        kind:undefined
      };
      var tokens =  localStorage.getItem("accessToken");
   
    
      if(name=='تعديل'){
       
        api
        .put(
          `/patient/oncology?uuid=` + Uuid,
          {
            "oncology": formInputData.uuid            ,
            "level": formInputData.level,
            "diagnosisdate": formInputData.diagnosisdate,
            
            "kind": formInputData.kind

        },
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        )
        .catch(function (error) {
            ;
          if (error) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error + "!",
            });
            return 0;
          }
        });
      Swal.fire({
        icon: "success",
        title: "success...",
        text: "تم تعديل المريض",
      });
      }else{
        api
        .post(
          `/patient/oncology/add`,
          {
            "patient":id,
            "oncology": formInputData.uuid            ,
            "level": formInputData.level,
            "diagnosisdate": formInputData.diagnosisdate,
            "kind": formInputData.kind

        },
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        )
        .catch(function (error) {
            ;
          if (error) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error + "!",
            });
            return 0;
          }
        });
      Swal.fire({
        icon: "success",
        title: "success...",
        text: "تم أضافة المرض للمريض",
      });
     
      }
 
      setformInputData(emptyInput);
      document.getElementById("name").value = "";
      document.getElementById("diagnosisdate").value = "";
      document.getElementById("level").value = "";
      setname('أضافه')

    }
  };

  
  const handleChange = (data) => {
  
      
        setUuid(data.uuid)
        const employeesCopy = tableData.filter(
          (employee) => employee.uuid !== data.uuid

        );
        setisloading(false);
        document.getElementById("name").value = data.name;
        document.getElementById("level").value = data.level;
        document.getElementById("kind").value = data.kind;

        data.diagnosisdate = new Date(data.diagnosisdate);
          var a = Math.floor(data.diagnosisdate.getTime());
          a = new Date(a);
          data.diagnosisdate =a.toISOString().split("T")[0]
        document.getElementById("diagnosisdate").value =  data.diagnosisdate;
        setformInputData(data)
        var  uuidvalue = $('#oncologylist [value="' + data.name + '"]').data("value");
 
    console.log(uuidvalue)
          setformInputData({
            ...data,
            uuid: uuidvalue,
           
          });
       
        setTableData(employeesCopy);
   setname('تعديل')
  
  };
function Msg(params) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: params+ "!",
  });
  return 0;
}
  //Service Get Data
  try {
    useEffect(() => {

      DataService.Getoncologys()
        .then((data) => data)
        .then((data) => setoncology(data)).catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
       
          DataService.GetRefresh().catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
          Msg('error');
          
 
        }})
     } });
        DataService.GetonePation(id)
        .then((data) => data)
        .then((data) => {
         setTableData(data.oncologys)
         console.log(data.oncologys)
          
         setisloading0(true)
        })
       
    }, []);
   
  } catch (err) {
   
  }
  const navItems = [];

  return (
    <>
      <DrawerAppBar navItems = {navItems} />
      {!isloading0 ? <div className="spinner-container">
          <div className="loading-spinner">
          
          </div>
        </div> :
    <div className="App">
     
    <div className="Home">
     <br></br>
     <br></br>
  
    <br />

    <Typography className="R">بيانات التشخيص</Typography>

    <FormInput
      handleChangeTable1={handleChangeTable1}
      formInputData={formInputData}
      handleSubmit={handleSubmit}
      oncology={oncology}
      Check={Check}
      isloading={isloading}
      name={name}
    />

    <Box
      sx={{
        height: tableData.length <= 5 ? 500 : 1000,
        backgroundColor: "primary",
      }}
    >
      <Table tableData={tableData} handleChange={handleChange} name={name} />
    </Box>
</div>
</div>}
</>
  );
};

export default Oncologys;

export const Table = ({ tableData, handleChange ,name }) => {
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time.getTime());
    a = new Date(a);
    return a.toISOString().split("T")[0];
     
  }
  function Delete(uuid) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
   
      if (result.isConfirmed) {
        var tokens =  localStorage.getItem("accessToken");
 
        api
        .delete(
          `/patient/oncology?uuid=` + uuid,
        
     
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        )
        .catch(function (error) {
            
          if (error) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error + "!",
            });
            return 0;
          }
          if (!error.status) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "NetWork...",
              text: "!مشكلة في شبكة ",
            });
            return 0;
          }
        });
      Swal.fire({
        icon: "success",
        title: "success...",
        text: "تم حذف المرض",
      }).then(()=>window.location.reload());
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
   
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                رمز الورم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                اسم الورم
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                المستوى {" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                التاريخ التشخيص
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
           التشخيص
              </h3>

            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                تعديل
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                حذف
              </h3>
            </th>
            
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.code}</td>
                <td>{data.name}</td>
                <td>{data.level}</td>
                <td>{time(data.diagnosisdate)}</td>
                <td>{data.kind}</td>
                <td>
                {name=='أضافه'?  <button
                    style={{
                      background: "#3f51b5",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleChange(data)}
                    className=""
                  >
                  تعديل
                  </button>:null}
                  {/* ca8d156da92f446480975c5ab8ce1e0a */}
                </td>
                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                   onClick={() => Delete( data.uuid)}
                    className=""
                  >
                  حذف
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function FormInput({
  handleChangeTable1,
  oncology,
  Check,
  handleSubmit,
  isloading,
  name
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label">اسم الورم</label>
            <br></br>
            <input
              list="oncologylist"
              id="name"
              name="name"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable1}
            />
            <h6 className="R">
              {Check?.oncology === false ? "  يرجي التأكد  من  حروف الورم" : ""}
            </h6>
            <datalist id="oncologylist">
              {oncology.map((oncology) => (
                <option
                  key={oncology.uuid}
                  value={oncology.name}
                  data-value={oncology.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label">خطورة الورم </label>
            <br></br>
            <select
              className="input"
              name="level"
              id="level"
              onChange={handleChangeTable1}
            >
              <option></option>
              <option value="standard">standard risk</option>
              <option value="intermediate">intermediate risk</option>
              <option value="high">high risk</option>
            </select>
          </div>
        </div>

        <div className="flex">
        <div className="0">
            <label className="label">خيارت التشخيص</label>
            <br></br>
            <select
              className="input"
              name="kind"
              id="kind"
              onChange={handleChangeTable1}
            >
              <option></option>
              <option value="CT">CT</option>
              <option value="PET /CT/SCAN">PET /CT/SCAN</option>
              <option value="MRI">MRI</option>
              <option value="MAMMOGRAM">MAMMOGRAM</option>
              <option value="ENDOSCOPE">ENDOSCOPE</option>
              <option value="BIOPSY">BIOPSY</option>
              <option value="HISTOPATHOLOGY">HISTOPATHOLOGY</option>
            </select>
          </div>
          <div className="0">
            <label className="label">تاريخ التشخيص </label>
            <br></br>
            <input
              className="input"
              type="date"
              name="diagnosisdate"
              id="diagnosisdate"
              onChange={handleChangeTable1}
            ></input>
          </div>
         
        </div>
      </div>
      <div className="0">
            <br></br>
            <Button
              variant="contained"
              className="Save-Button"
              disabled={isloading}
              onClick={handleSubmit}
              style={{
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            >
            {name}
            </Button>
          </div>
    </Box>
  );
}

