import Login from "./components/view/Login";
import RequireAuth from "./context/RequireAuth";
import { Routes, Route } from "react-router-dom";

import "./index.css";
import { Report } from "./components/Report/Report";
import { View } from "./components/view/view";

import New from "./components/view/New";
import Home from "./components/view/Home";
import { One } from "./components/Report/One";
import Edit from "./components/view/Eidit/Edit";
import Oncologys from "./components/view/Eidit/oncologys";
import RoleAccess from "./hooks/Role";
import Medicine from "./components/view/Eidit/Medicines";
import Disease from "./components/view/Eidit/Disease";
import FacilityAccess from "./hooks/facility";

import Admin from "./components/Admin/Admin";
import { AuthProvider } from "./hooks/useAuth";
import { Index } from "./components/Pharm/Index";

import Radiations from "./components/view/Eidit/Radiations";
import Search from "./components/view/Search";
import Name from "./components/view/Name";

import AddImage1 from "./WhatsApp Image 2022-12-13 at 8.13.39 PM.jpeg";
import Invoice from "./components/view/invoice";

import Medicinesview from "./components/view/Eidit/Medicinesview";
import Cycle from "./components/view/Eidit/Cycle";
import InvoiceModal from "./components/view/Eidit/InvoiceModal";
import Analysis from "./components/view/Eidit/Print";
import Medicines from "./components/view/Eidit/Medicines";


const ROLES = {
  User: "superadmin",
  Editor: "superadmin",
  Admin: "superadmin",
};
const year = new Date().getFullYear();
function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* public routes */}
        <Route path="/login" element={<Login />} />

        {/* we want to protect these routes */}
        
        <Route  element={<RequireAuth />}>
        <Route element={ <RoleAccess grantedRoles={['pharmacist','admin','superadmin','doctor','reception','analist']} /> }>
        
          <Route path="/Search" element={<Search />} />
          <Route path="/Search/Invoice/:id" element={<Invoice />} />
        </Route>
      
        <Route element={ <RoleAccess grantedRoles={['admin','superadmin','doctor','reception','analist']} /> }>
          <Route path="/New" element={<New />} />
          <Route path="/" element={<Home />} />
          <Route path="/View" element={<View />} />
          <Route path="/Pharm" element={<Index />} />  
          <Route path="/View/:id" element={<Edit />} />
          <Route path="/View/r/:id" element={<InvoiceModal />} />
          <Route path="/View/Disease/:id" element={<Disease />} />
          <Route path="/View/oncologys/:id" element={<Oncologys />} />
          <Route path="/View/Radiations/:id" element={<Radiations />} />
          <Route path="/View/analysis/:id" element={<Analysis/>} />
          <Route path="/View/Medicines/:id" element={<Medicines />} />
          <Route path="/View/Medicinesview/:id" element={<Medicinesview />} />
          <Route path="/View/Name/:id" element={<Name />} />
          <Route path="/View/Medicines/:id/cycle/:uuid" element={<Cycle />} />
          <Route element={ <FacilityAccess /> }>
          </Route>      
          <Route element={ <RoleAccess grantedRoles={['admin','superadmin']} /> }>
          <Route path="/Admin" element={<Admin />} />
          <Route path="/Search" element={<Search />} />
          </Route>
          <Route element={ <RoleAccess grantedRoles={['admin','superadmin','doctor','analist']} /> }>
          <Route path="/Report" element={<Report />} />
          <Route path="/Report/one/:type" element={<One />} />
          <Route path="/Report/one/:type/:id" element={<One />} />
          </Route> 
          <Route element={ <RoleAccess grantedRoles={['reception']} /> }>
        <Route path="/View" element={<View />} />
        <Route path="/View/:id" element={<Edit />} />
      </Route>
          </Route>
         
      
        </Route>
     
      </Routes>
   

    
    </AuthProvider>
  );
}

export default App;
