import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from "react-hook-form";
import DialogTitle from '@mui/material/DialogTitle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DataService from '../../../services/data.service';
import Swal from 'sweetalert2';
import MedicinesService from '../../../services/medicines.service';
import { DialogActions } from '@mui/material';
import { BiSave } from 'react-icons/bi';



const columns = [
   
    {
        field: 'name',
        headerName: 'Name',
             flex:4,
             editable: true
      },
     {
        field: 'measruingunit',
        headerName: 'Measruingunit',

              flex:1,
              editable: true

     },
      {
        field: 'code',
        headerName: 'code',
             flex:1,
             editable: true
      },
     
   
      {
        field: "actionEdit",
        headerName: "Save",
        sortable: false,
        renderCell: (params) => {
          const onClick = (e) => {
            MedicinesService.PutMedicines(params.row)  .then(function (response) {
            
              Swal.fire({
                icon: "success",
                title: "success...",
                text: "   تم تعديل المرض بالنجاح",
              }).then(() => window.location.reload());
            })
            .catch(function (error) {
                
              if (error.response) {
                Swal.fire({
                  icon: "error",
                  title: "",
                  text: error.response?.data?.error + "!",
                });
                return 0;
              }
              if (!error.status) {
                if (error) {
                  Swal.fire({
                    icon: "error",
                    title: "NetWork",
                    text: "مشكلة في شبكه!",
                  });
                  return 0;
                }
              }
            });
          };
    
          return <Button onClick={onClick}><BiSave  /></Button>;
        },
      },

];

const Medicines = () => {
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = React.useState({});
      console.log(rows)
    const { register, handleSubmit,  formState: { errors } } = useForm();
    const onSubmit = data => MedicinesService.PostMedicines(data).then(function(response) {
        setOpen(false)
        Swal.fire({
   icon: "success",
   title: "success...",
   text: "   تم أضافه العلاج بالنجاح",
 }).then(() => window.location.reload())

 })
 .catch(function(error){
   if (error.response) {
    setOpen(false)
         Swal.fire({
           icon: "error",
           title: "",
           text: error.response?.data?.error + "!",
         });
         return 0;
       }
   if(!error.status){
     if (error) {
 
           Swal.fire({
             icon: "error",
             title: "NetWork",
             text: "مشكلة في شبكه!",
           });
           return 0;
         }
   }

 });

    function Msg(params) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: params+ "!",
        });
        return 0;
      }
     //Service Get Data
  try {
    useEffect(() => {
      MedicinesService.GetMedicines()
        .then((data) => data)
        .then((data) => setRows(data)).catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
       
          DataService.GetRefresh().catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
        window.location.reload();
        }})
        window.location.reload();
     } });
 
    }, []);
   
  } catch (err) {
   
  }
  return (
    <div className='w-100'>
<div className='center'>
<Button variant="outlined" onClick={()=> setOpen(true)}>
      أضافة علاج جديده
      </Button>
      <Dialog open={open}  fullWidth={true}
        maxWidth={'md'} >
        <DialogTitle>أضافه</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          
        
    
      <TextField  id="standard-basic" label="Name" variant="standard" {...register("name" , { required: true })}
         inputProps={{min: 0, style: {  }}}      sx={{
      width:'100%',
    // "& label": {
    //   marginLeft: "90%",
    //   "&.Mui-focused": {
    //     marginLeft: "85%",
           }  }
   />
      <br />
      {errors.name && <span  className='R'>confirmation The Name .</span>}
 <br />
 <TextField  id="standard-basic" label="measruingunit" variant="standard" {...register("measruingunit" , { required: true })}
         inputProps={{min: 0, style: {  }}}      sx={{
      width:'100%',
    // "& label": {
    //   marginLeft: "90%",
    //   "&.Mui-focused": {
    //     marginLeft: "85%",
           }  }
   />
      <br />
      {errors.measruingunit && <span  className='R'>confirmation The measruingunit .</span>}
 <br />
        
 <TextField  id="standard-basic" label="code" variant="standard" {...register("code" , { required: true })}
         inputProps={{min: 0, style: {  }}}      sx={{
      width:'100%',
    // "& label": {
    //   marginLeft: "90%",
    //   "&.Mui-focused": {
    //     marginLeft: "85%",
           }  }
   />
      <br />
      {errors.code && <span  className='R'>confirmation The code .</span>}
 
      <br />
     
     
        </DialogContent>
        <DialogActions className='l'>
           

           
        <Button onClick={()=> setOpen(false)}>Cancel</Button>
           
        <Button  type='submit' >Save</Button>
        </DialogActions>
        </form>
      </Dialog>
</div>
<br />
<div style={{   width: '100%', background: 'white' }} className="table" >
<Box sx={{ height:'84vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
       
        className="DataGrid"
        getRowId={(rows1) => rows1?.uuid}
        experimentalFeatures={{ newEditingApi: true }}
  
      />
    </Box>

</div>
    </div>
  )
}

export default Medicines