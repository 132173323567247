import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const GetFacility = async () => {
  const response=  await axios
  .get("/facilitys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Postfacility = async (data) => {
  
  const response=  await axios
  .post("/facility",
  {
    name:data.name,
    lang:data.lang, 
    loat:data.loat, 
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Putfacility = async (data) => {
  
  const response=  await axios
  .put("/facility?uuid="+data.uuid,
  {
    name:data.name,
    lang:data.lang, 
    loat:data.loat, 
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const FacilitysService = {
  GetFacility,
  Postfacility,
  Putfacility,
}

export default FacilitysService;
