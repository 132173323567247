import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import DataService from '../../services/data.service';
import DrawerAppBar from '../AppBar';

export const Index = () => {
  const [rows, setrows] = useState({});
  const [code, setcode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
  function onclick(params) {
    DataService.GetonePationbycode(code)
      .then((data) => data)
      .then((data) => {
        setTableData(data.oncologys);
        setTableData2(data.medicines);
        setrows(data)

        setIsLoading(true)
      }).catch(function (err) {
        if (!err?.response) {
          Msg('No Server Response');
        } else if (err.response?.status === 400) {
          Msg('');
        }
        else if (err.response?.status === 404) {
          setrows(null)
          setIsLoading(false)
          Msg('رقم الملف غير صحيح');
        } else if (err.response?.status === 401 || err.response?.status === 422) {

          DataService.GetRefresh().catch(function (err) {
            if (!err?.response) {
              Msg('No Server Response');
            } else if (err.response?.status === 400) {
              Msg('error');
            } else if (err.response?.status === 401 || err.response?.status === 422) {
              window.location.reload();
            }
          })
          window.location.reload();
        }
      });

  }



    

  return (
    <>
      <DrawerAppBar />
      <div className='flex m6'>
        <TextField label="Code" id="fullWidth" onChange={(e) => setcode(e.target.value)} />

        <Button variant="contained" onClick={() => onclick()}>Search</Button>

      </div>
      <br />
      {isLoading && rows && <>

        <div className="flex">

          <div className="0">
            <label className="label">اسم المركز</label>
            <br></br>
            <input
              className="input"
              value={rows.facility ? rows.facility : ""}
              required
              disabled
              name="facility"
            ></input>

          </div>
          <div className="0">
            <label className="label">الاسم الرباعى</label>
            <br></br>
            <input
              className="input"
              value={rows.name ? rows.name : ""}
              required
              disabled
              name="name"
            ></input>

          </div>
        </div>
        <div className="flex">

          <div className="0">
            <label className="label">فصيلة الدم</label>

            <br></br>
            <input
              className="input"
              value={rows.bloadtype ? rows.bloadtype : ""}
              required
              disabled
              name="bloadtype"
            ></input>

          </div>
          <div className="0">
            <label className="label">تاريخ الميلاد </label>
            <br></br>
            <input
              className="input1"
              type="date"
              required
              value={rows.birthday ? rows.birthday : ""}
              name="birthday"
              disabled
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
            ></input>
          </div>
        </div>
        <div className='flex'>


          <div>

            <Typography className="R">بيانات التشخيص</Typography>

            <Box
              sx={{
                height: tableData.length <= 5 ? 300 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table tableData={tableData} />
            </Box>
            <br></br>
            <Typography className="R">بيانات التشخيص</Typography>

            <Box
              sx={{
                height: tableData.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table2 tableData2={tableData2} />
            </Box>
          </div>


        </div>
      </>
      }
    </>

  )
}
export const Table = ({ tableData }) => {

  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                التاريخ التشخيص
              </h3>
            </th>



            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                المستوى {" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم الورم
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                رمز الورم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{data.diagnosisdate}</td>
                <td>{data.level}</td>
                <td>{data.name}</td>
                <td>{data.code}</td>
                <td>{index + 1}</td>
                {tableData.length == 0 ? <td>1</td> : null}
                {tableData.length == 0 ? <td >لايوجد  مرض</td> : null}

              </tr>

            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export const Table2 = ({ tableData2 }) => {

  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>

       

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                الكمية
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم العلاج
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData2.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>
                <td>{data.quantity}</td>



              </tr>
            );
          })}
          {tableData2.length == 0 ? <td>1</td> : null}
          {tableData2.length == 0 ? <td>لايوجد علاج</td> : null}
          {tableData2.length == 0 ? <td>0</td> : null}

        </tbody>
      </table>
    </div>
  );
};
export const Table3 = ({ tableData3 }) => {

  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "center",
                }}
              >
                اسم المرض
              </h3>{" "}
            </th>

            <th>

            </th>
          </tr>
        </thead>
        <tbody>
          {tableData3.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>

              </tr>
            );
          })}
          {tableData3.length == 0 ? <td>1</td> : null}
          {tableData3.length == 0 ? <td>لايوجد امراض</td> : null}

        </tbody>
      </table>
    </div>
  );
};
