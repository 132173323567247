import React from "react";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Link } from "react-router-dom";
import DataService from "../../services/data.service";
function Card() {
  const exportToCSV = () => {
    DataService.exportToCSV()
  }
       
       
  return (
    <>
      <div className="featured">
      <Link to="one/Sex" sx={{ color: "#000" }} className="featuredItem"  >
        <div className="">
          
            <span className="featuredTitle"> الجنس </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
     
        </div>
        </Link>
        <Link
            to="one/BloodType"
            sx={{ color: "#000" }}
            className="featuredItem"
          >
        <div className="">
       
            <span className="featuredTitle">فصيلة الدم</span>
            <div className="featuredMoneyContainer"></div>
         
        </div>
        </Link>
        <Link
            to="one/Facilitys"
            sx={{ color: "#000" }}
           className="featuredItem"
          >
        <div className="">
          
            <span className="featuredTitle"> المدينة </span>
            <div className="featuredMoneyContainer"></div>
        
        </div>
        </Link>
      </div>
      <div className="featured">
      <Link
            to="one/medicinepatient"
            sx={{ color: "#000" }}
            className="featuredItem"
          >
        <div className="">
       
            <span className="featuredTitle">الأدوية تفصيلي</span>
            <div className="featuredMoneyContainer"></div>
            {/* <ArrowDownwardIcon  style={{color:'red'}}/> */}
        
        </div>
        </Link>
        <Link
            to="one/Group"
            sx={{ color: "#000" }}
          className="featuredItem"
          >
        <div className="">
        
            <span className="featuredTitle">إحتياج أدوية الأورام </span>
            <div className="featuredMoneyContainer"></div>
      
        </div>
        </Link>
        <Link
            to="one/Birthdate"
            sx={{ color: "#fff" }}
           className="featuredItem"
          >
        <div className="">
       
            <span className="featuredTitle">تاريخ الميلاد</span>

            <div className="featuredMoneyContainer"></div>
        
        </div>
        </Link>
      </div>
      <div className="featured">
      <Link to="one/Indate" sx={{ color: "#000" }}  className="featuredItem">
        <div className="">
         
            <span className="featuredTitle">تاريخ الدخول</span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
         
        </div>
        </Link>
        <Link to="one/Outedate" sx={{ color: "#000" }} className="featuredItem"
        >
        <div >
         
            <span className="featuredTitle"> حالات الخروج   </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
        
        </div>
        </Link>
        <Link to="one/Facilitypatient" sx={{ color: "#000" }}  className="featuredItem">
        <div className="">
         
            <span className="featuredTitle"> تعداد المراكز</span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
         
        </div>
        </Link>
   
        </div>
      <div className="featured">
      <Link to="one/r" sx={{ color: "#000" }}className="featuredItem" >
        <div>
        
            <span className="featuredTitle">  الإحصائيات المدن  </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
        
        </div>
        </Link>
        <Link to="one/sexChart" sx={{ color: "#000" }}className="featuredItem" >
        <div>
        
            <span className="featuredTitle"> الإحصائيات الجنس  </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
        
        </div>
        </Link>
        <Link to="one/BloodTypeChart" sx={{ color: "#000" }}className="featuredItem" >
        <div>
        
            <span className="featuredTitle"> الإحصائيات فصائل الدم  </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
        
        </div>
        </Link>
       

      </div>
      <div className="featured">
      <Link to="one/Facilitymidecine" sx={{ color: "#000" }}className="featuredItem" >
        <div>
        <span className="featuredTitle">    الإحتياج بالنسبة للمركز </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
        
        </div>
        </Link>
      {/* <Link onClick={()=>exportToCSV()} sx={{ color: "#000" }}className="featuredItem" >
        <div>
        
            <span className="featuredTitle"> انشاء تقارير excel   </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
        
        </div>
        </Link> */}
        <Link to="one/c" sx={{ color: "#000" }}className="featuredItem" >
        <div>
        
            <span className="featuredTitle">  الإحصائيات الأورام  </span>
            <div className="featuredMoneyContainer"></div>
            <span className="featuredSub"></span>
        
        </div>
        </Link>
      </div>
      
    </>
  );
}

export default Card;
