import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import api from "../../api/axios";
import $ from "jquery";
import Swal from "sweetalert2";
import DataService from "../../services/data.service";
import DrawerAppBar from "../AppBar";
var facility =   localStorage.getItem('facility' )
const New = () => {
  const [page, SetPage] = useState(1);

  const [state, setState] = useState({});
  const [isloading, setisloading] = useState(true);
  const [isloading2, setisloading2] = useState(true);
  const [isloading3, setisloading3] = useState(true);
  const [isloading4, setisloading4] = useState(true);
  const [nationality, setnationality] = useState([]);
  const [facilitys, setfacilitys] = useState([]);
  const [bloadtype, setbloadtype] = useState([]);
  const [medicine, setmedicine] = useState([]);
  const [oncology, setoncology] = useState([]);
  const [address, setAddresss] = useState([]);
  const [disease, setdisease] = useState([]);
  const [Radiations, setRadiations] = useState([]);
  const [Check, setCheck] = useState([]);
  const [isMale, setisMale] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  const [formInputData, setformInputData] = useState({
    uuid: null,
    level: null,
    date: null,
    Name: null,
    code:null,
    kind:null
  });
  const [formInputData4, setformInputData4] = useState({
    radiation: null,
    radiationtype:null,
    tecnology:null,
    startdate: null,
    enddate: null,
    status: null,
    uuid:null
  });
  const [tableData4, setTableData4] = useState([]);
  
  const [formInputData2, setformInputData2] = useState({
    uuid: null,
    quantity: 1,
    medicine: null,
  });
  const [tableData3, setTableData3] = useState([]);
  const [formInputData3, setformInputData3] = useState({
    uuid: null,
    diseases: null,
  });

  //valadion
  const handleChange = (event) => {
    const value = event.target.value;
    let a = [];
    let uuidvalue = null;

    if (event.target.name === "bloadtypeName") {
      bloadtype.map((bloadtype, index) => a.push(bloadtype.name));

      if (!a.includes(value.toUpperCase())) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#bloadtypelist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        bloadtypeName1:value


      });
    }
    if (event.target.name === "address") {
      address.map((address, index) => a.push(address.name));

      if (!a.includes(value.toUpperCase())) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#Addressslist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        AddresssName:value
      });
    }
    if (event.target.name === "nationalityName") {
      nationality.map((nationality, index) => a.push(nationality.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#nationalitylist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        libya: value,
        nationalityName1:value
      });
   
    }

    if (event.target.name === "status") {
      if (value === "") {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
        isout: true,
      });
    }
    if (event.target.name === "name") {
      if (value.length <= 5) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "mothername") {
      setCheck({ ...Check, [event.target.name]: true });

      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "phone_number") {
      if (value.length != 10) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "facilitys") {
      facilitys.map((facilitys, index) => a.push(facilitys.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#citylist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        facilitys1:  value
      });
    }

    if (event.target.name === "NID") {

      if (value.length != 12 && state.libya === "ليبيا") {
        setCheck({ ...Check, [event.target.name]: true });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "medicine") {
      medicine.map((medicine, index) => a.push(medicine.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "oncology") {
      oncology.map((oncology, index) => a.push(oncology.name));
        ;
      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
    if (event.target.name === "birthday") {
      setCheck({ ...Check, [event.target.name]: true });
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
    if (
      event.target.name != "facilitys" &&
      event.target.name != "bloadtypeName" &&
      event.target.name != "nationalityName" &&
         event.target.name != "address"
    ) {
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
  };
  const handleChangeTable1 = (evnt) => {
    setisloading(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    

    if (evnt.target.name === "level") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined &&
        formInputData.oncology != undefined
      
      ) {
        setisloading(false);
      }
    }
    if (evnt.target.name === "date") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined &&
        formInputData.oncology != undefined
      ) {
        setisloading(false);
      }
    }
    if (evnt.target.name === "oncology") {
      uuidvalue = $('#oncologylist [value="' + value + '"]').data("value");
    
let code 
      oncology.map((oncology, index) => (
       
      value==oncology.name?  code = oncology.code :''
         ));

      newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
        code :code

      });

      if (uuidvalue != undefined) {
        setformInputData(newInput);
  
        setCheck({ ...Check, [evnt.target.name]: true });
      
        if (
          formInputData.uuid != undefined &&
          formInputData.level != undefined &&
          formInputData.oncology != undefined
        ) {
          setisloading(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    if (evnt.target.name == "kind") {
      setisloading(false);
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined &&
        formInputData.kind != undefined

      ) {
        setisloading(false);
      }
    }

  };

  const handleChangeTable2 = (evnt) => {
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    if (evnt.target.name === "name") {
      uuidvalue = $('#medicinelist [value="' + value + '"]').data("value");
      console.log(uuidvalue);

      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });

      if (uuidvalue != undefined) {
        setformInputData2(newInput);
        setCheck({ ...Check, [evnt.target.name]: true });
        if (
          formInputData2.uuid != undefined &&
          formInputData2.quantity != undefined &&
          formInputData2.name != undefined &&
          formInputData2.dose != undefined &&
          formInputData2.frequency != undefined &&
          formInputData2.roa != undefined
        ) {
          setisloading2(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    if (evnt.target.name === "quantity"
    || evnt.target.name === "dose") {
      if (evnt.target.value <= 0) {
        evnt.target.value = 1;
      }
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData2(newInput);
      if (
        formInputData2.uuid != undefined &&
        formInputData2.quantity != undefined &&
        formInputData2.name != undefined &&
        formInputData2.dose != undefined &&
        formInputData2.frequency != undefined &&
        formInputData2.roa != undefined
      ) {
        setisloading2(false);
      }
    }
    if (evnt.target.name != "quantity" || evnt.target.name != "name"
    || evnt.target.name != "dose") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData2(newInput);
      if (
        formInputData2.uuid != undefined &&
        formInputData2.quantity != undefined &&
        formInputData2.name != undefined &&
        formInputData2.dose != undefined &&
        formInputData2.frequency != undefined &&
        formInputData2.roa != undefined
      ) {
        setisloading2(false);
      }
    }
  
  };
  const handleChangeTable3 = (evnt) => {
    setisloading3(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
      ;

    if (evnt.target.name === "diseases") {
      uuidvalue = $('#diseaselist [value="' + value + '"]').data("value");


      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });

      if (uuidvalue != undefined) {
        setformInputData3(newInput);
       
        setCheck({ ...Check, [evnt.target.name]: true });
        if (formInputData3.uuid != undefined) {
          setisloading3(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }

    setformInputData3(newInput);
    if (formInputData3.uuid != undefined) {

    } else {
      setisloading3(false);
    }
  };
  const handleChangeTable4 = (evnt) => {
    setisloading4(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    

    if (evnt.target.name === "status") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        formInputData4.radiation != undefined &&
        formInputData4.uuid != undefined &&
        formInputData4.status != undefined &&
        formInputData4.startdate != undefined
      ) {
        setisloading4(false);
      }
    }
   
    if (evnt.target.name === "radiation") {
      uuidvalue = $('#radiationlist [value="' + value + '"]').data("value");
    


      newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
       
      });

      if (uuidvalue != undefined) {
        setformInputData4(newInput);
      
        setCheck({ ...Check, [evnt.target.name]: true });
      
        if (
          formInputData4.radiation != undefined &&
          formInputData4.status != undefined &&
          formInputData4.uuid != undefined 
        ) {
          setisloading4(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    if (evnt.target.name === "startdate") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        formInputData4.radiation != undefined &&
        formInputData4.status != undefined &&
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }
    if (evnt.target.name === "enddate") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        formInputData4.radiation != undefined &&
        formInputData4.status != undefined &&
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }
    if (evnt.target.name === "radiationtype") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        formInputData4.radiation != undefined &&
        formInputData4.status != undefined &&
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }
    if (evnt.target.name === "tecnology") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        formInputData4.radiation != undefined &&
        formInputData4.status != undefined &&
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }
  };
  //Submint
  const handleSubmit = (evnt) => {
    setisloading(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData];
      setTableData(newData);
      const emptyInput = {
        uuid: undefined,
        level: undefined,
        date: undefined,
        Name: undefined,
        kind: undefined,
      };
      setformInputData(emptyInput);
      document.getElementById("oncology").value = "";
      document.getElementById("date").value = "";
      document.getElementById("level").value = "";
      document.getElementById("kind").value = "";
    }
  };
  const handleSubmit2 = (evnt) => {

    setisloading2(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData2).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData2];
      setTableData2(newData);
      const emptyInput = {
        medicine: undefined,
        quantity: undefined,
        uuid: undefined,
        dose: undefined,
        frequency: undefined,
        roa: undefined,


      };
      setformInputData2(emptyInput);
      document.getElementById("name").value = "";
      document.getElementById("quantity").value = "";
      document.getElementById("dose").value = "";
      document.getElementById("frequency").value = "";
      document.getElementById("medicine").value = "";
      document.getElementById("unit").value = "";
      document.getElementById("roa").value = "";

    }
  };
  const handleSubmit3 = (evnt) => {

    setisloading3(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData3).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData3];
      setTableData3(newData);
      const emptyInput = {
        name: undefined,
        uuid: undefined,
      };
      setformInputData3(emptyInput);
      document.getElementById("diseases").value = "";
    }
  };
  const handleSubmit4 = (evnt) => {
    setisloading4(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData4).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData4];
      setTableData4(newData);
      const emptyInput = {
        uuid: undefined,
        status: undefined,
        startdate: undefined,
        enddate: undefined,
        tecnology:undefined,
        radiationtype:undefined,
        Name: undefined,
      };
      setformInputData4(emptyInput);
      document.getElementById("radiation").value = "";
      document.getElementById("startdate").value = "";
      document.getElementById("tecnology").value = "";
      document.getElementById("radiationtype").value = "";
      document.getElementById("status").value = "";
      document.getElementById("enddate").value = "";
    }
  };
  async function  onSubmit() {
 
    var tokens =  localStorage.getItem("accessToken");
    // try {
 await  api
      .post(
        `/patient/init`,
        {
          status: state.status ? state.status : null,
          name: state.name ? state.name : null,
          mothername: state.mothername ? state.mothername :null,
          passport: state.passport ? state.passport : null,
          NID: state.NID ? state.NID : null,
          birthday: state.birthday ? state.birthday :null,
          indate: state.indate ? state.indate : null,
          exitdate: state.outdate ? state.outdate : null,
          phone_number: state.phone_number ? state.phone_number : null,
          address: state.address  ,
          ismale: isMale === true,
          isout: state.isout ? state.isout : false,
          facility: state.facilitys? state.facilitys : null ,
          bloadtype: state.bloadtypeName ? state.bloadtypeName : null,
          nationality: state.nationalityName ? state.nationalityName : null,
          oncologys: tableData ? tableData : [{ uuid: null }],
          medicines: tableData2 ? tableData2 : [{ uuid: null }],
          diseases: tableData3 ? tableData3 : [{ uuid: null }],
          radiations : tableData4 ? tableData4 : [{ uuid: null }],
          circles: [
            {
              date: "",
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
        }
      ).then(function(response) {
             Swal.fire({
        icon: "success",
        title: "success...",
        text: "   تم أضافه المريض بالنجاح",
      }).then(() => window.location.reload())

      })
      .catch(function(error){
        if (error.response) {
      
              Swal.fire({
                icon: "error",
                title: "",
                text: error.response?.data?.error + "!",
              });
              return 0;
            }
        if(!error.status){
          if (error) {
      
                Swal.fire({
                  icon: "error",
                  title: "NetWork",
                  text: "مشكلة في شبكه!",
                });
                return 0;
              }
        }
    
      });
      setisloading4(false)
        }

  //Delete
  const handleDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.value) {
  

        const employeesCopy = tableData.filter(
          (employee) => employee.uuid !== id
        );

        setTableData(employeesCopy);
        const employeesCopy2 = tableData2.filter(
          (employee) => employee.uuid !== id
        );

        setTableData2(employeesCopy2);
        const employeesCopy3 = tableData3.filter(
          (employee) => employee.uuid !== id
        );
        setTableData3(employeesCopy3);
        const employeesCopy4 = tableData4.filter(
          (employee) => employee.uuid !== id
        );
        setTableData4(employeesCopy4);
      }
    });
  };
function Msg(params) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: params+ "!",
  });
  return 0;
}
  //Service Get Data
  try {
    useEffect(() => {
      DataService.Getfacilitys()
        .then((data) => data)
        .then((data) => setfacilitys(data)).catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
       
          DataService.GetRefresh().catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
        window.location.reload();
        }})
     } });
      DataService.Getnationalitys()
        .then((data) => data)
        .then((data) => setnationality(data));
        DataService.GetAddresss()
        .then((data) => data)
        .then((data) => setAddresss(data));
      DataService.Getbloadtypes()
        .then((data) => data)
        .then((data) => setbloadtype(data));
      DataService.Getdiseases()
        .then((data) => data)
        .then((data) => setdisease(data));
      DataService.Getmedicines()
        .then((data) => data)
        .then((data) => setmedicine(data));
      DataService.Getoncologys()
        .then((data) => data)
        .then((data) => setoncology(data))
        DataService.GetRadiations()
        .then((data) => data)
        .then((data) => setRadiations(data))
       
    }, []);
   
  } catch (err) {
   
  }
  const navItems = [];
  return (
    <>
      <DrawerAppBar navItems={navItems}/>
    <div className="App">
     
      <div className="Home">
        {page === 1 && (
          <>
          <br></br>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R">بيانات الشخصيه</Typography>
            <div className="flex">
              <div className="0">
                <label className="label">الاسم الرباعى</label>
                <br></br>
                <input
                  className="input"
                  value={state.name ? state.name : ""}
                  required
                  onChange={(event)=>{
                    console.log(event.target.value)
                    if (!/\p{Nd}/u.test(event.target.value)) {
                      handleChange(event);
                    }
                 
                  }}
                  name="name"
                ></input>
                <h6 className="R">
                  {Check?.name === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
              <div className="0">
                <label className="label">اسم الأم</label>
                <br></br>
                <input
                  className="input"
                  value={state.mothername ? state.mothername : ""}
                  required
                  onChange={(event)=>{
                    console.log(event.target.value)
                    if (!/\p{Nd}/u.test(event.target.value)) {
                      handleChange(event);
                    }
                 
                  }}
                  name="mothername"
                ></input>
                <h6 className="R">
                  {Check?.mothername === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
            </div>
            <div className="flex">
              <div className="0">
                <label className="label">الجنسية</label>
                <br></br>
                <div className="Box">
                  <input
                    className="input"
                    required
                    list="nationalitylist"
                    id="nationalityName"
                  value={state.nationalityName1 ? state.nationalityName1 : ""}
                    name="nationalityName"
                    style={{
                      margin: "1%",

                      textAlign: "start",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                    onChange={handleChange}
                  />
                  <h6 className="R">
                    {Check?.nationalityName === false
                      ? "يرجي التأكد من الجنسية  "
                      : ""}
                  </h6>
                  <datalist id="nationalitylist">
                    {nationality.map((nationality, index) => (
                      <option
                        key={nationality.uuid}
                        value={nationality.name}
                        data-value={nationality.uuid}
                      >
                        {" "}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="0">
                <label className="label">رقم الجواز</label>
                <br></br>
                <input
                  className="input"
                  required
                  value={state.passport ? state.passport : ""}
                  onChange={handleChange}
                  name="passport"
                ></input>
                <h6 className="R">
                  {Check?.passport === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
            </div>
            <div className="flex">
              <div className="0">
                <label className="label">الرقم الوطني </label>
                <br></br>
                <input
                  className="input"
                  disabled={state.libya === "ليبيا" ? false : true}
                  value={state.NID ? state.NID : ""}
                  required
                  onChange={(event)=>{
                    console.log(event.target.value)
                    if (!/\p{Nd}/u.test(event.target.value)) {
                     
                    }else{
                      handleChange(event);
                    }
                 
                  }}
                  name="NID"
                ></input>
                <h6 className="R">
                  {state?.NID && state?.NID?.length != 12 ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>

              <div className="0">
                <label className="label">تاريخ الميلاد </label>
                <br></br>
                <input
                  className="input1"
                  type="date"
                  required
                  value={state.birthday ? state.birthday : ""}
                  name="birthday"
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                ></input>
              </div>
            </div>

            <div className="flex">
              <div className="b1">
                <label className="label">فصيلة الدم</label>
                <br></br>
                <div className="Box">
                  <input
                    className="input"
                    required
                    list="bloadtypelist"
                    id="bloadtypeName"
                   value={state.bloadtypeName1 ? state.bloadtypeName1 : ""}
                    name="bloadtypeName"
                    style={{
                      margin: "1%",

                      textAlign: "start",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                    onChange={handleChange}
                  />
                  <h6 className="R">
                    {Check?.bloadtypeName === false
                      ? "يرجي التأكد من فصيلة الدم "
                      : ""}
                  </h6>
                  <datalist id="bloadtypelist">
                    {bloadtype.map((bloadtype, index) => (
                      <option
                    
                        key={bloadtype.uuid}
                        value={bloadtype.name}
                        data-value={bloadtype.uuid}
                      >
                        {" "}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              {facility==''?<div className="0">
                <label className="label"> مركز العلاج</label>
                <br></br>
                <input
                  list="citylist"
                  id="facilitys"
                  name="facilitys"
                  className="input"
                  value={state.facilitys1 ? state.facilitys1 : ""}
                  required
                  style={{
                    margin: "1%",

                    textAlign: "start",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.facilitys === false ? "  يرجي  التأكد من  حروف مركز العلاج" : ""}
                </h6>
                <datalist id="citylist">
                  {facilitys.map((facilitys) => (
                    <option
                      key={facilitys.uuid}
                      value={facilitys.name}
                      data-value={facilitys.uuid}
                    >
                      {" "}
                    </option>
                  ))}
                </datalist>
              </div>:<div className="0">
                <label className="label"> مركز العلاج</label>
                <br></br>
                <input
                  list="citylist"
                  id="facilitys"
                  name="facilitys"
                  className="input"
                  value={facility}
                  required
                  style={{
                    margin: "1%",

                    textAlign: "start",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                disabled
                />
              <datalist id="citylist">
                  {facilitys.map((facilitys) => (
                    <option
                      key={facilitys.uuid}
                      value={facilitys.name}
                      data-value={facilitys.uuid}
                    >
                      {" "}
                    </option>
                  ))}
                </datalist>
              </div> }
              
            </div>
            <div className="flex">
              <div className="">
                <label className="label"> تاريخ الدخول </label>
                <br></br>
                <input
                  className="input1"
                  max="9999-12-31"
                  type="date"
                  required
                  name="indate"
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  value={state.indate ? state.indate : ""}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="">
                <label className="label"> تاريخ الخروج </label>
                <br></br>
                <input
                  className="input1"
                  max="9999-12-31"
                  type="date"
                  required
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  name="outdate"
                  value={state.outdate ? state.outdate : null}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <label className="label"> نوع الخروج </label>

                <br></br>
                <select
                  className="input"
                  value={state.status ? state.status : ""}
                  required
                  onChange={handleChange}
                  name="status"
                >
                  <option value=""></option>

                  <option value="خروج طبيعي">خروج طبيعي</option>
                  <option value="خروج علي المسؤلية">خروج علي المسؤلية</option>
                  <option value="هروب">هروب</option>
                  <option value="وفاة">وفاة</option>
                  <option value="رافض الدخول ">رافض الدخول</option>
                </select>
               
              </div>
              <div className="c1">
                <label className="label">الجنس</label>
                <br />
                <br />
                <div className="">
                  <input
                    type="radio"
                    id="html"
                    className="kk"
                    name="fav_language"
                    value="ذكر"
                    required
                    onClick={() => setisMale(true)}
                  />
                  <label>ذكر</label>
                  <input
                    type="radio"
                    id="css"
                    className="kk"
                    name="fav_language"
                    value="انثي"
                    required
                    onClick={() => setisMale(false)}
                  />
                  <label>أنثى </label>
                </div>
              </div>
            </div>
          </>
        )}
        <br></br>
        {page === 2 && (
          <div>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R">بيانات الإضافيه</Typography>
            <div className="flex">
              <div className="0">
                <div className="Flex1">
                  <label className="label"> محل الإقامة</label>
                </div>

                <br></br>
                <input
                  list="Addressslist"
                  id="address"
                  name="address"
                  value={state.AddresssName ? state.AddresssName : ""}
                  className="input"
                  style={{
                    margin: "1%",

                    textAlign: "start",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.address === false ? "  يرجي  من  حروف المدينة" : ""}
                </h6>
                <datalist id="Addressslist">
                  {address.map((address) => (
                    <option
                      key={address.uuid}
                      value={address.name}
                      data-value={address.uuid}
                    >
                      {" "}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className="0">
                <div className="Flex1">
                  <label className="label"> رقم الهاتف </label>
                </div>

                <br></br>
                <input
                  list="phone_numberlist"
                  id="phone_number"
                  type="number"
                  value={state.phone_number ? state.phone_number : ""}
                  name="phone_number"
                  className="input"
                  style={{
                    margin: "1%",

                    textAlign: "start",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.phone_number === false ? "  يرجي  من  عدد  الارقام" : ""}
                </h6>
              </div>
            </div>
            <br />
            <br /> <br />
            <br /> <br />
            <br /> <br />
            <br /> <br />
            <br /> <br />
            <br />
          </div>
        )}
        {page === 3 && (
          <>
            <h1 className="C">{page}</h1>
            <br />

            <Typography className="R">بيانات التشخيص</Typography>

            <FormInput
              handleChangeTable1={handleChangeTable1}
              formInputData={formInputData}
              handleSubmit={handleSubmit}
              oncology={oncology}
              Check={Check}
              isloading={isloading}
            />

            <Box
              sx={{
                height: tableData.length <= 4 ? 400 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table tableData={tableData} handleDelete={handleDelete} />
            </Box>
          </>
        )}
        {page === 4 && (
          <>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R">بيانات العلاج</Typography>

            <FormInput2
              handleChangeTable2={handleChangeTable2}
              handleSubmit2={handleSubmit2}
              medicine={medicine}
              Check={Check}
              isloading2={isloading2}
            />
            <br />

            <Box
              sx={{
                height: tableData2.length <= 4 ? 400 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table2 tableData2={tableData2} handleDelete={handleDelete} />
            </Box>
          </>
        )}
        {page === 5 && (
          <>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R"> الأمراض المصاحبة</Typography>

            <FormInput3
              handleChangeTable3={handleChangeTable3}
              handleSubmit3={handleSubmit3}
              disease={disease}
              Check={Check}
              isloading3={isloading3}
            />
            <br />

            <Box
              sx={{
                height: tableData3.length <= 4 ? 400 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table3 tableData3={tableData3} handleDelete={handleDelete} />
            </Box>
          </>
        )}
           {page === 6 && (
          <>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R"> العلاج الإشعاعي </Typography>

            <FormInput4
              handleChangeTable4={handleChangeTable4}
              handleSubmit4={handleSubmit4}
              Radiations={Radiations}
              Check={Check}
              isloading4={isloading4}
            />
            <br />

            <Box
              sx={{
                height: tableData4.length <= 4? 400 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table4 tableData4={tableData4} handleDelete={handleDelete} />
            </Box>
          </>
        )}
        <div className="flex">
          {page >= 1 &&
          localStorage.getItem("role") != "reception" &&
          Check.bloadtypeName === true &&
          Check.name === true &&
          Check.birthday === true &&
          isMale != null ? (
            <button
              className="button"
              // disabled={isloading4}
              onClick={() =>
                page < 6 ?  SetPage(page+1) : onSubmit()
              }
            >
              {page === 6 ? "الحفظ" : "التالي"}
            </button>
          ) : null}
 {page >= 1 && localStorage.getItem("role") === "reception" &&
          Check.bloadtypeName === true &&
          Check.name === true &&
          Check.birthday === true &&
          isMale != null ? (
            <button
              className="button"
              // disabled={isloading4}
              onClick={() =>
                 onSubmit()
              }
            >
            الحفظ
            </button>
          ) : null}
          { 
          page != 1 ? (
            <button
              hidden={true}
              className="button R"
              onClick={() => SetPage(page > 1 ? page - 1 : page)}
            >
              الرجوع
            </button>
          ) : null}
        </div>
      </div>
    </div>
    </>
  
  );
};

export default New;

export const Table = ({ tableData, handleDelete }) => {
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                رمز الورم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                اسم الورم
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
              المستوى{" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
              تاريخ التشخيص
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
           التشخيص
              </h3>

            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                حذف{" "}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.code}</td>
                <td>{data.oncology}</td>
                <td>{data.level}</td>
                <td>{data.date}</td>
                <td>{data.kind}</td>
                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleDelete(data.uuid)}
                    className=""
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export const Table2 = ({ tableData2, handleDelete }) => {
  console.log(tableData2);

  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
         الرقم
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
            الاسم
              </h5>{" "}
            </th>

           
            <th
            
            >
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
  
                  float: "Center",
                }}>
      الوحده/الجرعة    

              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
 طريقة اعطاء العلاج
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
             التكرار
             {/* Frequency    */}
              </h5>
            </th>
           
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
الكمية
              </h5>
            </th>
            {/* <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
uint
              </h5>
            </th> */}
               <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                حذف{" "}
              </h3>
            </th>
            {/* <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
              delete
              </h5>
            </th> */}
            {/* <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
       deActivate

              </h5>
            </th>
       */}
          </tr> 
        </thead>
        <tbody>
          {tableData2.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name
}</td>
                <td>{data.dose+'/'+data.unit}</td>
                <td>{data.roa}</td>
                <td>{data.frequency}</td>
            
                <td>{data.quantity}</td>
                
                {/* <td>{data.unit}</td> */}

               
                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleDelete(data.uuid)}
                    className=""
                  >
                    Delete
                  </button>
                </td>
           
                {/* <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => Delete(data.uuid)}
                    className=""
                  >
                    حذف
                  </button>
                </td> */}
                {/* <td>
                  <button
                    style={{
                      background: "#1976D2",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() =>
                      handleDisable(
                        data,
                        data.status == "Active" ? "deactivate" : "activate"
                      )
                    }
                    className=""
                  >
                    {data.status == "Active" ? "تفعيل" : "تعطيل"}
                  </button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
function FormInput2({
  handleChangeTable2,
  medicine,
  Check,
  handleSubmit2,
  isloading2,

}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> الادوية </label>
            <br></br>
            <input
              list="medicinelist"
              id="name"
              name="name"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable2}
            />
            <h6 className="R">
              {Check?.name === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
            <datalist id="medicinelist">
              {medicine.map((medicine) => (
                <option
                  key={medicine.uuid}
                  value={medicine.name}
                  data-value={medicine.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label">طريقة اعطاء الدواء</label>
            <br></br>
            <select
              className="input"
              name="roa"
              id="roa"
              onChange={handleChangeTable2}
            >
              <option value=""> </option>
              <option value="I.V/INF">I.V/INF</option>
              <option value="I.V/Bolus"> I.V/Bolus</option>
              <option value="S.C"> S.C</option>
              <option value="ORAL">ORAL</option>
            </select>
          </div>
        </div>
        <div className="flex">
          <div className="0">
            <label className="label">  الجرعه </label>
            <br></br>
            <input
              className="input"
              type="number"
              required
              onChange={handleChangeTable2}
              name="dose"
              id="dose"
            ></input>
          </div>
          <div className="0">
            <label className="label">التكرار</label>
            <br></br>
            <select
              className="input"
              name="frequency"
              id="frequency"
              onChange={handleChangeTable2}
            >
              <option value=""> </option>
              <option value="per/dose"> per/dose</option>
              <option value="per/Day"> per/Day</option>
              <option value="per/cycle"> per/cycle</option>
            </select>
          </div>
        </div>
        <div className="flex">
         
        
          <div className="0">
            <label className="label">الوحده</label>
            <br></br>
            <select
              className="input"
              name="unit"
              id="unit"
              onChange={handleChangeTable2}
            >
              <option value=""> </option>
              <option value="MG/m2"> MG/m2</option>
              <option value="MG/KG">MG/KG</option>
              <option value="IU"> IU</option>
              <option value="mqg"> mqg</option>
            </select>
          </div>
          <div className="0">
            <label className="label"> الكمية الشهرية</label>

            <br></br>
            <input
              className="input"
              type="number"
              required
              onChange={handleChangeTable2}
              name="quantity"
              id="quantity"
            ></input>
            <h6 className="R">
              {Check?.quantity === false ? "يرجي التأكد من عدد الارقام " : ""}
            </h6>
          </div>
        </div>
        <br></br>
        <Button
          variant="contained"
          className="Save-Button"
          disabled={isloading2}
          onClick={handleSubmit2}
          style={{
            fontFamily: "Tajawal",
            textAlign: "center",
            minwidth: "100%",
          }}
        >
       أضافه
        </Button>
      </div>
    </Box>
  );
}
export const Table3 = ({ tableData3, handleDelete }) => {
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
              اسم المرض
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                حذف{" "}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData3.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.diseases}</td>

                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleDelete(data.uuid)}
                    className=""
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export const Table4 = ({ tableData4, handleDelete }) => {
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
 هدف العلاج  {" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
 نوع  الإشعاع{" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >الكمية الإجمالية للاشعاع 
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
          نوع التقنية  
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
              تاريخ البدء
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
              تاريخ النهاية
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                حذف{" "}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
       
          {tableData4.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.status}</td>
                <td>{data.radiationtype}</td>
                <td>{data.radiation}</td>
                <td>{data.tecnology}</td>
                <td>{data.startdate}</td>
                <td>{data.enddate}</td>
                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleDelete(data.uuid)}
                    className=""
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
function FormInput({
  handleChangeTable1,
  oncology,
  Check,
  handleSubmit,
  isloading,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label">اسم الورم</label>
            <br></br>
            <input
              list="oncologylist"
              id="oncology"
              name="oncology"
              className="input"
              style={{
                margin: "1%",

                textAlign: "start",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable1}
            />
            <h6 className="R">
              {Check?.oncology === false ? "  يرجي التأكد  من  حروف الورم" : ""}
            </h6>
            <datalist id="oncologylist">
              {oncology.map((oncology) => (
                <option
                  key={oncology.uuid}
                  value={oncology.name}
                  data-value={oncology.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label">خطورة الورم </label>
            <br></br>
            <select
              className="input"
              name="level"
              id="level"
              onChange={handleChangeTable1}
            >
              <option></option>
              <option value="standard">standard risk</option>
              <option value="intermediate">intermediate risk</option>
              <option value="high">high risk</option>
            </select>
          </div>
        </div>
        <div className="flex">
        <div className="0">
            <label className="label">خيارت التشخيص</label>
            <br></br>
            <select
              className="input"
              name="kind"
              id="kind"
              onChange={handleChangeTable1}
            >
              <option></option>
              <option value="CT">CT</option>
              <option value="PET /CT/SCAN">PET /CT/SCAN</option>
              <option value="MRI">MRI</option>
              <option value="MAMMOGRAM">MAMMOGRAM</option>
              <option value="ENDOSCOPE">ENDOSCOPE</option>
              <option value="BIOPSY">BIOPSY</option>
              <option value="HISTOPATHOLOGY">HISTOPATHOLOGY</option>
            </select>
          </div>
          <div className="0">
            <label className="label">تاريخ التشخيص </label>
            <br></br>
            <input
              className="input"
              type="date"
              name="date"
              max="9999-12-31"
              id="date"
              onChange={handleChangeTable1}
            ></input>
          </div>
         
        </div>
      </div>
      <div className="0">
            <br></br>
            <Button
              variant="contained"
              className="Save-Button"
              disabled={isloading}
              onClick={handleSubmit}
              style={{
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            >
              {isloading ? "Loading..." : "Save"}
            </Button>
          </div>
    </Box>
  );
}

function FormInput3({
  handleChangeTable3,
  disease,
  Check,
  handleSubmit3,
  isloading3,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> اسم المرض </label>
            <br></br>
            <input
              list="diseaselist"
              id="diseases"
              name="diseases"
              className="input"
              style={{
                margin: "1%",

                textAlign: "start",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable3}
            />
            <h6 className="R">
              {Check?.diseases === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
            <datalist id="diseaselist">
              {disease.map((medicine) => (
                <option
                  key={medicine.uuid}
                  value={medicine.name}
                  data-value={medicine.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
        </div>
        <br></br>
        <Button
          variant="contained"
          className="Save-Button"
          disabled={isloading3}
          onClick={handleSubmit3}
          style={{
            fontFamily: "Tajawal",
            textAlign: "center",
            minwidth: "100%",
          }}
        >
          أضافه{" "}
        </Button>
      </div>
    </Box>
  );
}
function FormInput4({
  handleChangeTable4,
  Radiations,
  Check,
  handleSubmit4,
  isloading4,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
        <div className="0">
            <label className="label">هدف  العلاج </label>
            <br></br>
            <select
              className="input"
              name="status"
              id="status"
              onChange={handleChangeTable4}
            >
             <option value=""> </option>
              <option value="Curative"> Curative/ استشفائي</option>
              <option value="Palliative"> Palliative/ تلطيفي</option>
              <option value="وقائي"> وقائي</option>
            </select>
          </div>
          <div className="0">
            <label className="label">نوع  الأشعة </label>
            <br></br>
            <select
              className="input"
              name="radiationtype"
              id="radiationtype"
              onChange={handleChangeTable4}
            >
              <option value=""> </option>
              <option value="X"> X</option>
              <option value="Electron"> Electron</option>
              <option value="gamma"> gamma</option>
              <option value="proton"> proton</option>
              <option value="photon"> photon</option>
            </select>
          </div>

        </div>
<br />
        <div className="flex">
        <div className="0" style={{  }}>
            <label className="label">الكمية الإجمالية للإشعاع</label>
            <br></br>
            <input
              list="radiationlist"
              id="radiation"
              name="radiation"
              className="input"
              style={{
                // marginLeft: "100px",
              
                textAlign: "start",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable4}
            />
            <h6 className="R">
              {Check?.radiation === false ? "  يرجي التأكد  من  حروف الورم" : ""}
            </h6>
            <datalist id="radiationlist">
              {Radiations.map((Radiations) => (
                <option
                  key={Radiations.uuid}
                  value={Radiations.name}
                  data-value={Radiations.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label">نوع  التقنية </label>
            <br></br>
            <select
              className="input"
              name="tecnology"
              id="tecnology"
              onChange={handleChangeTable4}
            >
              <option value=""> </option>
              <option value="2D"> 2D</option>
              <option value="3D"> 3D CRT</option>
              <option value="IMRT"> IMRT</option>
              <option value="VMAT"> VMAT</option>
              <option value="Proton"> Proton</option>
              <option value="GAMMA KNIFE"> GAMMA KNIFE</option>
           
            </select>
          </div>

         
        </div>
        <br></br>
        <div className="flex">

        <div className="0">
            <label className="label">تاريخ البدء </label>
            <br></br>
            <input
             max="9999-12-31"
              className="input"
              type="date"
              name="startdate"
              id="startdate"
              onChange={handleChangeTable4}
              style={{
                textAlign: "start",
                paddingRight: "2%",
                height: "35px",
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            ></input>
          </div>
          <div className="0">
            <label className="label">
              
            تاريخ النهاية العلاج
            
            
             </label>
            <br></br>
            <input
              className="input"
              type="date"
              name="enddate"
              max="9999-12-31"
              id="enddate"
              onChange={handleChangeTable4}
              style={{
              
                paddingRight: "2%",
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            ></input>
          </div>
      

        </div>
        <div className="">
            <br></br>
            <Button
              variant="contained"
              className="Save-Button"
              disabled={isloading4}
              onClick={handleSubmit4}
              style={{

                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            >
              أضافه{" "}
            </Button>
          </div>
      </div>
    </Box>
  );
}