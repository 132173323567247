import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
const Getfacilitys = async () => {

    const response=  await axios
  .get("/facilitys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  })
  return response.data;

  
};
const GetPatients = async () => {

  const response=  await axios
.get("/patients", {
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
})
return response.data;


};
const Getbloadtypes = async () => {
  const response=  await axios
  .get("/bloadtypes", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const Getnationalitys = async () => {
  const response=  await axios
  .get("/nationalitys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const Getdiseases = async () => {
  const response=  await axios
  .get("/diseases", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getmedicines = async () => {
  const response=  await axios
  .get("/medicines", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
}; 
const Getoncologys = async () => {
  const response=  await axios
  .get("/oncologys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetAddresss = async () => {
  const response=  await axios
  .get("/addresss", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetRadiations = async () => {
  const response=  await axios
  .get("/radiations", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetAnalysis = async () => {
  const response=  await axios
  .get("/analysis", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetRefresh = async () => {
  const response=  await axios
  .get("/refresh", {
          headers: {
            Authorization: `Bearer ${refresh_token}`,
          },
  });
  localStorage.setItem('accessToken',response.data.access_token  );
  tokens = localStorage.getItem("accessToken");
  return setTimeout(response.data, 1);
};
const GetonePationbycode = async (code) => {
  const response=  await axios
  .get("/patient/code?code="+code , {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });

  return( response.data);
};

const GetPmedicines = async (id) => {
  const response=  await axios
  .get("/medicine/invoice?uuid="+id, {
          headers: {
           
            Authorization: `Bearer ${tokens}`,
          },
  });
  return( response.data);
};  
const Getreportmedicines = async (id) => {
  const response=  await axios
  .get("/report/patient?uuid="+id, {
          headers: {
           
            Authorization: `Bearer ${tokens}`,
          },
  });
  return( response.data);
};  
const GetonePation = async (id) => {
  const response=  await axios
  .get("/patient?uuid="+id, {
    headers: {     'Content-Type': 'application/json',
    Authorization: `Bearer ${tokens}`}
  });
  return( response.data);
};
const  Getcircle = async (id) => {
  const response=  await axios
  .get("/patient/medicine/circle?uuid="+id, {
    headers: {     'Content-Type': 'application/json',
    Authorization: `Bearer ${tokens}`}
  });
  return( response.data);
};
const  Getcircleday = async (id) => {
  const response=  await axios
  .get("/patient/medicine/circle/day?uuid="+id, {
    headers: {     'Content-Type': 'application/json',
    Authorization: `Bearer ${tokens}`}
  });
  return( response.data);
};
const postonePation = async (post) => {
  //print all value in post

  for (var pair of post.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
}

  const response=  await axios
  .post("/patient/analysis/add",{
    post
  }, {
    
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Bearer ${tokens}`,
    },
  });
  console.log(response.error)
  return( response.data);
};
const exportToCSV = async () => {
 
  fetch('https://oncologytest.teccube.ly/api/report/medicine/group/excel', {
    headers: {     'Content-Type': 'application/json',
    Authorization: `Bearer ${tokens}`}
   ,
  }).then( res => res.blob() )
  .then( blob => {
    var file = window.URL.createObjectURL(blob, 'filename');

    window.location.assign(file);
  });

};
const download = async (id) => {

  fetch('https://oncologytest.teccube.ly/api/patient/analysis/file?uuid='+id, {
    headers: {     'Content-Type': 'application/json',
    Authorization: `Bearer ${tokens}`}
   ,
  }).then( res => res.blob() )
  .then( blob => {
    var file = window.URL.createObjectURL(blob, 'filename');

    window.location.assign(file);
  });

};
const DataService = {
  Getoncologys,
  Getmedicines,
  GetPmedicines,
  Getnationalitys,
  Getfacilitys,
  Getbloadtypes,
  Getdiseases,
  GetPatients,
  GetonePation,
  GetAddresss,
  GetRefresh,
  GetonePationbycode,
  GetRadiations,
  exportToCSV,
  GetAnalysis,
  postonePation,
  Getcircle,
  download,Getcircleday,
  Getreportmedicines
}

export default DataService;
