import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import {HashRouter, Routes, Route } from 'react-router-dom';



ReactDOM.render(
  <React.StrictMode>
 <HashRouter hastype='hasbang' >
     
        <Routes  >
          <Route path="/*" element={<App />} />
        </Routes>
      
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);