import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DrawerAppBar from '../AppBar';
import Address from './Address/Address';
import Diseases from './Diseases/Diseases';
import Facility from './Facility/Facility';
import Medicines from './Medicines/Medicines';
import Oncology from './Oncology/Oncology';
import Nationalitys from './Nationalitys/Nationalitys';
import User from './User/User';
import Radiations from './Radiations/Radiations';
import Analysis from './Analysis/Analysis';

const Admin = () => {
  const [isSelected, setIsSelected] = useState( localStorage.getItem('isSelected') );
 
  const navigate = useNavigate();
  function FIsSelected(params) {

    localStorage.setItem('isSelected',params) 
     setIsSelected(params)
  }
  return (
    <div className='Homeadmin'>
    
      <div className='flexadmin' >
        
        <div className='Table-centeradmin' style={{ justifyContent: "none" }}>
        {/* <DrawerAppBar  /> */}
          {/* <Navbar /> */}
          {isSelected === "oncologys" && (
            <Oncology />
          )}
            {isSelected === "Diseases" && (
            <Diseases />
          )}
            {isSelected === "Address" && (
            <Address />
          )}
             {isSelected === "Facility" && (
            <Facility />
          )}
               {isSelected === "Medicines" && (
            <Medicines />
          )}
                  {isSelected === "nationalitys" && (
            <Nationalitys />
          )}
               { ( localStorage.getItem('user')=='001'
            ||
            localStorage.getItem('user')=='002'
            ||
            localStorage.getItem('role')=='superadmin') && isSelected === "UsersControl" && (
            <User />
          )}
             {isSelected === "radiation" && (
            <Radiations />
          )}
              {isSelected === "analysis" && (
            <Analysis />
          )}
        </div>



        <div className='slader'>
        
         
          <div className="buttonitems">
            <button className={`${isSelected === "oncologys" ? "Active" : "NONActive"}`}  onClick={() => FIsSelected("oncologys")} >أمراض الأورام</button>
            <button className={`${isSelected === "Diseases" ? "Active" : "NONActive"}`} onClick={() => FIsSelected( "Diseases")}  >الأمراض المصاحبة</button>
            <button className={`${isSelected === "Medicines" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("Medicines")}  >العلاج المستخدم</button>
            <button className={`${isSelected === "Address" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("Address")}  > المدن الليبية </button>
            <button className={`${isSelected === "nationalitys" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("nationalitys")}  >الجنسية </button>
            <button className={`${isSelected === "Facility" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("Facility")}  >المراكز الطبية </button>
            <button className={`${isSelected === "analysis" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("analysis")}  > التحليلي </button>
          {
            localStorage.getItem('user')=='001'
            ||
            localStorage.getItem('user')=='002'
            ||
            localStorage.getItem('role')=='superadmin'?
            <button className={`${isSelected === "UsersControl" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("UsersControl")}  >التحكم بالمستخدمين </button>:null
          
          } 

            <button className={`${ "NONActive"}`} onClick={() =>  navigate('/')} >  الرجوع </button>
          </div>
          
        </div>

      </div>




    </div>
   
  )
}

export default Admin