import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const Getradiations = async () => {
  const response=  await axios
  .get("/radiations", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Postradiation = async (data) => {
  
  const response=  await axios
  .post("/radiation",
  {
    name:data.name,

} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Putradiation = async (data) => {
  
  const response=  await axios
  .put("/radiation?uuid="+data.uuid,
  {
    name:data.name,
  
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const radiationsService = {
  Getradiations,
  Postradiation,
  Putradiation,
}

export default radiationsService;
