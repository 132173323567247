import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";

import { Box, Button, Radio } from "@mui/material";
import $ from "jquery";
import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from '../../AppBar'
import api from "../../../api/axios";

const Radiations = () => {

  const [isloading4, setisloading4] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [isloading0, setisloading0] = useState(false);
  let { id } = useParams();
  console.log( id )
  const [name, setname] = useState('أضافه');
  const [Uuid, setUuid] = useState(null);

  const [radiations, setradiations] = useState([]);
  const [Check, setCheck] = useState([]);
  const [formInputData4, setformInputData4] = useState({
    radiation: null,
    startdate: null,
    status: null,
    tecnology: null,
    radiationtype: null, 
    enddate: null,
   uuid:null
  });
  const [tableData4, setTableData4] = useState([]);
  //valadion
 
  const handleChangeTable4 = (evnt) => {
    setisloading4(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    if (evnt.target.name === "radiationtype") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }
    if (evnt.target.name === "tecnology") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }

    if (evnt.target.name === "status") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        formInputData4.radiation != undefined &&
        formInputData4.uuid != undefined 
      ) {
        setisloading4(false);
      }
    }
   
    if (evnt.target.name === "radiation") {
      uuidvalue = $('#radiationlist [value="' + value + '"]').data("value");
    


      newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
       
      });

      if (uuidvalue != undefined) {
        setformInputData4(newInput);
        console.log(formInputData4)
        setCheck({ ...Check, [evnt.target.name]: true });
      
        if (
          formInputData4.tecnology != undefined 
      
        ) {
          setisloading4(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    if (evnt.target.name === "startdate") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }
    if (evnt.target.name === "enddate") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData4(newInput);
      if (
        
        formInputData4.uuid != undefined
      ) {
        setisloading4(false);
      }
    
    }

  };

  //Submint
  const handleSubmit4 = (evnt) => {
    console.log(formInputData4)
    // setisloading(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData4).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData4];
      setTableData4(newData);
      const emptyInput = {
        uuid: undefined,
        radiation: undefined,
        status: undefined,
        startdate: undefined,
        tecnology: undefined,
      };
      var tokens =  localStorage.getItem("accessToken");
   
    
      if(name=='تعديل'){
       
        api
        .put(
          `/patient/radiation?uuid=` + Uuid,
          {
            "radiation": formInputData4.uuid            ,
            "radiationtype": formInputData4.radiationtype,
            "startdate": formInputData4.startdate,
            "status": formInputData4.status,
            "tecnology": formInputData4.tecnology,
            "enddate": formInputData4.enddate,
        },
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        )
        .catch(function (error) {
            ;
          if (error) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error + "!",
            });
            return 0;
          }
        });
      Swal.fire({
        icon: "success",
        title: "success...",
        text: "تم تعديل المريض",
      });
      }else{
        api
        .post(
          `/patient/radiation/add`,
          {
         
            "patient":id,
            "radiation": formInputData4.uuid            ,
            "radiationtype": formInputData4.radiationtype,
            "startdate": formInputData4.startdate,
            "status": formInputData4.status,
            "tecnology": formInputData4.tecnology,
            "enddate": formInputData4.enddate,
        },
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        )
        .catch(function (error) {
          
          if (error) {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error + "!",
            });
            return 0;
          }
        });
      Swal.fire({
        icon: "success",
        title: "success...",
        text: "تم أضافة المرض للمريض",
      });
     
      }
 
      setformInputData4(emptyInput);
      document.getElementById("radiation").value = "";
      document.getElementById("startdate").value = "";
      document.getElementById("status").value = "";
      document.getElementById("radiationtype").value = "";
      document.getElementById("tecnology").value = "";
      //ENDDATE
      document.getElementById("enddate").value = "";
      setisloading4(true);

      setname('أضافه')

    }
  };
  const handleChange4 = (data) => {
  console.log(data)
      setisloading4(false);
    setUuid(data.uuid)
    const employeesCopy = tableData4.filter(
      (employee) => employee.uuid !== data.uuid

    );
    setisloading(false);
    document.getElementById("radiation").value = data.name;
    document.getElementById("status").value = data.status;
    document.getElementById("radiationtype").value = data.radiationtype;
    document.getElementById("tecnology").value = data.tecnology;
    document.getElementById("enddate").value = data.enddate;

    data.startdate = new Date(data.startdate);
      var a = Math.floor(data.startdate.getTime());
      a = new Date(a);
      data.startdate =a.toISOString().split("T")[0]
      document.getElementById("startdate").value = data.startdate;
    setformInputData4(data)
    var  uuidvalue = $('#radiationlist [value="' + data.name + '"]').data("value");

console.log(uuidvalue)
      setformInputData4({
        ...data,
        uuid: uuidvalue,
       
      });
   
    setTableData4(employeesCopy);
setname('تعديل')

};
  

function Msg(params) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: params+ "!",
  });
  return 0;
}
  //Service Get Data
  try {
    useEffect(() => {

      DataService.GetRadiations()
        .then((data) => data)
        .then((data) => setradiations(data)).catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
       
          DataService.GetRefresh().catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
          Msg('error');
          
 
        }})
     } });
        DataService.GetonePation(id)
        .then((data) => data)
        .then((data) => {
         setTableData4(data.radiations)
         setisloading0(true)
        })
       

    }, []);
   
  } catch (err) {
   
  }
  const navItems = [];
console.log(tableData4)
  return (
    <>
      <DrawerAppBar navItems = {navItems} />
      {!isloading0 ? <div className="spinner-container">
          <div className="loading-spinner">
          
          </div>
        </div> :
    <div className="App">
     
    <div className="Home">
     <br></br>
     <br></br>
  
    <br />
    <>
         
            <br />
            <Typography className="R"> العلاج الاشعاعي </Typography>

            <FormInput4
              handleChangeTable4={handleChangeTable4}
              handleSubmit4={handleSubmit4}
              Radiations={radiations}
              Check={Check}
              isloading4={isloading4}
              name={name} 
            />
            <br />

            <Box
              sx={{
                height: tableData4.length <= 4? 400 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table4 tableData4={tableData4} handleChange4={handleChange4} name={name} />
            </Box>
          </>
</div>
</div>}
</>
  );
};

export default Radiations;

function FormInput4({
  handleChangeTable4,
  Radiations,
  Check,
  handleSubmit4,
  isloading4,
  name
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
        <div className="0">
            <label className="label">هدف  العلاج </label>
            <br></br>
            <select
              className="input"
              name="status"
              id="status"
              onChange={handleChangeTable4}
            >
             <option value=""> </option>
              <option value="Curative"> Curative/ استشفائي</option>
              <option value="Palliative"> Palliative/ تلطيفي</option>
              <option value="وقائي"> وقائي</option>
            </select>
          </div>
          <div className="0"> <label className="label">نوع  الأشعة </label>
            <br></br>
            <select
              className="input"
              name="radiationtype"
              id="radiationtype"
              onChange={handleChangeTable4}
            >
              <option value=""> </option>
              <option value="X"> X</option>
              <option value="Electron"> Electron</option>
              <option value="gamma"> gamma</option>
              <option value="proton"> proton</option>
              <option value="photon"> photon</option>
            </select>
          </div>
         

        </div>

        <div className="flex">
          <div className="0">
            <label className="label">تاريخ البدايه </label>
            <br></br>
            <input
              className="input"
              type="date"
              name="startdate"
              id="startdate"
              onChange={handleChangeTable4}
              style={{
              
                paddingRight: "2%",
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            ></input>
          </div>
          <div className="0">
            <label className="label">نوع  التقنية </label>
            <br></br>
            <select
              className="input"
              name="tecnology"
              id="tecnology"
              onChange={handleChangeTable4}
            >
              <option value=""> </option>
              <option value="2D"> 2D</option>
              <option value="3D"> 3D CRT</option>
              <option value="IMRT"> IMRT</option>
              <option value="VMAT"> VMAT</option>
              <option value="Proton"> Proton</option>
              <option value="GAMMA KNIFE"> GAMMA KNIFE</option>
           
            </select>
          </div>

        
        </div>
        <div className="flex">
        <div className="">
            <label className="label">الكمية الإجمالية للإشعاع</label>
            <br></br>
            <input
              list="radiationlist"
              id="radiation"
              name="radiation"
              className="input"
              style={{
          
              }}
              onChange={handleChangeTable4}
            />
            <h6 className="R">
              {Check?.radiation === false ? "  يرجي التأكد  من  حروف الورم" : ""}
            </h6>
            <datalist id="radiationlist">
              {Radiations.map((Radiations) => (
                <option
                  key={Radiations.uuid}
                  value={Radiations.name}
                  data-value={Radiations.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label">
              
            تاريخ النهاية العلاج
            
            
             </label>
            <br></br>
            <input
              className="input"
              type="date"
              name="enddate"
              id="enddate"
              onChange={handleChangeTable4}
              style={{
              
                paddingRight: "2%",
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            ></input>
          </div>
         
        </div>
        <div className="">
            <br></br>
            <Button
              variant="contained"
              className="Save-Button"
              disabled={isloading4}
              onClick={handleSubmit4}
              style={{

                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            >
              {name}
            </Button>
          </div>
      </div>
    </Box>
  );
}
export const Table4 = ({ tableData4,handleChange4, name }) => {
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time.getTime());
    a = new Date(a);
    return a.toISOString().split("T")[0];
     
  }
  function Delete(uuid) {
    Swal.fire({
      title: 'هل انت متاكد من حذف البيانات؟',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
   
      if (result.isConfirmed) {
        var tokens =  localStorage.getItem("accessToken");
 
        api
        .delete(
          `/patient/radiation?uuid=`+ uuid,
        
     
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        )
        .catch(function (error) {
            
          if (error) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error + "!",
            });
            return 0;
          }
          if (!error.status) {
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "NetWork...",
              text: "!مشكلة في شبكة ",
            });
            return 0;
          }
        });
      Swal.fire({
        icon: "success",
        title: "success...",
        text: "تم حذف المرض",
      }).then(()=>window.location.reload());
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  return (
    <div>
     
      <table className="table" id="customers">
     
      <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
 هدف العلاج  {" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
 نوع  الإشعاع{" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >الكمية الإجمالية للاشعاع 
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
          نوع التقنية  
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
              تاريخ البدء
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
              تاريخ النهاية
              </h3>
            </th>
            <th>
            <h3
              style={{
                direction: "rtl",
                color: "white",
                float: "right",
              }}
            >
            تعديل
            </h3>
          </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                حذف{" "}
              </h3>
            </th>
          </tr>
        </thead>
      <tbody>
     
        {tableData4.map((data, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{data.status}</td>
              <td>{data.radiationtype}</td>
              <td>{data.name}</td>
              <td>{data.tecnology}</td>
              <td>{time(data.startdate)}</td>
              <td>{time(data.enddate)}</td>

              <td>

{name=='أضافه'?  <button
    style={{
      background: "#3f51b5",
      color: "white",
      border: "none",
      width: "100px",
    }}
    onClick={() => handleChange4(data)}
    className=""
  >
  تعديل
  </button>:null}

</td>
<td>
<button
    style={{
      background: "red",
      color: "white",
      border: "none",
      width: "100px",
    }}
   onClick={() => Delete(data.uuid)}
    className=""
  >
  حذف
  </button>
</td>
            </tr>
          );
        })}
        
      </tbody>
    </table>
    </div>
  );
};