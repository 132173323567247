import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const GetUsers = async () => {
  const response=  await axios
  .get("/users", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const PostUser = async (data) => {
  
  const response=  await axios
  .post("/user",
  {
    username:data.username,
    birthday:data.birthday,
    facility:data.facility,
    password:data.password,
    phone_number:data.phone_number,
    role:data.role,
    name:data.name,
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const PutUser = async (data,uuidFacilitys) => {
console.log(uuidFacilitys?uuidFacilitys:'AJK');
  const response=  await axios
  .put("/user?uuid="+ data.uuid,
  {
    birthday:data.birthday,
    facility:uuidFacilitys?uuidFacilitys:'AJK',
    password:data.password,
    phone_number:data.phone_number,
    role:data.role,

    name:data.name,
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const DisActiveUser = async (data,status) => {
  
  const response=  await axios
  .put("/activate?uuid="+ data.uuid,
  {
    status:'active',
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const ActiveUser = async (data,status) => {
  
  const response=  await axios
  .put("/deactivate?uuid="+ data.uuid,
  {
    status:'disactive',
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const UserService = {
  GetUsers,
  PostUser,
  PutUser,
  ActiveUser,
  DisActiveUser,
}

export default UserService;
