  
import React, { PureComponent, useEffect, useState } from 'react';



import ReportService from "../../services/report.service";
import { useNavigate } from "react-router-dom";
  


  
 
const BloodType = () => {

  const [item, setitem] = useState('');
  const [loading, setloading] = useState(false);
  const [rows, setrows] = useState({});
  useEffect(  ()  => {

 
     
    ReportService.Getbloadtypes()
    .then((data) => data)
    .then((data) => setrows(data))
   
    .catch(function (err) {
      if (!err?.response) {
        
    } else if (err.response?.status === 400) {
    
    } else if (err.response?.status === 401 ||err.response?.status === 422) {
           localStorage.clear();
      navigate('/');
      window.location.reload();
    }
    });
    // setloading(true)

  },[])
 
  
  const navigate = useNavigate();
  console.log(rows);

  return (
    <div style={{backgroundColor:'#ffffff'}}>
 <br></br>
   
        <div className="border rounded p-2" >
     
          <div   className="W100" >
            <h1 className="text-center" >  عدد الأمراض    بالنسبة   لفصيلة الدم  </h1>
            <span className='rg1'>
              <h6 id="name">
{localStorage.getItem("user")+'-:اسم المستخدم '}
</h6>


            </span>
          
    <table id="customers">
      
    <tr>
  
    <th style={{color:'#ffff',flex:1}}  className="text-center">   
المجموع

   

 </th>

    {  rows[0] != undefined && Object.keys(rows[0].Bloadtype).map((result,index) => 
    <th style={{color:'#ffff',flex:1}}  className="text-center" key={index}>  {result}</th>

)}
  <th style={{color:'#ffff',flex:1}}  className="text-center">الاسم</th>
  <th style={{color:'#ffff',flex:1}}  className="text-center" >  
الرقم
   </th>
  </tr>
  { rows[0] != undefined &&  (rows).map((result,index)  =>   
  <tr className="text-center" >
     <td  className="text-center">{rows[index]?.total}</td> 
  { rows[0] != undefined && Object.values(rows[index].Bloadtype).map((result,index)  =>     
  
              <td  className="text-center" style={{flex:1}}>{parseInt(result) ? parseInt(result) : 0}</td> 
        
    
           
       )}
        
     
        <td  className="text-center">{rows[index]?.oncology}</td> 

        <td  className="text-center">{index+1}</td> 
        </tr> 

) }
</table>
  
      
          </div>
        
      </div>
    </div>
  )
}

export default BloodType