import axios from "../api/axios";



var tokens = localStorage.getItem("accessToken");
const refresh_token = localStorage.getItem("refresh_token");
// s
 
const Getoncologys = async () => {
  const response=  await axios
  .get("/oncologys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Postoncology = async (data) => {
  
  const response=  await axios
  .post("/oncology",
  {
    name:data.name,
    code:data.code
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Putoncology = async (data) => {
  
  const response=  await axios
  .put("/oncology?uuid="+data.uuid,
  {
    name:data.name,
    code:data.code,
    editable: true
} , {

          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const OncologysService = {
  Getoncologys,
  Postoncology,
  Putoncology,
}

export default OncologysService;
