import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";

import { Box, Button, Radio } from "@mui/material";
import $, { map } from "jquery";
import Swal from "sweetalert2";
import DataService from "../../services/data.service";
import DrawerAppBar from '../AppBar'
import api from "../../api/axios";
import MedicinesService from "../../services/medicines.service";


const Invoice = () => {

 
  let { id } = useParams();
console.log(id);
  const [isloading2, setisloading2] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [name, setname] = useState('أضافه');
  const [Uuid, setUuid] = useState(null);
  const [medicine, setmedicine] = useState([]);
  const [Check, setCheck] = useState([]);
 
  const [tableData2, setTableData2] = useState([]);
  const [formInputData2, setformInputData2] = useState({
    uuid: null,
    quantity: 1,
    name: null,
  });
  const handleChangeTable2 = (evnt) => {
    setisloading2(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
      ;

    if (evnt.target.name === "name") {
      uuidvalue = $('#medicinelist [value="' + value + '"]').data("value");
      console.log(uuidvalue);

      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });

      if (uuidvalue != undefined) {
        setformInputData2(newInput);
        
        setCheck({ ...Check, [evnt.target.name]: true });
        if (
          formInputData2.uuid != undefined &&
          formInputData2.quantity != undefined &&
          formInputData2.name != undefined
        ) {
          setisloading2(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    if (evnt.target.name === "quantity") {
      if (evnt.target.value <= 0) {
        evnt.target.value = 1;
      }
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData2(newInput);
      if (
        formInputData2.uuid != undefined &&
        formInputData2.quantity != undefined &&
        formInputData2.name != undefined
      ) {
        setisloading2(false);
      }
    }
    console.log(formInputData2);
  };

  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params+ "!",
    });
    return 0;
  }
  const handleSubmit = (evnt) => {
  setisloading(true);
  evnt.preventDefault();
  const checkEmptyInput = !Object.values(formInputData2).every(
    (res) => res === ""
  );
  if (checkEmptyInput) {
    const newData = (data) => [...data, formInputData2];
    setTableData2(newData);
    const emptyInput = {
      uuid: undefined,
      quantity: undefined,
      name: undefined,
    };
    var tokens =  localStorage.getItem("accessToken");
 
  
    if(name=='تعديل'){
     
      api
      .put(
        `/patient/medicine?uuid=` + Uuid,
        {
          "medicine": formInputData2.uuid,
          "quantity": formInputData2.quantity,
          "iscircled":false
      },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
        }
      )
      .catch(function (error) {
          ;
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "NetWork...",
            text: "!مشكلة في شبكة ",
          });
          return 0;
        }
      });
    Swal.fire({
      icon: "success",
      title: "success...",
      text: "تم تعديل المريض",
    });
    }else{
      api
      .post(
        `/medicine/invoice`,
        {
          "patient":id.split('-')[0],
          "lines": [formInputData2],
         
      },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
        }
      )
      .catch(function (error) {
        
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
      });
      
    Swal.fire({
      icon: "success",
      title: "success...",
      text: "تم أضافة العلاج للمريض",
    });
   
    }

    setformInputData2(emptyInput);
    document.getElementById("name").value = "";
    document.getElementById("quantity").value = "";
    setname('أضافه')

  }
};

    
//Service Get Data
  try {
    useEffect(() => {

      MedicinesService.GetPMedicines(id.split('-')[0] )
      .then((data) =>{
        setmedicine(data)
       
      } )  .catch(function (err) {
        if (!err?.response) {
          Msg('No Server Response');
      } else if (err.response?.status === 400) {
         Msg('error');
      } else if (err.response?.status === 401 ||err.response?.status === 422) {
     
        DataService.GetRefresh().catch(function (err) {
        if (!err?.response) {
          Msg('No Server Response');
      } else if (err.response?.status === 400) {
         Msg('error');
      } else if (err.response?.status === 401 ||err.response?.status === 422) {
        Msg('error');
localStorage.clear();
      }})
   } });
   DataService.GetonePationbycode(id.split('-')[1])
    
        .then((data) => {
      console.log(data)
       setTableData2(data.invoices)
        })
       
    }, []);
  
  } catch (err) {
   
  }
  const navItems = [];
  const handleChange = (data) => {
  
      
    setUuid(data.medicine_uuid)
    const employeesCopy = tableData2.filter(
      (employee) => employee.medicine_uuid !== data.medicine_uuid
  
    );
    setisloading(false);
    document.getElementById("name").value = data.medicine_name;
    document.getElementById("quantity").value = data.quantity;
    setformInputData2(data)
    var  uuidvalue = $('#medicinelist [value="' + data.medicine_name + '"]').data("value");
  
  console.log(uuidvalue)
      setformInputData2({
        ...data,
        uuid: uuidvalue,
       
      });
   
    setTableData2(employeesCopy);
  setname('تعديل')
  
  };
  
  return (
    <>
      <DrawerAppBar navItems = {navItems} />
      {!1 ? <div className="spinner-container">
          <div className="loading-spinner">
          
          </div>
        </div> :    <div className="App">
     
    <div className="Home">
     <br></br>
     <br></br>
  
    <br />

    <Typography className="R">بيانات الصيدلي</Typography>

    <FormInput2
      handleChangeTable2={handleChangeTable2}
      formInputData2={formInputData2}
      handleSubmit={handleSubmit}
      medicine={ medicine}
      Check={Check}
      isloading2={isloading2}
      name={name}
    />

    <Box
      sx={{
        height: tableData2.length <= 5 ? 500 : 1000,
        backgroundColor: "primary",
      }}
    >
      <Table2 tableData2={tableData2} handleChange={handleChange}  />

      <br>
      </br>
    
    </Box>
</div>
</div>}
</>
  );
};

export default Invoice;



export const Table2 = ({ tableData2, handleChange ,name}) => {
  function time(time) {
    time = new Date(time);
    var a = Math.floor(time.getTime());
    a = new Date(a);
    return a.toISOString().split("T")[0];
     
  }
  
  
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                اسم العلاج
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                الكمية
              </h3>
            </th>
           
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
               
              التاريخ
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                   float: "Center",
                }}
              >
                تعديل
              </h3>
            </th>
          
          </tr>
        </thead>
        <tbody>
          { tableData2.map((data, index) => {
            return (
              <tr key={index}>
                 <td>{index+1}</td>
                <td>{data.lines[0].medicine}</td>
                <td>{data.lines[0].quantity+' '+ 
                data.lines[0].measruingunit}</td>
                <td>{time(data.date)}</td>
                <td>
                  <button
                    style={{
                      background: "#1976D2",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleChange(data)}
                    className=""
                  >
                    تعديل
                  </button>
                </td>
               
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
function FormInput2({
  handleChangeTable2,
  medicine,
  Check,
  handleSubmit,
  isloading2,
  name,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> الادوية </label>
            <br></br>
            <input
              list="medicinelist"
              id="name"
              name="name"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable2}
            />
            <h6 className="R">
              {Check?.name === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
            <datalist id="medicinelist">
              {medicine.map((medicine) => (
               <option
               key={medicine.medicine_uuid}
               value={medicine.medicine_name}
               data-value={medicine.medicine_uuid}
             >
               {" "}
             </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label"> عدد الجرعات</label>

            <br></br>
            <input
              className="input"
              type="number"
              required
              onChange={handleChangeTable2}
              name="quantity"
              id="quantity"
            ></input>
            <h6 className="R">
              {Check?.quantity === false ? "يرجي التأكد من عدد الارقام " : ""}
            </h6>
          </div>
        </div>
        <br></br>
        <Button
              variant="contained"
              className="Save-Button"
              disabled={isloading2}
              onClick={handleSubmit}
              style={{
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            >
            {name}
            </Button>
      </div>
    </Box>
  );
}