import axios from 'axios';
const BASE_URL = 'https://oncologytest.teccube.ly/api';


var tokens = localStorage.getItem("accessToken");
const instance = axios.create({
    baseURL: BASE_URL,
    headers: { 'content-type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`} 
})

export default instance;